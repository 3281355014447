import { RepositoryFactory } from "@/repository/RepositoryFactory";
import { errorHandlingAndMessage } from "@/services/ErrorService";
const actionRepository = RepositoryFactory.get("action");

// This mixin should be used on a step

export default {
  methods: {
    actionChanged(event) {
      const newIndex = event.newIndex;
      const newStepId = event.to.id;

      const action = {
        sort: newIndex,
      };

      if (this.id !== newStepId) {
        action.step_id = newStepId;
      }

      this.updateAction(this.activeLesson.id, this.id, event.item.id, action);
    },

    updateAction(activeLessonId, stepId, actionId, action) {
      actionRepository
        .updateAction(activeLessonId, stepId, actionId, action)
        .then(() => {
          this.$store.dispatch("editorStore/GET_CHAPTER_TREE");
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t("notifications.something_went_while_sorting"),
          );
        });
    },
  },
};
