<template>
  <!--  <router-link-->
  <!--    v-if="!external"-->
  <!--    :to="link"-->
  <!--    tag="button"-->
  <!--    class="nav-btn w-full group flex items-center px-2 py-2 mb-1 text-base leading-6 font-medium rounded-md  text-lightAlineaColor hover:bg-navButtonBackground transition ease-in-out duration-150"-->
  <!--    :exact="exact"-->
  <!--    @click="clickAction"-->
  <!--  >-->

  <router-link v-if="!external" :to="link" v-slot="{ navigate }">
    <div
      @click="
        navigate;
        clickAction;
      "
      class="nav-btn w-full group flex items-center px-2 py-2 mb-1 text-base leading-6 font-medium rounded-md text-lightAlineaColor hover:bg-navButtonBackground transition ease-in-out duration-150"
      :class="active ? 'bg-navButtonBackground' : ''"
    >
      <font-awesome-icon
        :icon="icon"
        class="fa-lg mr-4 h-6 w-6 transition ease-in-out duration-150"
      />
      {{ title }}
    </div>
  </router-link>
  <a
    v-else
    target="_blank"
    tag="button"
    class="nav-btn group flex flex-row items-center justify-between px-2 py-2 mb-1 text-base leading-6 font-medium rounded-md text-lightAlineaColor hover:bg-navButtonBackground transition ease-in-out duration-150"
    :class="active ? 'bg-navButtonBackground' : ''"
    :href="link"
  >
    <div class="flex flex-row items-center">
      <font-awesome-icon
        :icon="icon"
        class="fa-lg mr-4 h-6 w-6 transition ease-in-out duration-150"
      />
      {{ title }}
    </div>

    <font-awesome-icon
      icon="external-link-alt"
      class="fa-lg h-4 w-4 transition ease-in-out duration-150"
    />
  </a>
</template>

<script>
export default {
  name: "NavButton",
  props: {
    title: {
      type: String,
      default: "Title",
      required: true,
    },
    icon: {
      type: String,
      default: "user",
      required: true,
    },
    link: {
      type: String,
      default: "/",
      required: true,
    },
    external: {
      type: Boolean,
      default: false,
    },
    exact: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clickAction() {
      this.$emit("clickAction");
    },
  },
};
</script>

<style scoped>
.router-link-active {
  background-color: rgba(0, 0, 0, 0.15);
}
</style>
