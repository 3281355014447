import i18n from "@/i18n";
import { errorHandlingAndMessage } from "@/services/ErrorService";
import { showNotification } from "@/services/notificationService";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const stepRepository = RepositoryFactory.get("step");
const actionRepository = RepositoryFactory.get("action");
import store from "@/store/store";

const state = {
  showFullTitles: false,
  collapseAll: 1, //0 = not collapsed, 1 = neutral; to make the watchers fire, 2 = collapsed
  disableShortcuts: false,
  clipboard: {
    lessonId: "",
    type: "copy", // cut, copy
    target: "", // action, linear, nonLinear
    stepId: "",
    actionId: "",
    sort: 0,
  },
};
const getters = {};

const actions = {
  TOGGLE_FULL_TITLES: ({ commit, dispatch }) => {
    commit("TOGGLE_FULL_TITLES");
  },

  TOGGLE_COLLAPSE_ALL: ({ commit, dispatch }, payload) => {
    commit("TOGGLE_COLLAPSE_ALL", payload);
  },

  TOGGLE_DISABLE_SHORTCUTS: ({ commit, dispatch }, payload) => {
    commit("TOGGLE_DISABLE_SHORTCUTS", payload);
  },

  COPY_TO_CLIPBOARD: ({ commit, dispatch }, payload) => {
    commit("COPY_TO_CLIPBOARD", payload);
    if (payload.type === "copy") {
      showNotification(
        i18n.global.t("notifications.copy_step"),
        i18n.global.t("notifications.step_copied_to_clipboard"),
        "success",
      );
    }
    if (payload.type === "cut") {
      showNotification(
        i18n.global.t("notifications.cut_step"),
        i18n.global.t("notifications.step_cut_to_clipboard"),
        "success",
      );
    }
  },

  PASTE_FROM_CLIPBOARD: ({ commit, dispatch }, payload) => {
    const target = state.clipboard.target;
    const type = state.clipboard.type;

    switch (target) {
      case "action":
        switch (type) {
          case "cut":
            dispatch("MOVE_ACTION", {
              destinationStepId: payload.destinationStepId,
              sort: payload.sort,
            });
            break;
          case "copy":
            dispatch("DUPLICATE_ACTION", {
              destinationStepId: payload.destinationStepId,
              sort: payload.sort,
            });
            break;
        }
        break;
      case "linear":
        switch (type) {
          case "cut":
            dispatch("MOVE_STEP", {
              newSort: payload,
            });
            break;
          case "copy":
            dispatch("DUPLICATE_STEP", {
              newSort: payload,
            });
            break;
        }
        break;
      case "nonLinear":
      case "setup":
        switch (type) {
          case "cut":
            dispatch("MOVE_STEP", {
              newSort: payload,
            });
            break;
          case "copy":
            dispatch("DUPLICATE_STEP", {
              newSort: payload,
            });
            break;
        }
        break;
    }
  },

  DUPLICATE_STEP: ({ commit, state, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      stepRepository
        .duplicateStep(
          payload.lessonId ?? state.clipboard.lessonId,
          payload.stepId ?? state.clipboard.stepId,
          payload.newSort,
        )
        .then((result) => {
          showNotification(
            i18n.global.t("notifications.duplicate_step"),
            i18n.global.t(
              "notifications.this_step_has_been_successfully_duplicated",
            ),
            "success",
          );
          store.dispatch("editorStore/GET_CHAPTER_TREE");
          commit("CLEAR_CLIPBOARD");
          resolve();
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            i18n.global.t(
              "notifications.something_went_wrong_while_duplicating_this_step",
            ),
          );
          reject(err);
        });
    });
  },

  MOVE_STEP: ({ commit, state, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      const step = {
        sort: payload.newSort,
      };
      stepRepository
        .editStep(state.clipboard.lessonId, step, state.clipboard.stepId)
        .then((result) => {
          showNotification(
            i18n.global.t("notifications.paste_step"),
            i18n.global.t(
              "notifications.this_step_has_been_successfully_pasted",
            ),
            "success",
          );
          store.dispatch("editorStore/GET_CHAPTER_TREE");
          commit("CLEAR_CLIPBOARD");
          resolve();
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            i18n.global.t(
              "notifications.something_went_wrong_while_moving_this_step",
            ),
          );
          reject(err);
        });
    });
  },

  DUPLICATE_ACTION: ({ commit, state, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      actionRepository
        .duplicateAction(
          state.clipboard.lessonId,
          state.clipboard.stepId,
          state.clipboard.actionId,
          payload.destinationStepId,
          payload.sort,
        )
        .then((result) => {
          showNotification(
            i18n.global.t("notifications.paste_action"),
            i18n.global.t(
              "notifications.this_action_has_been_successfully_pasted",
            ),
            "success",
          );
          store.dispatch("editorStore/GET_CHAPTER_TREE");
          commit("CLEAR_CLIPBOARD");
          resolve();
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            i18n.global.t(
              "notifications.something_went_wrong_while_duplicating_this_action",
            ),
          );
          reject(err);
        });
    });
  },

  MOVE_ACTION: ({ commit, state, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      actionRepository
        .moveAction(
          state.clipboard.lessonId,
          state.clipboard.stepId,
          state.clipboard.actionId,
          payload.destinationStepId,
          payload.sort,
        )
        .then((result) => {
          showNotification(
            i18n.global.t("notifications.paste_action"),
            i18n.global.t(
              "notifications.this_action_has_been_successfully_pasted",
            ),
            "success",
          );
          store.dispatch("editorStore/GET_CHAPTER_TREE");
          commit("CLEAR_CLIPBOARD");
          resolve();
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            i18n.global.t(
              "notifications.something_went_wrong_while_moving_this_action",
            ),
          );
          reject(err);
        });
    });
  },
};

const mutations = {
  TOGGLE_FULL_TITLES: (state, payload) => {
    state.showFullTitles = !state.showFullTitles;
  },

  TOGGLE_COLLAPSE_ALL: (state, payload) => {
    state.collapseAll = payload;
  },

  TOGGLE_DISABLE_SHORTCUTS: (state, payload) => {
    state.disableShortcuts = payload;
  },

  COPY_TO_CLIPBOARD: (state, payload) => {
    state.clipboard = {
      lessonId: payload.lessonId,
      type: payload.type,
      target: payload.target,
      stepId: payload.stepId,
      actionId: payload.actionId,
      sort: payload.sort,
    };
  },

  CLEAR_CLIPBOARD: (state, payload) => {
    state.clipboard = {
      lessonId: "",
      type: "copy",
      target: "",
      stepId: "",
      actionId: "",
      sort: 0,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
