<template>
  <PageContentContainer ref="top">
    <div class="grid grid-cols-1 gap-6">
      <MediaTranslationTopBar
        :selected-category="selectedCategory"
        :category-options="categories"
        :selected-type="selectedType"
        :types-options="types"
        @searching="searching"
        @changeType="changeType"
        @changeCategory="changeCategory"
      />
      <div class="overflow-hidden sm:rounded-md">
        <div v-if="loading" class="py-48 flex items-center justify-center">
          <Loader />
        </div>
        <div v-else class="flex flex-col">
          <div
            class="-mt-2 mb-8 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
          >
            <div class="inline-block w-full overflow-hidden">
              <div
                v-if="!slicedTranslations.length"
                class="flex justify-center"
              >
                <span class="text-white mb-4">{{
                  $t("translation.no_results")
                }}</span>
              </div>
              <table class="table-fixed w-full bg-gray-800">
                <thead class="bg-blue-800 tracking-widest">
                  <tr>
                    <th
                      class="w-64 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider"
                    >
                      {{ $t("translation.name") }}
                    </th>
                    <th
                      class="w-64 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider"
                    >
                      {{ $t("translation.languages") }}
                    </th>
                    <th
                      class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider"
                    >
                      {{ $t("translation.info") }}
                    </th>
                    <th
                      v-if="selectedType !== 'None'"
                      class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider"
                    >
                      {{ $t("translation.preview") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <TranslationRow
                    v-for="(translation, index) in slicedTranslations"
                    :class="index % 2 ? 'bg-gray-700' : 'bg-gray-800'"
                    :key="translation.id"
                    :locales="locales"
                    :translation="translation"
                    :default-locale="defaultLocale"
                    :current-index="currentPage"
                    :selected-type="selectedType"
                    :limit="limit"
                    :is-lesson-translations="isLessonTranslations"
                    :is-game-translations="isGameTranslations"
                    @updateProductField="updateProductField"
                    @getLocales="getLocales"
                  />
                </tbody>
              </table>
              <Pagination
                :data="search"
                :total-pages="totalPages"
                :current-page="currentPage"
                @next="nextPage"
                @previous="previousPage"
                @toPage="toPage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </PageContentContainer>
</template>

<script>
import { mapState } from "vuex";
import PageContentContainer from "@/components/helpers/PageContentContainer";
import Loader from "@/views/editor/components/Loader";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const trainingRepository = RepositoryFactory.get("training");
const productFieldRepository = RepositoryFactory.get("productField");
const lessonMediaRepository = RepositoryFactory.get("lessonMedia");
import TranslationRow from "@/views/editor/components/TranslationRow";
import Pagination from "@/views/editor/components/Pagination";
import MediaTranslationTopBar from "@/views/editor/components/MediaTranslationTopBar";
import { showNotification } from "@/services/notificationService";

export default {
  name: "MediaTranslations",
  components: {
    PageContentContainer,
    Loader,
    TranslationRow,
    Pagination,
    MediaTranslationTopBar,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      productFields: [],
      combinedProductFields: [],
      edit: false,
      showModal: false,
      totalPages: null,
      currentPage: 1,
      limit: 10,
      searchInput: "",
      defaultLocale: null,
      selectedCategory: "",
      selectedType: "",
      categories: [],
      types: [],
      isGameTranslations: false,
      isLessonTranslations: false,
    };
  },
  computed: {
    ...mapState("translationStore", ["locales"]),
    search: function () {
      if (!this.loading) {
        return this.combinedProductFields.filter((translation) => {
          if (
            Object.values(translation).find((val) => {
              if (this.isGameTranslations) {
                return (
                  val.name
                    .toUpperCase()
                    .includes(this.searchInput.toUpperCase()) &&
                  val.json_type === this.selectedType
                );
              }
              if (this.isLessonTranslations) {
                return (
                  val.unity_reference_name
                    .toUpperCase()
                    .includes(this.searchInput.toUpperCase()) &&
                  val.unity_package_action_property.data.media_type ===
                    this.selectedType
                );
              }
            })
          ) {
            return translation;
          }
        });
      } else {
        return null;
      }
    },
    slicedTranslations: function () {
      const start = this.currentPage * this.limit - this.limit;
      const end = this.currentPage * this.limit;
      return this.search.slice(start, end);
    },
  },
  mounted() {
    this.isLessonTranslations = this.$route.name === "lessonTranslations";
    this.isGameTranslations = this.$route.name === "gameTranslations";
    this.getLocales();
  },
  methods: {
    updateProductField() {
      if (this.isGameTranslations) {
        this.getCategories();
      } else {
        this.getTranslationLocale();
      }
    },
    getLocales() {
      this.$store.dispatch("translationStore/GET_LOCALES").then(() => {
        if (this.isGameTranslations) {
          this.getCategories();
        } else {
          this.getTranslationLocale();
        }
      });
    },

    getCategories() {
      productFieldRepository
        .getCategories(this.$route.params.id)
        .then((result) => {
          this.categories = result.data.data;
          this.selectedCategory = this.selectedCategory
            ? this.selectedCategory
            : this.categories[0].name;
          this.getTranslationLocale();
        })
        .catch((err) => {
          err,
            showNotification(
              this.$t("notifications.categories"),
              this.$t(
                "notifications.something_went_wrong_while_fetching_categories",
              ),
              "error",
            );
        });
    },

    getTranslationLocale() {
      this.productFields = [];
      this.defaultLocale = this.locales.filter((locale) => {
        return locale.default;
      })[0];
      const loopLocales = this.locales.map((locale) => {
        return new Promise((resolve, reject) => {
          this.getTranslations(locale.tag).then(() => resolve());
        });
      });
      Promise.all(loopLocales).then(() => {
        this.combinedProductFields = this.productFields;
        this.loading = false;
      });
    },

    getTranslations(locale) {
      return this.repositoryPicker(locale)
        .then((result) => {
          // Create new array with the available locales with the corresponding productfields
          result.data.data.map((productField, index) => {
            // add original index to productField object used to update the productfield
            const originalIndex = { original_index: index };
            productField = { ...productField, ...originalIndex };
            if (!this.productFields[index]) {
              this.productFields.push({ [locale]: productField });
            } else {
              this.productFields[index] = {
                ...this.productFields[index],
                [locale]: productField,
              };
            }
          });
          // filter out available types
          const filterTypes = this.productFields.map((productField) => {
            const allTypes = Object.values(productField).map(
              (productFieldLanguage) => {
                if (this.isGameTranslations) {
                  return productFieldLanguage.json_type;
                }
                if (this.isLessonTranslations) {
                  return productFieldLanguage.unity_package_action_property.data
                    .media_type;
                }
              },
            );
            return new Array(allTypes);
          });
          const uniqueAvailableTypes = filterTypes
            .flat(2)
            .filter((value, index, array) => array.indexOf(value) === index);
          this.types = uniqueAvailableTypes;
          // set default type
          this.selectedType = this.selectedType
            ? this.selectedType
            : this.types[0];
        })
        .catch((err) => {
          err,
            showNotification(
              this.$t("notifications.product_field"),
              this.$t(
                "notifications.something_went_wrong_while_fetching_product_fields",
              ),
              "error",
            );
        });
    },

    repositoryPicker(locale) {
      if (this.isGameTranslations) {
        return productFieldRepository.getProductFields(
          this.$route.params.id,
          this.selectedCategory,
          locale,
        );
      }
      if (this.isLessonTranslations) {
        const page = 1;
        const maxResults = 10000;
        return lessonMediaRepository.getLessonMedia(
          this.$route.params.id,
          locale,
          page,
          maxResults,
        );
      }
    },

    toPage(p) {
      this.scrollToTop();
      this.currentPage = p;
    },
    nextPage() {
      this.scrollToTop();
      this.currentPage++;
    },
    previousPage() {
      this.scrollToTop();
      this.currentPage--;
    },
    searching(s) {
      this.searchInput = s;
    },
    scrollToTop() {
      this.$refs.top.$el.scrollIntoView({ behavior: "smooth" });
    },
    changeCategory(category) {
      this.selectedType = this.types[0];
      this.selectedCategory = category;
      this.getTranslationLocale(category);
    },
    changeType(val) {
      this.currentPage = 1;
      this.selectedType = val;
    },
  },
};
</script>

<style></style>
