<template>
  <div id="app">
    <router-view></router-view>
    <!-- animationtag -->
    <Notification />
    <!-- animationtag -->
  </div>
</template>

<script>
import Notification from "./components/helpers/Notification";
import "./assets/css/tailwind.css";

export default {
  name: "App",
  components: { Notification },
};
</script>

<style>
html {
  background-color: rgba(251, 250, 250, 1);
}
</style>
