<template>
  <div class="px-10 flex-auto pt-10 mobile:pt-1 mobile:px-1">
    <slot />
  </div>
</template>

<script>
export default {
  name: "PageContentContainer",
};
</script>

<style></style>
