export const keyboardShortcutList = [
  {
    name: "editor_shortcuts",
    shortcuts: [
      {
        name: "toggle_shortcut_menu",
        shortcut: ["Ctrl", "Shift", "/"],
      },
      {
        name: "search",
        check_os: true,
        shortcut: ["Ctrl", "f"],
        shortcut_mac: ["Cmd", "f"],
      },
    ],
  },
  {
    name: "timeline_shortcuts",
    shortcuts: [
      {
        name: "copy_id",
        shortcut: ["Ctrl", "Shift", "i"],
        quick_action: true,
      },
      {
        name: "show_full_title",
        shortcut: ["Shift", "n"],
        quick_action: true,
      },
      {
        name: "expand_all_visible",
        shortcut: ["Shift", "+"],
        quick_action: true,
      },
      {
        name: "collapse_all_visible",
        shortcut: ["Shift", "-"],
        quick_action: true,
      },
      {
        name: "cut_step_action",
        check_os: true,
        shortcut: ["Ctrl", "x"],
        shortcut_mac: ["Cmd", "x"],
        quick_action: true,
      },
      {
        name: "copy_step_action",
        check_os: true,
        shortcut: ["Ctrl", "c"],
        shortcut_mac: ["Cmd", "c"],
        quick_action: true,
      },
      {
        name: "paste_step_action",
        check_os: true,
        shortcut: ["Ctrl", "v"],
        shortcut_mac: ["Cmd", "v"],
        quick_action: true,
      },
    ],
  },
];
