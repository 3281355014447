<template>
  <PageContentContainer>
    <!-- <p class="text-26 text-lightAlineaColor font-bold mb-8">
      {{ $t("results.personal_results") }}
    </p> -->
    <!-- <div class="flex flex-row h-90 pl-10 items-center bg-standardBgColor">
        <p
          class="text-70 font-extrabold ml-10 font leading-none text-darkAlineaColor"
        >
          4
        </p>
        <div class="flex flex-col h-45 justify-between flex-1 pr-5">
          <p class="leading-none ml-14 font-light text-darkAlinea2Color">
            {{ $t("results.level") }}
          </p>
          <div class="bg-indigo-300 h-5 ml-14 bg-primaryColor opacity-25">
            <div class="h-full w-90 bg-primaryColor opacity-100"></div>
          </div>
        </div>
      </div> -->
    <div
      v-if="id"
      class="flex items-center p-3 backgroundCard h-130 justify-center mb-5"
    >
      <div class="flex flex-1 flex-wrap items-center justify-between">
        <div class="flex flex-row">
          <ProfileImage
            class="ml-3"
            :image="user ? user.image : ''"
            :firstname="user.firstname"
            :lastname="user.lastname"
            size="w-90 h-90"
          />
          <ResultsDetail
            class="ml-6"
            :title="user.email"
            :value="`${user.firstname} ${user.lastname}`"
          />
          <div
            v-if="customerOrDealerInactive"
            class="flex items-center ml-6"
            v-tooltip="{
              content: `<div class='flex flex-col z-100'>
                  <span>${getUserInactiveMessage}</span>
                </div>`,
              boundary: 'window',
              html: true,
            }"
          >
            <font-awesome-icon
              :icon="'exclamation-triangle'"
              class="fa-md text-40 text-yellow-500"
            />
          </div>
        </div>
        <ResultsDetail
          v-if="user.last_time_played"
          :title="$t('results.days_since_last_play')"
          :value="getDays"
        />
        <div class="flex justify-center items-center mr-6">
          <font-awesome-icon
            v-if="allowEdit"
            icon="edit"
            class="ml-6 fa-2x text-lightAlineaColor cursor-pointer hover:text-lightAlineaColor"
            @click="editUser(user)"
          />
        </div>
      </div>
    </div>
    <div class="flex items-center p-3 backgroundCard justify-center">
      <Loader v-if="loading" />
      <transition name="fade">
        <div
          v-if="!loading"
          class="flex flex-1 flex-wrap justify-between items-center"
        >
          <ResultsDetail
            :title="$t('results.hours_played')"
            :value="`${secondsToHours}`"
          />
          <ResultsDetail
            :title="$t('results.games_failed')"
            :value="`${percentageGamesFailed}%`"
          />
          <ResultsDetail
            :title="$t('results.games_completed')"
            :value="`${percentageGamesPassed}%`"
          />
          <ResultsDetail
            :title="$t('results.games_played')"
            :value="`${results.games_played}`"
          />
          <!-- <ResultsDetail
          :title="$t('results.trophies')"
          :value="`${completed_trophies}/${total_trophies}`"
          collapsible
          @showcollapsible="showCollapsible"
        /> -->
          <ResultsDetail
            :title="$t('results.trophies')"
            :value="`${results.completed_trophies}/${results.total_trophies}`"
          />
        </div>
      </transition>
    </div>
    <transition name="slide" transition="slide">
      <div v-show="show" class="bg-standardBgColor px-5 pb-5">
        <p class="text-22 mb-10 text-darkAlineaColor">
          {{ $t("results.trophies") }}
        </p>
        <div class="flex">
          <div class="scrollableBar flex flex-nowrap overflow-x-auto">
            <Trophy
              background-url="test1.jpg"
              color="gold"
              title="Welcome Aboard"
              :locked="false"
            />
            <Trophy
              background-url="test2.jpg"
              color="silver"
              title="Hazard awareness (level 1)"
              class="ml-24"
              :locked="false"
            />
            <Trophy
              background-url="test3.jpg"
              color="bronze"
              title="Hazard awareness (level 2)"
              class="ml-24"
              :locked="false"
            />
            <Trophy
              title="Hazard awareness (level 2)"
              color="none"
              class="ml-24"
              :locked="true"
            />
            <Trophy
              title="Hazard awareness (level 2)"
              color="none"
              class="ml-24"
              :locked="true"
            />
            <Trophy
              title="Hazard awareness (level 2)"
              color="none"
              class="ml-24"
              :locked="true"
            />
            <Trophy
              title="Hazard awareness (level 2)"
              color="none"
              class="ml-24"
              :locked="true"
            />
          </div>
        </div>
      </div>
    </transition>

    <div class="flex flex-row justify-between mt-8 mb-8 items-center">
      <p class="text-26 text-lightAlineaColor font-bold">
        {{ $t("results.game_results") }}
      </p>
    </div>

    <div
      v-if="game_results.length"
      id="highscore-header"
      class="h-60 flex items-center justify-center mobile:h-60"
    >
      <div
        class="flex flex-row items-center mobile:px-10 mobile:h-10 h-50 items-center w-full p-4"
      >
        <div class="flex flex-4 flex-col">
          <p
            class="text-20 text-lightAlineaColor text-left flex-1 font-medium mobile:text-16 mobile:hidden"
          >
            {{ $t("results.level_name") }}
          </p>
        </div>
        <div class="flex flex-2 flex-col">
          <p
            class="text-20 text-lightAlineaColor text-left flex-2 mobile:flex-5 font-medium mobile:text-16"
          >
            {{ $t("results.scored_points") }}
          </p>
        </div>
        <div class="flex flex-3 flex-col mobile:hidden">
          <p
            class="text-20 text-lightAlineaColor text-left flex-1 font-medium mobile:flex-2 mobile:text-16"
          >
            {{ $t("results.score_percentage") }}
          </p>
        </div>
        <div class="flex flex-3 flex-col mobile:hidden">
          <p
            class="text-20 text-lightAlineaColor text-left flex-1 font-medium mobile:hidden"
          >
            {{ $t("results.passing_percentage") }}
          </p>
        </div>
        <div class="flex flex-2 flex-col">
          <p
            class="text-20 text-lightAlineaColor text-left flex-1 font-medium mobile:flex-2 mobile:text-16"
          >
            {{ $t("results.time") }}
          </p>
        </div>
        <div class="flex flex-2 flex-col mobile:hidden">
          <p
            class="text-20 text-lightAlineaColor text-left flex-1 font-medium mobile:flex-2 mobile:text-16"
          >
            {{ $t("results.date") }}
          </p>
        </div>
        <div class="flex flex-2 flex-col items-end">
          <p
            class="text-20 text-lightAlineaColor text-right flex-1 font-medium mobile:flex-2 mobile:text-16"
          >
            {{ $t("results.level_passed") }}
          </p>
        </div>
      </div>
    </div>

    <ListPlaceholder
      v-if="!game_results.length"
      :text="this.$t('results.no_results')"
      class="mt-5"
    />
    <transition-group v-else name="fade" tag="span">
      <ResultsResultRow
        v-for="result in game_results"
        :key="result.gamesessionId"
        :levelname="result.lessonName"
        :score-points="result.score"
        :score-percentage="result.scorePercentage"
        :passing-percentage="result.passingPercentage"
        :time="calcTime(result.startTime, result.stopTime)"
        :date="getDate(result.startTime)"
        :passed-and-finished="result.passed_and_finished"
        @clickAction="toDetails(result.gamesessionId)"
      />
    </transition-group>
    <EditModal
      v-if="showEditModal"
      :user="user"
      @getUsers="fetchResults(id)"
      @close="showEditModal = false"
    />
  </PageContentContainer>
</template>

<script>
import ProfileImage from "@/components/profile/ProfileImage";
import PageContentContainer from "@/components/helpers/PageContentContainer";
import ResultsDetail from "@/components/results/ResultsDetail";
import ResultsResultRow from "@/components/results/ResultsResultRow";
import Trophy from "@/components/results/Trophy";
import Loader from "@/components/helpers/Loader";
import EditModal from "./users/EditModal";
import { errorHandlingAndMessage } from "@/services/ErrorService";
import { mapState } from "vuex";
import { enGB, nl } from "date-fns/locale";
import {
  addSeconds,
  differenceInSeconds,
  format,
  formatDistance,
  parseISO,
} from "date-fns";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import ListPlaceholder from "@/components/helpers/ListPlaceholder";
import { allowEdit } from "@/services/roleService";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const usersRepository = RepositoryFactory.get("user");

export default {
  name: "Results",
  components: {
    FontAwesomeIcon,
    ResultsDetail,
    Trophy,
    ResultsResultRow,
    PageContentContainer,
    Loader,
    EditModal,
    ProfileImage,
    ListPlaceholder,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    userDetails: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      show: false,
      list: false,
      seconds_played: 0,
      games_failed: 0,
      games_passed: 0,
      games_played: 0,
      completed_trophies: 0,
      total_trophies: 0,
      game_results: [],
      loading: true,
      showEditModal: false,
      edit: false,
      user: {
        image: "",
        firstname: "",
        lastname: "",
      },
      results: {
        seconds_played: "",
        games_failed: "",
        games_passed: "",
        games_played: "",
        completed_trophies: "",
        total_trophies: "",
        finished: "",
      },
    };
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),

    getDays: function () {
      return formatDistance(new Date(this.user.last_time_played), new Date(), {
        locale: this.getLanguage,
      });
    },

    customerOrDealerInactive: function () {
      return !!(
        (this.user.customer && !this.user.customer.data.enabled) ||
        (this.user.dealer && !this.user.dealer.data.enabled)
      );
    },

    getUserInactiveMessage: function () {
      if (this.user.customer && !this.user.customer.data.enabled) {
        return (
          this.$t("users.customer") +
          " " +
          this.$t("users.inactive").toLowerCase()
        );
      }
      if (this.user.dealer && !this.user.dealer.data.enabled) {
        return (
          this.$t("users.dealer") +
          " " +
          this.$t("users.inactive").toLowerCase()
        );
      }
      return "";
    },

    getLanguage: function () {
      switch (this.$i18n.locale) {
        case "nl":
          return nl;
        case "en":
          return enGB;
        default:
          return enGB;
      }
    },

    percentageGamesFailed: function () {
      const total = this.results.games_failed + this.results.games_passed;
      return isNaN(this.calcPercentage(this.results.games_failed, total))
        ? 0
        : this.calcPercentage(this.results.games_failed, total);
    },

    percentageGamesPassed: function () {
      const total = this.results.games_failed + this.results.games_passed;
      return isNaN(this.calcPercentage(this.results.games_passed, total))
        ? 0
        : this.calcPercentage(this.results.games_passed, total);
    },

    secondsToHours: function () {
      return Math.floor(this.results.seconds_played / 3600);
    },

    allowEdit: function () {
      return this.user.roles_reachable
        ? allowEdit(this.user.roles_reachable, this.currentUser.roles_reachable)
        : null;
    },
  },
  mounted() {
    if (this.id) {
      this.fetchResults(this.id);
      this.fetchUser(this.id);
    } else {
      this.fetchResults(this.currentUser.id);
      this.fetchUser(this.currentUser.id);
    }
  },
  methods: {
    isMobile() {
      if (screen.width <= 820) {
        return true;
      } else {
        return false;
      }
    },
    async fetchResults(id) {
      usersRepository
        .getUserResults(id)
        .then((result) => {
          this.results = result.data;
          this.loading = false;
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t(
              "notifications.something_went_wrong_receiving_user_details",
            ),
          );
        });

      usersRepository
        .getUser(id)
        .then((result) => {
          this.user = result.data.data;
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t("notifications.something_went_wrong_receiving_user"),
          );
        });
    },
    async fetchUser(id) {
      usersRepository
        .getUserGamesessions(id)
        .then((result) => {
          this.game_results = this.sortByDate(result.data.data);
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t(
              "notifications.something_went_wrong_receiving_user_details",
            ),
          );
        });
    },
    calcTime(start, end) {
      const seconds = differenceInSeconds(parseISO(end), parseISO(start));
      if (isNaN(seconds)) {
        return this.$t("results.no_time_set");
      } else {
        const helper = addSeconds(new Date(0), seconds);
        return format(helper, "mm:ss");
      }
    },
    getDate(date) {
      return format(
        parseISO(date),
        this.$i18n.locale === "en" ? "dd MMM yyyy" : "dd-MM-yyyy",
      );
    },

    toDetails(id) {
      this.$router.push({ name: "results-details", params: { id: id } });
    },
    calcPercentage(amount, total) {
      return Math.round((amount / total) * 100);
    },
    sortByDate(data) {
      return data.sort((a, b) => {
        return new Date(b.startTime) - new Date(a.startTime);
      });
    },
    editUser(user) {
      this.user = user;
      this.edit = true;
      this.showEditModal = true;
    },
  },
};
</script>

<style scoped>
.scrollableBar::-webkit-scrollbar {
  display: none;
}
</style>
