<template>
  <div class="flex flex-col justify-center mobile:items-center">
    <p class="mt-30 mb-15 text-lightAlineaColor font-medium text-22">
      {{ $t("download.generate_user_activity_report") }}
    </p>

    <div
      class="backgroundCard flex w-full flex-col justify-start justify-items-start desktop:mr-30 mobile:my-5"
    >
      <div class="mb-3">
        <DatePicker
          :range="selectedRange"
          :locale="this.$i18n.locale"
          @update:range="
            (val) => {
              selectedRange = val;
            }
          "
        />
      </div>
      <Button
        class="pl-8 mb-5"
        :title="$t('download.download')"
        button-type="primary"
        :loading="loading"
        :invalid="false"
        @clickAction="download(downloadableFile)"
      />
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const reportingRepository = RepositoryFactory.get("reporting");
import Button from "@/components/form/Button";
import { subDays } from "date-fns";
import DatePicker from "@/components/reporting/DatePicker";
import { errorHandlingAndMessage } from "@/services/ErrorService";

export default {
  name: "Download",
  components: {
    Button,
    DatePicker,
  },
  data() {
    return {
      selectedRange: {
        start: subDays(new Date(), 30),
        end: new Date(),
      },
      downloadableFile: null,
      loading: false,
    };
  },
  watch: {
    selectedRange: {
      handler() {
        this.generate();
      },
      deep: true,
    },
  },

  mounted() {
    this.generate();
  },

  methods: {
    generate() {
      this.loading = true;
      reportingRepository
        .getActivityDataCSV(this.selectedRange.start, this.selectedRange.end)
        .then((result) => {
          this.downloadableFile = result.data.download_url;
          this.loading = false;
        })
        .catch(() => {});
    },

    download(url) {
      this.loading = true;
      reportingRepository
        .downloadCSV(url)
        .then((result) => {
          window.open(result.data.data.uri, "_blank");
          this.loading = false;
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t("notifications.something_went_wrong_downloading_the_csv"),
          );
        });
    },

    cancel() {
      this.showModal = false;
    },
  },
};
</script>
