<template>
  <ModalContainer @cancel="cancel">
    <div class="bg-white shadow sm:rounded-lg" v-click-outside="cancel">
      <div class="px-4 py-5 sm:p-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          {{ $t("layout.you_dont_have_a_student_role") }}
        </h3>
        <div class="mt-2 max-w-xl text-sm leading-5 text-gray-500">
          <p>
            {{
              $t(
                "layout.please_fill_in_the_required_activation_code_you_received_from_your_company",
              )
            }}
          </p>
        </div>
        <div class="mt-5 sm:flex sm:items-center">
          <div class="w-full">
            <label for="activationcode" class="sr-only">Activation Code</label>
            <div class="relative rounded-md shadow-sm">
              <input
                id="activationcode"
                class="form-input block w-full sm:text-sm sm:leading-5"
                v-model="activationCode"
                :class="{
                  'border-rapportRed text-rapportRed': activationError,
                  'border-borderColor': !activationError,
                }"
                placeholder="Activation Code"
                @click="clear()"
              />
            </div>
          </div>
        </div>
        <div class="flex justify-end mt-5">
          <Button
            button-type="primary"
            :loading="loading"
            :title="$t('layout.activate')"
            @clickAction="send()"
          />
          <Button
            class="ml-3"
            button-type="secondary"
            :title="$t('layout.cancel')"
            @clickAction="cancel"
          />
        </div>
      </div>
    </div>
  </ModalContainer>
</template>

<script>
import ModalContainer from "../ModalContainer";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import { errorHandlingAndMessage } from "@/services/ErrorService";
import Button from "../../form/Button";
import { showNotification } from "@/services/notificationService";
const usersRepository = RepositoryFactory.get("user");

export default {
  name: "ActivationCodeModal",
  components: {
    ModalContainer,
    Button,
  },
  data() {
    return {
      showModal: this.show,
      activationCode: "",
      activationError: false,
      loading: false,
    };
  },
  methods: {
    send() {
      this.loading = true;
      usersRepository
        .activate(this.activationCode)
        .then((result) => {
          this.$emit("cancel");
          this.$store.dispatch("userStore/SET_CURRENT_USER", result.data.data);
          showNotification(
            this.$t("notifications.successfully_activated_title"),
            this.$t("notifications.successfully_activated_message"),
            "success",
          );
        })
        .catch((error) => {
          errorHandlingAndMessage(
            error,
            this.$t(
              "notifications.something_went_wrong_activating_your_account",
            ),
          );
          this.loading = false;
          this.activationError = true;
          this.activationCode = "Wrong code";
        });
    },
    clear() {
      this.loading = false;
      this.activationError = false;
      this.activationCode = "";
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style></style>
