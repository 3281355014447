<template>
  <div class="mb-5">
    <div class="flex flex-row">
      <div class="flex justify-between">
        <label
          for="input"
          class="block text-sm font-medium leading-5 text-gray-200"
        >
          {{ title }}
        </label>
        <span v-if="requiredInput" class="text-sm leading-5 text-gray-500">
          {{ $t("game.required") }}
        </span>
      </div>
      <div
        v-if="hint"
        class="flex justify-center bg-white items-center h-3 w-3 rounded-full mt-1 ml-1"
        v-tooltip="{ content: hint, boundary: 'window' }"
      >
        <p class="text-sidebar font-bold text-9">?</p>
      </div>
    </div>
    <!-- <span
      :class="{ 'bg-red-500': !newValue, 'bg-green-500': newValue }"
      class="mt-1 relative inline-block flex-no-shrink h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring"
      role="checkbox"
      tabindex="0"
      @click="toggle()"
      @keydown.space.prevent="toggle()"
      :aria-checked="newValue.toString()"
    >
      <span
        aria-hidden="true"
        @click="toggle()"
        @keydown.space.prevent="toggle()"
        :class="{ 'translate-x-5': newValue, 'translate-x-0': !newValue }"
        class="inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"
      ></span>
    </span> -->
    <span
      role="checkbox"
      tabindex="0"
      aria-checked="false"
      class="group relative inline-flex items-center justify-center flex-shrink-0 h-5 w-10 cursor-pointer focus:outline-none mt-1"
      @click="toggleAction"
    >
      <!-- On: "bg-indigo-600", Off: "bg-gray-200" -->
      <span
        aria-hidden="true"
        :class="{ 'bg-green-500': toggle, 'bg-gray-200': !toggle }"
        class="absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200"
      ></span>
      <!-- On: "translate-x-5", Off: "translate-x-0" -->
      <span
        aria-hidden="true"
        :class="{ 'translate-x-5': toggle, 'translate-x-0': !toggle }"
        class="absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform group-focus:ring group-focus:border-blue-300 transition-transform ease-in-out duration-200"
      ></span>
    </span>
  </div>
</template>

<script>
export default {
  name: "ToggleButton",
  props: {
    modelValue: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    requiredInput: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: "",
    },
    activeColor: {
      type: String,
      default: "#6b7280",
    },
  },
  data: () => {
    return {
      toggle: false,
    };
  },
  mounted() {
    this.toggle = this.modelValue;
  },
  methods: {
    toggleAction: function () {
      this.toggle = !this.toggle;
      this.$emit("toggle", this.toggle);
    },
  },
};
</script>

<style></style>
