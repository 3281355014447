import Repository from "./Repository";

const resource = "/api";

export default {
  createUserToken(payload) {
    return Repository.post(`${resource}/login_check`, payload);
  },

  confirmActivationToken(payload) {
    return Repository.post(`${resource}/user/confirm-registration`, {
      token: payload,
    });
  },

  registerNewUser(payload) {
    return Repository.post(`${resource}/user/register`, payload);
  },

  resetPassword(payload) {
    return Repository.post(`${resource}/user/reset-password`, {
      username: payload,
    });
  },

  newPassword(payload) {
    return Repository.post(`${resource}/user/reset-password/${payload.token}`, {
      password: payload.password,
    });
  },
};
