import { errorHandlingAndMessage } from "@/services/ErrorService";
import { mapState } from "vuex";
import { disableShortcuts } from "@/services/disableShortcutsService";

export default {
  data() {
    return {
      uploadError: false,
      fileSrc: null,
      fileLoading: true,
      showMediaModal: false,
    };
  },
  computed: {
    ...mapState("keyboardShortcutStore", ["disableShortcuts"]),
  },
  methods: {
    toggleMediaModal() {
      this.showMediaModal = !this.showMediaModal;
      disableShortcuts(!this.disableShortcuts);
    },
    initializeUpload() {
      this.$emit("addLoading", this.unityPackageActionPropertyId);
      this.uploadError = false;
      this.fileSrc = null;
      this.fileLoading = true;
    },
    finishUpload(file, fileUrl, required) {
      // if (required) {
      //   this.$refs.provider.validate(file[0]);
      // }
      const isFile = true;
      this.setFileInfo(file, isFile);
      this.$emit("update:modelValue", fileUrl);
    },
    selectFile(file) {
      this.$emit("update:modelValue", file.id);
      this.setFileInfo(file);
      this.toggleMediaModal();
    },
    handleUploadError() {
      this.uploadError = true;
      errorHandlingAndMessage(
        this.$t("notifications.something_went_wrong_uploading_this_file"),
      );
    },
    finalizeUpload() {
      this.fileLoading = false;
      this.toggleMediaModal();
      this.$emit("removeLoading", this.unityPackageActionPropertyId);
    },
  },
};
