<template>
  <div class="flex flex-col">
    <p class="text-14 font-light text-lightAlineaColor leading-none mb-1">
      {{ title }}
    </p>
    <p
      v-if="!edit"
      class="text-17 font-normal text-lightAlineaColor leading-none"
    >
      {{ modelValue }}
    </p>
    <input
      v-else
      type="text"
      class="appearance-none border-b bg-transparent w-full text-lightAlineaColor focus:outline"
      :class="{
        'border-rapportRed': errorMessage,
        'border-borderColor': !errorMessage,
      }"
      :value="modelValue"
      @input="updateValue"
      ref="input"
    />
    <p class="text-rapportRed text-11 mt-2 leading-none">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
export default {
  name: "OutputField",

  props: {
    title: { type: String, default: "" },
    modelValue: { type: String, default: "" },
    edit: { type: Boolean, default: false },
    errorMessage: {
      type: String,
      default: "",
    },
  },
  methods: {
    updateValue(e) {
      this.$emit("update:modelValue", e.target.value);
    },
  },
};
</script>

<style></style>
