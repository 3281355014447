<template>
  <div class="">
    <label class="block text-sm leading-5 font-medium text-gray-700">
      {{ title }}
    </label>
    <div
      class="mt-1 flex flex-1"
      :class="{
        'flex-col items-start': align === 'column',
        'flex-row items-center': align === 'row',
      }"
    >
      <div
        class="w-full rounded-md overflow-hidden bg-white border border-gray-300 shadow-md background"
      >
        <div
          v-if="loading"
          class="flex"
          :style="{ width: '335px', height: '275px', maxWidth: 'initial' }"
        >
          <loader class="m-auto"></loader>
        </div>

        <div v-else>
          <div
            v-if="imgSrc"
            :style="{
              width: '335px',
              height: '275px',
              maxWidth: 'initial',
              backgroundImage: 'url(' + (showOrigional ? url : imgSrc) + ')',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }"
          ></div>
          <div
            v-else
            class="flex justify-center items-center"
            :style="{ width: '335px', height: '275px', maxWidth: 'initial' }"
          >
            <font-awesome-icon icon="image" class="fa-7x text-gray-300" />
          </div>
        </div>
      </div>

      <div
        :class="{
          'mt-5': align === 'column',
          'ml-5': align === 'row',
        }"
        class="overflow-hidden relative mt-4 mb-4 flex flex-row"
      >
        <div>
          <input
            type="file"
            name="file"
            id="photo"
            class="hidden"
            accept="image/png, image/jpeg"
            @change="setImage"
            data-cy="gameImageUpload"
          />
          <label
            for="photo"
            class="py-3 px-3 mt-2 rounded-md text-sm leading-4 w-auto flex justify-center items-center font-medium bg-gray-500 text-gray-100 cursor-pointer hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
            >{{ $t("game.upload") }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "cropperjs/dist/cropper.css";
import Loader from "@/components/helpers/Loader.vue";

export default {
  name: "Photo",
  components: { Loader },
  props: {
    title: {
      type: String,
      default: "Title",
    },
    url: {
      type: String,
      default: "",
    },
    align: {
      type: String,
      default: "row",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showOrigional: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      reader: new FileReader(),
      imgSrc: "",
      cropImg: "",
    };
  },

  created() {
    if (this.url) {
      this.imgSrc = this.url;
    }
  },

  methods: {
    setImage(event) {
      const input = event.target;

      if (input.files && input.files[0]) {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.imgSrc = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
      this.$emit("newImage", input.files[0]);
    },
  },
};
</script>

<style scoped>
.background {
  background-repeat: no-repeat;
}

.imageContainer {
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.fileContainer {
  overflow: hidden;
  position: relative;
}

.fileContainer [type="file"] {
  cursor: inherit;
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}
</style>
