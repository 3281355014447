<template>
  <div class="w-48" @keypress.enter="search(value)">
    <!-- <label
      for="price"
      class="block text-sm font-medium leading-5 text-gray-100 tracking-widest"
    >
      Search
    </label> -->
    <div class="relative rounded-md shadow-md">
      <input
        id="search"
        class="form-input text-gray-800 block w-48 h-10 pl-3 pr-3 bg-gray-100 border-1 rounded-full sm:text-sm sm:leading-5"
        type="text"
        placeholder="Search"
        v-model="value"
      />
      <div
        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
      >
        <font-awesome-icon
          icon="search"
          class="fa-sm text-secondaryColor"
          @click="search(value)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchBar",
  props: {
    title: {
      type: String,
      default: "Title",
    },
    placeholder: {
      type: String,
      default: "Placeholder",
    },
  },
  data() {
    return {
      value: "",
    };
  },
  watch: {
    value: {
      handler(val) {
        this.$emit("search", val);
      },
      deep: true,
    },
  },
  methods: {
    search(i) {
      this.$emit("search", i);
    },
  },
};
</script>
