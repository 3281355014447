<template>
  <div class="w-full">
    <div v-if="title || requiredInput" class="flex justify-between mb-1">
      <label
        :for="uid"
        class="block text-sm font-medium leading-5"
        :class="[textColor]"
      >
        {{ title }}
      </label>
      <span v-if="requiredInput" class="text-sm leading-5" :class="[textColor]">
        {{ $t("game.required") }}
      </span>
      <span v-if="loadingStatus" class="text-sm leading-5" :class="[textColor]">
        {{ loadingMessage }}
        <font-awesome-icon
          :icon="loadingIcon"
          class="fa-lg mr-1"
          :class="loadingStatus === 2 ? 'text-green-500' : 'text-gray-500'"
        />
      </span>
    </div>
    <div
      class="relative rounded-md"
      :class="[
        {
          'shadow-sm ': false,
        },
        style,
      ]"
    >
      <input
        v-if="blur"
        v-on="validationListeners"
        class="form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        :class="[
          {
            'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red':
              errorMessage && !focus,
          },
          {
            'border-gray-100 text-gray-300': disabled,
          },
          style,
        ]"
        :type="type"
        :placeholder="placeholder"
        :value="inputValue"
        :min="min"
        :max="max"
        @focusin="setFocus(true)"
        @focusout="setFocus(false)"
        @blur="updateField(meta.dirty)"
        :id="uid"
        :data-cy="cy"
        :disabled="disabled"
      />
      <input
        v-else
        v-on="validationListeners"
        class="form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        :class="[
          {
            'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red':
              errorMessage && !focus,
          },
          {
            'border-gray-100 text-gray-300': disabled,
          },
          style,
        ]"
        :type="type"
        :placeholder="placeholder"
        :value="inputValue"
        :min="min"
        :max="max"
        @focusin="setFocus(true)"
        @focusout="setFocus(false)"
        :id="uid"
        :data-cy="cy"
        :disabled="disabled"
      />
      <div
        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
      >
        <svg
          v-if="errorMessage && !focus"
          class="h-5 w-5 text-red-500"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </div>
    <div v-if="errorMessage && !focus" class="h-6 mt-px">
      <p class="text-sm text-red-600">
        {{ errorMessage }}
      </p>
    </div>
    <div v-else class="h-6 mt-px"></div>
  </div>
</template>

<script>
import useValidation from "@/helpers/useValidation";
export default {
  name: "InputField",
  props: {
    modelValue: {
      type: [String, Number, Date],
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    requiredInput: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    rules: {
      type: String,
      default: "",
    },
    mode: {
      type: String,
      default: "",
    },
    light: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: true,
    },
    cy: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: String,
      default: "",
    },
    max: {
      type: String,
      default: "",
    },
    blur: {
      type: Boolean,
      default: false,
    },
    loadingStatus: {
      type: Number,
      default: 0,
    },
  },
  emits: ["update:modelValue", "updateField"],
  setup(props, { emit }) {
    const { validationListeners, errorMessage, inputValue, meta, updateField } =
      useValidation(props, emit);

    return {
      validationListeners,
      errorMessage,
      inputValue,
      meta,
      updateField,
    };
  },
  data() {
    return {
      focus: false,
      uid: "",
    };
  },
  computed: {
    style: function () {
      if (this.light) return "text-gray-100 bg-rowHover";
      else return "border-gray-300";
    },
    textColor: function () {
      if (this.light) return "text-gray-100";
      else return "text-gray-700";
    },
    loadingMessage: function () {
      switch (this.loadingStatus) {
        case 1:
          return `${this.$t("general.saving")}`;
        case 2:
          return `${this.$t("general.saved")}!`;
        default:
          return "";
      }
    },
    loadingIcon: function () {
      switch (this.loadingStatus) {
        case 1:
          return "spinner";
        case 2:
          return "check";
        default:
          return "";
      }
    },
  },
  created() {
    this.uid = this.id ? this.id : this._uid;
  },
  methods: {
    setFocus(boolean) {
      this.focus = boolean;
    },
  },
};
</script>
