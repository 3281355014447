export function getOS() {
  let userAgent = window.navigator.userAgent,
    platform =
      window.navigator?.platform ||
      window.navigator?.userAgentData?.platform ||
      "unknown",
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K", "macOS"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    os = "";

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "macos";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "windows";
  } else if (/Android/.test(userAgent)) {
    os = "androidapk";
  }
  return os;
}
