<template>
  <div
    class="bg fixed inset-0 flex justify-center items-center z-[100] overflow-auto"
    data-cy="modal"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "ModalContainer",
  created() {
    document.onkeydown = (evt) => {
      if (evt.key === "Escape" || evt.key === "Esc") {
        this.hide();
      }
    };
  },
  methods: {
    hide() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped>
.bg {
  background: #0000001f;
}
</style>
