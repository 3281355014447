import Repository from "./Repository";

const resource = "/api/v3/lessons";

export default {
  getLesson(lessonId, includes = null) {
    const defaultIncludes =
      "image,steps.actions.unity_package_action,steps.actions.action_properties,steps.actions.action_properties.media,trophies,trophy_dependencies,unity_package";

    return Repository.get(
      `${resource}/${lessonId}?include=${includes ?? defaultIncludes}`,
    );
  },

  getLessonHighscore(lessonId) {
    return Repository.get(`${resource}/${lessonId}/highscores`);
  },

  exportLesson(lessonId) {
    return Repository.get(`${resource}/${lessonId}/export`);
  },

  importStep(lessonId, step) {
    return Repository.post(`${resource}/${lessonId}/import-step`, step);
  },

  getLessons(versionId) {
    return Repository.get(`${resource}?training_version_id=${versionId}`);
  },

  search(lessonId, query) {
    return Repository.post(`${resource}/${lessonId}/search`, query);
  },
};
