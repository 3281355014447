<template>
  <div ref="targetEl" :class="width">
    <slot v-if="shouldRender" />
    <div v-else class="flex items-center justify-center" :class="width">
      <Loader />
    </div>
  </div>
</template>

<script>
import { useIntersectionObserver } from "@vueuse/core";
import { ref, nextTick } from "vue";
import Loader from "@/views/editor/components/Loader";
import { useStore } from "vuex";

function onIdle(cb = () => {}) {
  if ("requestIdleCallback" in window) {
    window.requestIdleCallback(cb);
  } else {
    setTimeout(() => {
      nextTick(cb);
    }, 300);
  }
}

export default {
  components: { Loader },
  props: {
    id: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    staggerStart: {
      type: Number,
      default: 5,
    },
    renderOnIdle: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: "w-350",
    },
  },
  setup(props) {
    const shouldRender = ref(false);
    const targetEl = ref();

    const { stop } = useIntersectionObserver(
      targetEl,
      ([{ isIntersecting }]) => {
        if (isIntersecting) {
          shouldRender.value = true;
          stop();
        }
      },
      {
        rootMargin: "600px",
      },
    );

    if (props.renderOnIdle) {
      onIdle(() => {
        shouldRender.value = true;
        stop();
      });
    }

    return { targetEl, shouldRender };
  },
};
</script>
