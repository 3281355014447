<template>
  <ModalContainer @cancel="close">
    <div class="bg-white w-1/2 shadow overflow-hidden sm:rounded-lg">
      <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
        <div
          class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap"
        >
          <div class="ml-4 mt-4">
            <h3 class="text-lg leading-6 font-medium text-blue-900">
              {{ $t("sync.sync_status") }}
            </h3>
            <p v-if="!loading" class="mt-1 text-sm leading-5 text-gray-500">
              {{ $t("sync.diff_against_unity_package") }}
              {{
                syncData
                  ? syncData.diff_status.unity_package_version_diffed
                  : ""
              }}
            </p>
          </div>
          <div class="ml-4 mt-4 flex-shrink-0">
            <font-awesome-icon
              icon="times"
              class="text-blue-900 text-2xl right-0 mr-3 flex items-center cursor-pointer"
              @click="close"
            />
          </div>
        </div>
      </div>
      <div class="sm:p-0">
        <div v-if="loading" class="flex justify-center items-center px-4 py-5">
          <Loader />
        </div>
        <dl v-else>
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
            <dt class="text-sm leading-5 font-normal text-gray-500">
              {{ $t("sync.diff_status") }}
            </dt>
            <dd
              class="mt-1 text-base leading-5 font-bold text-blue-900 sm:mt-0 sm:col-span-2"
            >
              {{
                syncData.diff_status.ready
                  ? $t("sync.complete")
                  : $t("sync.incomplete")
              }}
            </dd>
          </div>
          <div
            class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
          >
            <dt
              class="flex text-sm leading-5 items-center font-normal text-gray-500"
            >
              {{ $t("sync.sync_level") }}
            </dt>
            <dd
              class="flex flex-row justify-between items-center mt-1 text-base leading-5 font-bold text-blue-900 sm:mt-0 sm:col-span-2"
            >
              {{
                syncData.is_diff_level_minor
                  ? $t("sync.minor")
                  : $t("sync.major")
              }}
              <Button
                v-if="(canStartSync || canFinishSync) && !isSyncBusy"
                button-type="primary"
                :loading-text="$t('sync.starting_sync')"
                :loading="isSyncBusy"
                :invalid="isSyncDone"
                :title="
                  canFinishSync
                    ? $t('sync.finish_sync')
                    : isSyncDone
                    ? $t('sync.done')
                    : $t('sync.start_sync')
                "
                @clickAction="getData(true)"
              />
              <Loader v-if="isSyncBusy" />
            </dd>
          </div>
          <div
            class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
          >
            <dt class="text-sm leading-5 font-normal text-gray-500">
              {{ $t("sync.sync_phases") }}
            </dt>
            <dd
              class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
            >
              <ul class="border border-gray-200 rounded-md">
                <li
                  class="pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5"
                >
                  <div class="w-0 flex-1 flex items-center">
                    <span class="ml-2 flex-1 font-normal w-0 truncate">
                      {{ $t("sync.diff") }}
                    </span>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <font-awesome-icon
                      icon="check"
                      class="text-green-500 text-16 right-0 mr-3 flex items-center"
                      v-if="syncData.diff_status.ready"
                    />
                    <p v-else>
                      {{
                        `${syncData.diff_status.actions_diffed_count}/${
                          syncData.diff_status.actions_diffed_count -
                          syncData.diff_status.actions_not_diffed_count
                        }`
                      }}
                    </p>
                  </div>
                </li>
                <li
                  class="pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5"
                >
                  <div class="w-0 flex-1 flex items-center">
                    <span class="ml-2 flex-1 font-normal w-0 truncate">
                      {{ $t("sync.copy_lessons") }}
                    </span>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <font-awesome-icon
                      v-if="
                        syncData.copy_tree_status_details.new.lessons >=
                        syncData.copy_tree_status_details.previous.lessons
                      "
                      icon="check"
                      class="text-green-500 text-16 right-0 mr-3 flex items-center"
                    />
                    <p v-else>
                      {{
                        `${syncData.copy_tree_status_details.new.lessons}/${syncData.copy_tree_status_details.previous.lessons}`
                      }}
                    </p>
                  </div>
                </li>
                <li
                  class="pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5"
                >
                  <div class="w-0 flex-1 flex items-center">
                    <span class="ml-2 flex-1 font-normal w-0 truncate">
                      {{ $t("sync.copy_steps") }}
                    </span>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <font-awesome-icon
                      v-if="
                        syncData.copy_tree_status_details.new.steps >=
                        syncData.copy_tree_status_details.previous.steps
                      "
                      icon="check"
                      class="text-green-500 text-16 right-0 mr-3 flex items-center"
                    />
                    <p v-else>
                      {{
                        `${syncData.copy_tree_status_details.new.steps}/${syncData.copy_tree_status_details.previous.steps}`
                      }}
                    </p>
                  </div>
                </li>
                <li
                  class="pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5"
                >
                  <div class="w-0 flex-1 flex items-center">
                    <span class="ml-2 flex-1 font-normal w-0 truncate">
                      {{ $t("sync.copy_actions") }}
                    </span>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <font-awesome-icon
                      v-if="
                        syncData.copy_tree_status_details.new.actions >=
                        syncData.copy_tree_status_details.previous.actions
                      "
                      icon="check"
                      class="text-green-500 text-16 right-0 mr-3 flex items-center"
                    />
                    <p v-else>
                      {{
                        `${syncData.copy_tree_status_details.new.actions}/${syncData.copy_tree_status_details.previous.actions}`
                      }}
                    </p>
                  </div>
                </li>
              </ul>
            </dd>
          </div>
          <div
            class="mt-8 sm:mt-0 sm:grid sm:grid-cols-2 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
          >
            <dt
              class="flex text-sm leading-5 items-center font-normal text-gray-500"
            >
              {{ $t("sync.sync_ready") }}
            </dt>
            <dd class="flex justify-end mr-4">
              <font-awesome-icon
                v-if="syncData.is_sync_ready"
                icon="check"
                class="text-green-500 text-16 right-0 mr-3 flex items-center"
              />
              <font-awesome-icon
                v-else
                icon="times"
                class="text-red-500 text-16 right-0 mr-3 flex items-center"
              />
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </ModalContainer>
</template>

<script>
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const trainingRepository = RepositoryFactory.get("training");
import ModalContainer from "@/components/helpers/ModalContainer";
import Loader from "@/views/editor/components/Loader";
import Button from "@/components/form/Button";

export default {
  components: { ModalContainer, Loader, Button },
  props: {
    version: {
      type: String,
      required: true,
    },
    trainingId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      syncData: null,
      loading: true,
      canStartSync: false,
      isSyncBusy: false,
      canFinishSync: false,
      polling: null,
      isSyncDone: false,
    };
  },
  mounted() {
    this.getData(false, true);
  },
  methods: {
    startPolling() {
      this.polling = setInterval(() => {
        this.getData();
      }, 10000);
    },
    close() {
      this.$emit("close");
    },
    getData(runNext, initial) {
      this.isSyncBusy = true;
      trainingRepository
        .syncStatus(this.trainingId, this.version, runNext)
        .then((result) => {
          this.syncData = result.data;
          this.canStartSync = this.syncData.can_start_sync;
          this.canFinishSync = this.syncData.can_finish_sync;
          this.isSyncBusy =
            !this.syncData.can_finish_sync &&
            !this.syncData.can_start_sync &&
            !this.syncData.is_sync_ready;

          if (this.isSyncBusy && this.polling === null) {
            this.startPolling();
          }

          if (this.canStartSync || this.canFinishSync) {
            clearInterval(this.polling);
            this.polling = null;
          }
        })
        .catch(() => {
          clearInterval(this.polling);
          this.polling = null;
        })
        .finally(() => {
          if (this.syncData.is_sync_ready) {
            clearInterval(this.polling);
            this.polling = null;
            this.isSyncBusy = false;
            this.isSyncDone = true;
          }
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
