<template>
  <div
    class="relative"
    @click="setActiveGame"
    @mouseenter="setShowMenuItems(true)"
    @mouseleave="setShowMenuItems(false)"
  >
    <div
      class="w-gameCardWidth h-gameCardHeight object-contain shadow-darkBg overflow-hidden rounded-15 flex flex-col bg-cover bg-no-repeat bg-center border-4 hover:shadow-none hover:border-secondaryColor"
      :class="[
        {
          'hover:shadow-hoverButtonShadow': game.active,
        },
        activeStyle,
        activeColor,
        game.version ? 'cursor-pointer' : 'cursor-not-allowed',
      ]"
      :style="[backgroundImage]"
    >
      <div
        class="font-bold text-gray-700 text-18 bg-white bg-opacity-40 mb-14 mt-20 px-6 py-4 min-h-fit"
      >
        <span>{{ game.name }}</span>
      </div>
      <div v-if="game.active" class="flex-1 flex flex-col justify-end">
        <div
          v-if="
            game.version &&
            (streamingEnabled ||
              gameUriEnabledAndDownloaded ||
              game.downloadAvailable)
          "
          class="flex h-10 w-8/12"
          @click="startGame"
          :class="[
            { 'bg-secondaryColor': showMenuItems === true },
            activeBarColor,
          ]"
        >
          <div class="flex flex-7 flex-row ml-5 items-center">
            <font-awesome-icon icon="play" class="fa-sm" style="color: white" />
            <p
              class="ml-5 font-bold text-18 text-white uppercase hover:text-blue-500"
            >
              {{ `${$t("home.play_now").toUpperCase()}` }}
            </p>
          </div>
          <div
            class="flex flex-1 skew-20 -mr-3"
            :class="[
              { 'bg-secondaryColor': showMenuItems === true },
              activeButton,
              activeBarColor,
            ]"
          ></div>
        </div>
        <p v-else class="mt-5"></p>
        <p
          class="flex text-lightAlineaColor font-light leading-none mb-3 mt-3 self-center cursor-pointer hover:text-secondaryColor"
          @click="flip"
        >
          {{ $t("home.downloads") }}
        </p>
      </div>
    </div>
    <div
      v-if="showOptionMenu || adminRole"
      class="absolute top-0 right-0 flex flex-auto justify-end transition-opacity transition duration-300"
    >
      <div
        class="flex h-10 w-10 justify-center items-center bg-secondaryColor rounded-bl-2xl rounded-tr-15"
      >
        <OptionMenu
          :options="options"
          target="chapterTranslation"
          icon="cog"
          color="text-gray-50"
          position="center"
          :separation-line="2"
          @exportTranslation="
            showTranslationModal('export', 'product', game.id)
          "
          @importTranslation="
            showTranslationModal('import', 'product', game.id)
          "
          @recentTranslationFiles="
            showTranslationModal('files', 'product', game.id)
          "
          @codeBranch="
            $router.push({ name: 'versions', params: { id: game.id } })
          "
          @edit="showModal('edit', true, game)"
          @delete="showModal('delete', true, game)"
          @showOptionMenu="toggleOptionMenu"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import OptionMenu from "@/components/helpers/OptionMenu";
import { showNotification } from "@/services/notificationService";
import { getOS } from "@/helpers/osCheck";

export default {
  name: "CardFront",

  components: {
    OptionMenu,
  },

  props: {
    logoUrl: {
      type: String,
      default: "",
    },
    downloadable: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    game: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      project: localStorage.getItem("project"),
      showMenuItems: false,
      showOptionMenu: false,
      streamingEnabled: this.game.onlineGameplayEnabled,
      appUriEnabled: process.env.VUE_APP_ENABLE_APP_URI_SUPPORT
        ? process.env.VUE_APP_ENABLE_APP_URI_SUPPORT === "enabled"
        : false,
      options: [
        { option: "importTranslation", name: "Import translations" },
        { option: "exportTranslation", name: "Export translations" },
        { option: "recentTranslationFiles", name: "Recent exported files" },
        { option: "codeBranch", name: "Game versions" },
        { option: "edit", name: "Edit game" },
        { option: "delete", name: "Delete Game" },
      ],
    };
  },

  computed: {
    ...mapState("userStore", ["currentUser"]),
    ...mapState("trainingStore", ["activeTraining"]),
    ...mapState("translationStore", [
      "showImportModal",
      "showExportModal",
      "showTranslationFilesModal",
    ]),

    adminRole: function () {
      return this.currentUser.roles.includes("ROLE_SUPER_ADMIN");
    },

    activeStyle: function () {
      if (this.activeTraining && this.activeTraining.id === this.game.id) {
        return "border-4 shadow-none border-secondaryColor";
      } else {
        return "";
      }
    },

    activeColor: function () {
      if (this.game.active) return "bg-primaryColor border-primaryColor";
      else return "bg-gray-400 border-gray-600 opacity-50";
    },

    activeBarColor: function () {
      if (this.game.active) return "bg-primaryColor";
      else return "bg-gray-600";
    },

    activeButton: function () {
      if (this.activeTraining && this.activeTraining.id === this.game.id) {
        return "bg-secondaryColor";
      } else {
        return "";
      }
    },

    backgroundImage: function () {
      return {
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.30)), ${
          this.image ? "url(" + this.image + ")" : "url()"
        }`,
      };
    },

    gameUriEnabledAndDownloaded: function () {
      if (this.appUriEnabled && "windows" === getOS()) {
        return this.currentUser.played_games.some((game) => {
          return game === this.game.id;
        });
      }
      return false;
    },
  },

  methods: {
    flip() {
      this.$emit("flip");
    },

    startGame() {
      if (this.streamingEnabled) {
        this.openGamePage();
      } else if (this.gameUriEnabledAndDownloaded) {
        this.openGameOnClient();
      } else {
        this.startDownload();
      }
    },

    openGameOnClient() {
      this.$store
        .dispatch("userStore/GET_USER_PINCODE", this.game.id)
        .then((res) => {
          const url = this.game.appUri.replace("#PIN#", `${res.data.pin}`);
          window.open(url, "_blank");
          const type = "downloadable";
          const content = {
            type: "downloadable",
            download_url: this.downloadable,
          };
          this.$store.dispatch("modalStore/SHOW_GAME_INFO_MODAL", content);
        })
        .catch(() => {
          showNotification(
            this.$t("notifications.unable_to_start_game"),
            this.$t(
              "notifications.something_went_wrong_while_attempting_to_start_this_game_please_try_again",
            ),
            "error",
          );
        });
    },

    openGamePage() {
      this.$router.push({
        name: "play",
        params: { id: this.game.id, versionId: this.game.version },
      });
    },

    showModal(type, val, game) {
      this.$store.dispatch("trainingStore/SHOW_MODAL", {
        val: val,
        type: type,
        training: game,
      });
    },
    setActiveGame() {
      this.$store.dispatch("trainingStore/SET_ACTIVE_TRAINING", this.game);
    },
    showTranslationModal(type, entityName, entityId) {
      this.$store.dispatch("translationStore/SHOW_TRANSLATION_MODAL", {
        type,
        entityName,
        entityId,
      });
      this.$emit("setId");
    },
    startDownload() {
      const content = {
        type: "downloadable",
        download_url: this.downloadable,
      };
      this.$store.dispatch("modalStore/SHOW_GAME_DOWNLOAD_MODAL", content);
    },
    setShowMenuItems(val) {
      this.showMenuItems = val;
    },
    toggleOptionMenu(val) {
      this.showOptionMenu = val;
    },
  },
};
</script>

<style></style>
