<template>
  <tr
    class="hover:bg-rowHover focus:bg-gray-100 cursor-pointer"
    @click.stop="$emit('details')"
  >
    <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-200">
      {{ firstname }}
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-200">
      {{ lastname }}
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-200">
      {{ email }}
    </td>
    <td
      class="px-6 py-4 whitespace-nowrap text-right text-sm leading-5 font-medium"
    >
      <Button
        icon="trash"
        size="m"
        hover="red"
        button-type="trashcan"
        @clickAction="$emit('delete')"
      />
    </td>
  </tr>
</template>

<script>
import Button from "@/components/form/Button";

export default {
  name: "TableRow",
  components: {
    Button,
  },
  props: {
    firstname: {
      type: String,
      default: "",
    },
    lastname: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
    id: {
      type: [String, Number],
      default: "",
    },
  },
  methods: {},
};
</script>

<style></style>
