<template>
  <div class="flex items-center flex-wrap sm:flex-nowrap" :class="[justify]">
    <SearchBar @search="searching" />
    <div v-if="buttons.length">
      <Button
        v-for="(button, index) in buttons"
        :key="`buttons-${index}`"
        :class="{ 'ml-3': index === 1 }"
        :button-type="index === 1 ? 'lightSecondary' : 'primary'"
        radius="full"
        :title="$t(button.title)"
        @clickAction="$emit(button.action)"
      />
    </div>
  </div>
</template>

<script>
import SearchBar from "@/components/helpers/SearchBar";
import Button from "@/components/form/Button";
export default {
  name: "Listheader",
  components: { SearchBar, Button },
  props: {
    buttons: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    justify: function () {
      if (this.buttons) {
        return "justify-between";
      } else if (!this.buttons) {
        return "justify-start";
      } else if (this.buttons) {
        return "justify-end";
      }
      return "";
    },
  },
  methods: {
    searching(s) {
      this.$emit("searchAction", s);
    },
  },
};
</script>

<style></style>
