import { computed } from "vue";
import { useField } from "vee-validate";

export default function (props, emit) {
  const {
    value: inputValue,
    errorMessage,
    handleChange,
    meta,
    resetField,
  } = useField(props.title, props.rules, {
    initialValue: props.modelValue,
  });

  const validationListeners = computed(() => {
    if (!errorMessage.value) {
      return {
        blur: handleChange,
        change: handleChange,
        input: (e) => handleChange(e, false),
      };
    }

    return {
      blur: handleChange,
      change: handleChange,
      input: handleChange,
    };
  });

  function updateField(dirty) {
    if (dirty) {
      emit("updateField");
      resetField({
        value: props.modelValue,
      });
    }
  }

  return {
    validationListeners,
    errorMessage,
    inputValue,
    meta,
    updateField,
  };
}
