<template>
  <div class="flex flex-col">
    <div class="flex flex-row justify-between mb-2">
      <h3 class="block text-base text-normal font-medium text-gray-50">
        {{ $t("editor.search") }}
      </h3>
      <button
        @click="close"
        class="text-gray-50 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150 cursor-pointer hover:bg-gray-300"
      >
        <svg class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>
    <div
      class="mt-1 relative flex items-center"
      @keypress.enter="getResults(searchValue)"
    >
      <input
        type="text"
        ref="searchBox"
        name="search"
        id="search"
        class="shadow-sm text-gray-70 focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 p-2 sm:text-sm border-gray-300 rounded-md"
        placeholder="Search..."
        v-model="searchValue"
        @focusin="disableShortcuts(true)"
        @focusout="disableShortcuts(false)"
      />
    </div>
  </div>
</template>

<script>
import { disableShortcuts } from "@/services/disableShortcutsService";

export default {
  name: "SearchInput",
  props: {},
  data() {
    return {
      searchValue: "",
    };
  },
  computed: {},
  mounted() {
    this.$refs.searchBox.focus();
  },
  methods: {
    getResults(val) {
      this.$emit("getResults", val);
    },
    close() {
      this.$emit("close");
    },
    disableShortcuts(boolean) {
      disableShortcuts(boolean);
    },
  },
};
</script>

<style></style>
