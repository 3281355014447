import Vue from "vue";
import { createI18n } from "vue-i18n";
// import { extend, configure } from "vee-validate";
// import { required, email, min, max } from "vee-validate/dist/rules";
import en from "./locales/en.json";
import nl from "./locales/nl.json";
// import validationMessagesEn from "vee-validate/dist/locale/en";
// import validationMessagesNl from "vee-validate/dist/locale/nl";

const messages = {
  en: {
    ...en,
    // validation: {
    //   messages: {
    //     ...en.validation.messages,
    //     // ...validationMessagesEn.messages,
    //   },
    // },
  },
  nl: {
    ...nl,
    // validation: {
    //   messages: {
    //     ...nl.validation.messages,
    //     // ...validationMessagesNl.messages,
    //   },
    // },
  },
};

const i18n = createI18n({
  locale: localStorage.locale || navigator.language.split("-")[0] || "en",
  allowComposition: true,
  legacy: false,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages,
});

// configure({
//   defaultMessage: (field, values) => {
//     return i18n.global.t(`validation.messages.${values._rule_}`, values);
//   },
// });

// extend("required", required);
// extend("email", email);
// extend("min", min);
// extend("max", max);

export default i18n;
