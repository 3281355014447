<template>
  <ModalContainer @cancel="close">
    <div
      class="flex flex-1 bg-modalBackground absolute top-0 left-0 h-full justify-center items-center w-full"
    >
      <div
        class="bg-white flex flex-col overflow-hidden shadow rounded-lg w-4/5 z-50"
      >
        <div class="border-b border-gray-200 px-4 py-5 sm:px-6">
          <div
            class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap"
          >
            <div class="ml-4 mt-2">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                {{ $t("editor.action_property_translations") }}
              </h3>
            </div>
          </div>
        </div>

        <div class="px-2 py-1 sm:px-6">
          <div class="mt-2">
            <h3 class="text-lg leading-6 font-medium text-gray-700">
              {{ `Action: ${action.name}` }}
            </h3>
          </div>
        </div>

        <div v-if="!loading" class="flex flex-row py-2" style="height: 500px">
          <div class="flex flex-1 flex-col mx-4 pr-3 mr-2">
            <span class="text-md leading-6 font-medium text-gray-700 pl-2">{{
              $t("editor.properties")
            }}</span>
            <div
              class="scrollable flex flex-col p-2 my-2 h-full border border-gray-200 rounded-md"
            >
              <ul class="w-full">
                <template
                  v-for="(property, index) in combinedLocaleActionComplete[0]
                    .action.action_properties.data"
                >
                  <li
                    v-if="isPropertyVisible(property)"
                    :key="property.action_property_id"
                    class="px-2 py-1 my-1 cursor-pointer rounded-md hover:bg-blue-500 hover:text-gray-50"
                    :class="
                      selectedPropertyIndex === index
                        ? 'bg-blue-500 text-gray-50'
                        : 'text-gray-700'
                    "
                    @click="setSelectedPropertyIndex(index)"
                  >
                    {{ property.name }}
                  </li>
                </template>
              </ul>
            </div>
          </div>

          <div class="flex flex-3 flex-col mx-2">
            <span class="text-md leading-6 mx-2 font-medium text-gray-700">{{
              $t("editor.content")
            }}</span>
            <div
              class="scrollable flex flex-col p-2 my-2 h-full border border-gray-200 rounded-md"
            >
              <div
                v-for="(locale, index) in combinedLocaleActionComplete"
                :key="`combinedLocaleAction-${index}`"
                class="w-full"
              >
                <div
                  v-if="
                    ['string', 'tts'].includes(
                      locale.action.action_properties.data[
                        selectedPropertyIndex
                      ]?.value_type,
                    )
                  "
                >
                  <InputField
                    class="w-full"
                    :title="
                      locale.tag === 'en_US'
                        ? `${locale.description} [Default]`
                        : `${locale.description}`
                    "
                    :required-input="false"
                    :blur="true"
                    type="text"
                    placeholder=""
                    :light="false"
                    v-model="
                      locale.action.action_properties.data[
                        selectedPropertyIndex
                      ].value
                    "
                    :loading-status="
                      inProgress.actionPropertyId ===
                        locale.action.action_properties.data[
                          selectedPropertyIndex
                        ].action_property_id && inProgress.locale === locale.tag
                        ? inProgress.status
                        : null
                    "
                    @updateField="saveActionTranslation(index, locale.tag)"
                  />
                  <audio
                    controls
                    v-if="
                      locale.action.action_properties.data[
                        selectedPropertyIndex
                      ].media_type === 'audio' &&
                      locale.action.action_properties.data[
                        selectedPropertyIndex
                      ]?.media?.data.uri
                    "
                    class="mb-3"
                  >
                    <source
                      :src="
                        locale.action.action_properties.data[
                          selectedPropertyIndex
                        ].media.data.uri
                      "
                      type="audio/mp3"
                    />
                    Your browser does not support the audio element.
                  </audio>
                </div>
                <div v-else>Not supported for translation yet..</div>
                <!--              <div-->
                <!--                v-if="-->
                <!--                  combinedLocaleAction[index].action.action_properties.data[-->
                <!--                    selectedPropertyIndex-->
                <!--                  ].value_type === 'String[]'-->
                <!--                "-->
                <!--              >-->
                <!--                <div-->
                <!--                  v-for="(-->
                <!--                    translation, indexStringArray-->
                <!--                  ) in combinedLocaleAction[index].action.action_properties-->
                <!--                    .data[selectedPropertyIndex].value"-->
                <!--                  :key="`combinedLocaleAction-${indexStringArray}`"-->
                <!--                  class="mb-4"-->
                <!--                >-->
                <!--                  <InputField-->
                <!--                    class="w-full -mt-2"-->
                <!--                    :title="-->
                <!--                      locale.tag === 'en_US' && indexStringArray === 0-->
                <!--                        ? `${locale.description} [Default]`-->
                <!--                        : indexStringArray !== 0-->
                <!--                        ? ''-->
                <!--                        : `${locale.description}`-->
                <!--                    "-->
                <!--                    :required-input="false"-->
                <!--                    :blur="true"-->
                <!--                    type="text"-->
                <!--                    placeholder=""-->
                <!--                    :light="false"-->
                <!--                    :error="false"-->
                <!--                    v-model="-->
                <!--                      combinedLocaleAction[index].action.action_properties.data[-->
                <!--                        selectedPropertyIndex-->
                <!--                      ].value[indexStringArray]-->
                <!--                    "-->
                <!--                    :loading-status="-->
                <!--                      inProgress.actionPropertyId ===-->
                <!--                        combinedLocaleAction[index].action.action_properties-->
                <!--                          .data[selectedPropertyIndex].action_property_id &&-->
                <!--                      inProgress.locale === locale.tag-->
                <!--                        ? inProgress.status-->
                <!--                        : null-->
                <!--                    "-->
                <!--                    @updateField="saveActionTranslation(index, locale.tag)"-->
                <!--                  />-->
                <!--                </div>-->
                <!--              </div>-->
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="flex flex-row px-3 py-4 justify-center items-center"
          style="height: 500px"
        >
          <Loader />
        </div>
        <div class="border-t border-gray-200 px-4 py-4 sm:px-6">
          <div class="flex-shrink-0 flex justify-end">
            <Button
              @clickAction="close"
              :title="$t('general.close')"
              type="button"
              button-type="secondary"
            >
            </Button>
          </div>
        </div>
      </div>
    </div>
  </ModalContainer>
</template>

<script>
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const actionRepository = RepositoryFactory.get("action");
import Button from "@/components/form/Button";
import { mapState } from "vuex";
import Loader from "@/views/editor/components/Loader";
import InputField from "@/components/form/InputField";
import { showNotification } from "@/services/notificationService";
import { disableShortcuts } from "@/services/disableShortcutsService";
import ModalContainer from "@/components/helpers/ModalContainer.vue";
import File from "@/views/editor/components/ActionProperties/Video.vue";

export default {
  name: "ActionPropertiesTranslationModal",
  components: {
    ModalContainer,
    InputField,
    Button,
    Loader,
  },
  props: {
    stepId: {
      type: String,
      required: false,
      default: "f",
    },
    action: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      loading: true,
      combinedLocaleAction: [],
      combinedLocaleActionComplete: [],
      selectedPropertyIndex: 0,
      inProgress: {
        actionPropertyId: "",
        locale: "",
        status: 0,
      },
    };
  },
  computed: {
    ...mapState("editorStore", ["activeAction", "activeLesson"]),
    ...mapState("translationStore", ["locales"]),
  },
  mounted() {
    disableShortcuts(true);
    this.getLocalesInAction();
    this.selectedPropertyIndex = 0;
  },
  methods: {
    isPropertyVisible(property) {
      return property.translatable;
    },

    getLocalesInAction() {
      this.$store.dispatch("translationStore/GET_LOCALES").then(() => {
        this.locales.forEach((locale) => {
          this.$store
            .dispatch("editorStore/GET_ACTION", {
              stepId: this.action.step_id,
              id: this.action.action_id,
              locale: locale.tag,
            })
            .then((response) => {
              let action = response;
              action.action_properties.data =
                action.action_properties.data.filter((actionProperty) => {
                  return actionProperty.translatable;
                });

              this.combinedLocaleAction = [
                ...this.combinedLocaleAction,
                {
                  tag: locale.tag,
                  description: locale.description,
                  action: action,
                },
              ];
              // sort alphabetically with default locale on top
              this.combinedLocaleAction.sort((a, b) => {
                return (
                  (b.tag === "en_US") - (a.tag === "en_US") ||
                  +(a.tag < b.tag) ||
                  -(a.tag > b.tag)
                );
              });

              if (this.combinedLocaleAction.length === this.locales.length) {
                this.combinedLocaleActionComplete = this.combinedLocaleAction;
                this.loading = false;
              }
            });
        });
      });
    },

    saveActionTranslation(localeIndex, locale) {
      const actionProperty =
        this.combinedLocaleActionComplete[localeIndex].action.action_properties
          .data[this.selectedPropertyIndex];

      const action = {
        action_properties: [
          {
            id: actionProperty.action_property_id,
            value: actionProperty.value,
          },
        ],
      };

      this.inProgress.actionPropertyId = actionProperty.action_property_id;
      this.inProgress.status = 1;
      this.inProgress.locale = locale;

      actionRepository
        .updateActionProperties(
          this.activeLesson.id,
          this.activeAction.stepId,
          this.activeAction.id,
          action,
          locale,
        )
        .then(() => {
          this.$store.dispatch("editorStore/SET_ACTIVE_ACTION", {
            id: this.activeAction.id,
            type: this.activeAction.type,
            activeType: "action",
            stepId: this.activeAction.stepId,
          });
        })
        .catch(() => {
          showNotification(
            this.$t("notifications.edit_action"),
            this.$t(
              "notifications.something_went_wrong_while_editing_this_action",
            ),
            "error",
          );
        })
        .finally(() => {
          this.inProgress.status = 2;
          setTimeout(() => (this.inProgress.status = 0), 3000);
        });
    },

    setSelectedPropertyIndex(index) {
      this.selectedPropertyIndex = index;
    },

    close() {
      disableShortcuts(false);
      this.$emit("toggleActionPropertiesTranslationModal");
    },
  },
};
</script>

<style>
.scrollable {
  width: 100%;
  min-height: 100px;
  max-height: 600px;
  max-width: 900px;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
