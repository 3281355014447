import { RepositoryFactory } from "@/repository/RepositoryFactory";
import { errorHandlingAndMessage } from "@/services/ErrorService";
import { mapState } from "vuex";
const stepRepository = RepositoryFactory.get("step");

export default {
  data() {
    return {
      stepsList: [],
    };
  },
  computed: {
    ...mapState("editorStore", ["mainSteps"]),
  },
  methods: {
    changed(event) {
      const newIndex = event.newDraggableIndex;
      const oldIndex = event.oldDraggableIndex;
      const oldStepId = event.from.id;
      const newStepId = event.to.id;
      const sequenceIndex = newIndex > oldIndex ? newIndex - 1 : newIndex + 1;
      if (oldStepId !== newStepId) {
        // dragging nonLinears between linears.
        // find new target step
        const newStep = this.mainSteps.filter((step) => {
          return step.linear.id === newStepId;
        })[0];
        const oldStep = this.mainSteps.filter((step) => {
          return step.linear.id === oldStepId;
        })[0];
        const stepId = newStep.children[newIndex].id;
        let sort;
        if (newIndex === 0) {
          // if first or only nonLinear, take the parent sequence + 1
          sort =
            newStep.children[newIndex].sequence < newStep.linear.sequence
              ? newStep.linear.sequence
              : newStep.linear.sequence + 1;
        } else {
          sort =
            newStep.children[newIndex - 1].sequence < oldStep.linear.sequence
              ? newStep.children[newIndex - 1].sequence + 1
              : newStep.children[newIndex - 1].sequence;
        }
        const step = {
          sort: sort,
        };
        this.editStep(this.activeLesson.id, step, stepId);
      } else if (newIndex !== oldIndex && oldStepId === newStepId) {
        // dragging linears or dragging nonLinears within the same linear
        const stepId =
          this.stepsList[newIndex].linear?.id ?? this.stepsList[newIndex].id;

        const step = {
          sort:
            this.stepsList[sequenceIndex].linear?.sequence ??
            this.stepsList[sequenceIndex].sequence,
        };
        this.editStep(this.activeLesson.id, step, stepId);
      }
    },

    editStep(activeLessonId, step, stepId) {
      stepRepository
        .editStep(this.activeLesson.id, step, stepId)
        .then((result) => {
          this.$store.dispatch("editorStore/GET_CHAPTER_TREE");
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t("notifications.something_went_while_sorting"),
          );
        });
    },
  },
};
