import Repository from "./Repository";

const resource = "/api/v3/lessons";

export default {
  getSteps(lessonId) {
    return Repository.get(
      `${resource}/${lessonId}/steps?include=actions.unity_package_action.unity_package_action_type`,
    );
  },

  getStepById(lessonId, stepId) {
    return Repository.get(
      `${resource}/${lessonId}/steps/${stepId}?include=actions`,
    );
  },

  newStep(lessonId, step) {
    return Repository.post(`${resource}/${lessonId}/steps`, {
      step,
    });
  },

  editStep(lessonId, step, stepId) {
    return Repository.patch(`${resource}/${lessonId}/steps/${stepId}`, {
      step,
    });
  },

  deleteStep(lessonId, stepId) {
    return Repository.delete(`${resource}/${lessonId}/steps/${stepId}`);
  },

  exportStep(lessonId, stepId) {
    return Repository.get(`${resource}/${lessonId}/steps/${stepId}/export`);
  },

  duplicateStep(lessonId, stepId, sort) {
    return Repository.post(
      `${resource}/${lessonId}/steps/${stepId}/duplicate?include=actions`,
      {
        sort: sort,
      },
    );
  },
};
