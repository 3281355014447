import axios from "axios";
import { errorHandlingAndMessage } from "@/services/ErrorService";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const uploadRepository = RepositoryFactory.get("upload");

export default {
  methods: {
    uploadFile(file, required) {
      if (file?.length) {
        this.$emit("addLoading", this.unityPackageActionPropertyId);
        this.uploadError = false;
        this.fileSrc = null;
        this.fileLoading = true;
        uploadRepository
          .createSignedUrl(file[0]["name"])
          .then(async (result) => {
            await axios
              .put(result.data.data.url, file[0])
              .then(() => {
                if (required) {
                  this.$refs.provider.validate(file[0]);
                }
                this.fileUrl = result.data.data.url.split("?")[0];
                this.setFileInfo(file);
                this.$emit("update:modelValue", this.fileUrl);
              })
              .catch((err) => {
                this.uploadError = true;
                errorHandlingAndMessage(
                  err,
                  this.$t(
                    "notifications.something_went_wrong_uploading_this_file",
                  ),
                );
              })
              .finally(() => {
                this.fileLoading = false;
                this.$emit("removeLoading", this.unityPackageActionPropertyId);
              });
          })
          .catch((err) => {
            errorHandlingAndMessage(
              err,
              this.$t("notifications.something_went_wrong_uploading_this_file"),
            );
          });
      }
    },
    setButtonStatus() {
      if (this.fileLoading) {
        return this.$t("button.uploading");
      } else {
        return this.$t("button.upload");
      }
    },
  },
};
