<template>
  <ModalContainer @cancel="$emit('cancel')">
    <div
      v-click-outside="clickOutside"
      class="bg-gray-50 rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
    >
      <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div
            class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
          >
            <svg
              class="h-6 w-6 text-red-600"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              {{ title }}
            </h3>
            <div class="mt-2">
              <p class="text-sm leading-5 text-gray-500">
                {{ body }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <div class="flex justify-end">
          <Button
            button-type="primary"
            :loading="loading"
            :title="$t('game.delete')"
            @clickAction="deleteGame"
          />
          <Button
            class="ml-3"
            button-type="secondary"
            :title="$t('game.cancel')"
            @clickAction="$emit('cancel')"
          />
        </div>
      </div>
    </div>
  </ModalContainer>
</template>

<script>
import { mapState } from "vuex";
import ModalContainer from "../ModalContainer";
import Button from "../../form/Button";
import { showNotification } from "@/services/notificationService";
import { errorHandlingAndMessage } from "@/services/ErrorService";

export default {
  name: "DeleteModal",
  components: { ModalContainer, Button },
  props: {
    title: {
      type: String,
      default: "",
    },
    body: {
      type: String,
      default: "",
    },
  },
  emits: ["cancel"],
  data: () => {
    return {
      test: "test",
    };
  },
  computed: {
    ...mapState("trainingStore", ["deleteId", "loading"]),
  },
  methods: {
    clickOutside() {
      this.$emit("cancel");
    },
    deleteGame() {
      this.$store
        .dispatch("trainingStore/DELETE_TRAINING", this.deleteId)
        .then(() => {
          showNotification(
            this.$t("notifications.game_deleted"),
            this.$t("notifications.game_is_successfully_deleted"),
            "success",
          );
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t("notifications.something_went_wrong_deleting_the_game"),
          );
        });
    },
  },
};
</script>

<style></style>
