import { nextTick } from "vue";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("searchStore", [
      "searchTarget",
      "searchKind",
      "searchTargetId",
      "searchStepId",
    ]),
  },
  watch: {
    searchTargetId: {
      handler(id) {
        if (
          this.stepId === this.searchStepId &&
          this.searchTarget === "action" &&
          (this.searchKind === "setup" || this.searchKind === "nonLinear") &&
          (this.$options.name === "NonLinearStep" ||
            this.$options.name === "LinearStep")
        ) {
          this.$store.dispatch("editorStore/SET_ACTIVE_STEP", {
            id: this.searchStepId,
            activeType: "action",
          });
          this.$store.dispatch("editorStore/SET_ACTIVE_ACTION", {
            id: id,
            type: "edit",
            activeType: "action",
            stepId: this.searchStepId,
          });
          this.collapsed = true;
          this.scrollToElement(this.searchStepId);
        } else if (this.searchTarget === "action") {
          nextTick(() => {
            if (this.id === id) {
              this.scrollToElement(id);
              this.$store.dispatch("editorStore/SET_ACTIVE_STEP", {
                id: this.searchStepId,
                activeType: "action",
              });
              this.$store.dispatch("editorStore/SET_ACTIVE_ACTION", {
                id: id,
                type: "edit",
                activeType: "action",
                stepId: this.searchStepId,
              });
            }
          });
        } else if (
          this.searchTarget === "nonLinear" ||
          this.searchTarget === "linear"
        ) {
          nextTick(() => {
            if (this.id === id) {
              if (this.searchTarget === "nonLinear") {
                this.collapsed = true;
              }
              this.scrollToElement(id);
              this.setActive();
            }
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    scrollToElement(id) {
      if (this.$refs[id]) {
        nextTick(() => {
          this.$refs[id].scrollIntoView({
            inline: "center",
            block: "end",
          });
        });
      }
    },
  },
};
