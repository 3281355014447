<template>
  <div class="flex flex-col justify-between px-4 sm:p-6 overflow-hidden">
    <MediaSearch class="mb-6" @search="searchAction" />
    <div class="flex flex-row overflow-hidden">
      <div class="overflow-auto" :class="showDetails ? 'w-2/3' : 'w-full'">
        <div
          v-if="loading"
          class="flex flex-row px-3 py-4 justify-center"
          style="height: 500px"
        >
          <Loader />
        </div>
        <div v-else class="flex flex-col shadow bg-white overflow-auto">
          <div>
            <div v-if="layout === 'list'" class="w-full h-full">
              <ul v-if="mediaItems.length" class="h-full overflow-y-auto">
                <MediaItem
                  v-for="(item, index) in mediaItems"
                  :key="item.id"
                  :media-type="mediaModalType"
                  :media-item="item"
                  :index="index"
                  :selected="selectedMedia ? selectedMedia.id : null"
                  @showMedia="showMedia"
                />
              </ul>
              <div v-else class="flex text-gray-500 justify-center mt-10">
                {{ `${$t("media.no_media_yet")}...` }}
              </div>
            </div>

            <div v-if="layout === 'grid'" class="w-full h-full mb-6">
              <ul
                v-if="mediaItems.length"
                class="grid grid-cols-1 gap-2 p-2"
                :class="
                  showDetails
                    ? 'sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3'
                    : 'sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'
                "
              >
                <MediaTile
                  v-for="(item, index) in mediaItems"
                  :key="item.id"
                  :media-type="mediaModalType"
                  :media-item="item"
                  :index="index"
                  :selected="selectedMedia ? selectedMedia.id : null"
                  @showMedia="showMedia"
                />
              </ul>
              <div v-else class="flex text-gray-500 justify-center mt-10">
                {{ `${$t("media.no_media_yet")}...` }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showDetails" class="flex w-1/3 ml-5 bg-gray-50 shadow p-2">
        <MediaViewer
          v-if="!loadMedia"
          :selected-media="selectedMedia"
          :media-type="mediaModalType"
          @selectFile="selectFile"
          @close="showDetails = false"
        />
      </div>
    </div>
    <div class="flex flex-row mt-6">
      <div
        class="flex bg-gray-100 items-center justify-between relative rounded-full w-32"
      >
        <select
          class="w-full appearance-none h-10 text-gray-500 px-4 rounded-full font-medium leading-tight mr-3"
          id="layout-state"
          v-model="layout"
          @change="toggleLayout"
        >
          <option value="list" selected>
            {{ $t("pagination.list") }}
          </option>
          <option value="grid">
            {{ $t("pagination.grid") }}
          </option>
        </select>
        <div
          class="pointer-events-none items-center mr-5 absolute right-0 text-darkAlineaColor"
        >
          <font-awesome-icon icon="chevron-down" class="fa-sm text-gray-500" />
        </div>
      </div>
      <Pagination
        :total-pages="totalPages"
        :current-page="currentPage"
        :max-result="maxResult"
        :total-items="totalItems"
        media-layout
        @next="nextPage"
        @previous="previousPage"
        @toPage="toPage"
        @changeAmountToShow="changeMediaToShow"
      />
    </div>
  </div>
</template>

<script>
import MediaSearch from "@/views/editor/components/modals/mediaModal/components/MediaSearch";
import MediaItem from "@/views/editor/components/modals/mediaModal/components/MediaItem";
import MediaTile from "@/views/editor/components/modals/mediaModal/components/MediaTile";
import Pagination from "@/components/helpers/Pagination";
import MediaViewer from "@/views/editor/components/modals/mediaModal/components/MediaViewer";
import Loader from "@/views/editor/components/Loader";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import { errorHandlingAndMessage } from "@/services/ErrorService";
const mediaRepository = RepositoryFactory.get("media");

export default {
  name: "SelectMedia",
  components: {
    MediaItem,
    MediaSearch,
    Pagination,
    MediaViewer,
    MediaTile,
    Loader,
  },
  props: {
    mediaModalType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      activeMediaType: "Image",
      options: ["list", "grid"],
      amount: 6,
      mediaItems: [],
      debounce: null,
      filteredMediaItems: [],
      showMediaViewerModal: false,
      selectedMedia: null,
      maxResult: 25,
      currentPage: 1,
      totalPages: 1,
      totalItems: 0,
      layout: "list",
      showDetails: false,
      loadMedia: true,
      search: "",
      params: {
        page: "1",
        sort: "ASC",
        sortfield: "name",
        maxResult: 25,
        query: "",
        category: "action_property",
        type: this.mediaModalType,
      },
    };
  },
  watch: {
    search: {
      handler(val) {
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          this.params.page = 1;
          this.currentPage = 1;
          this.getMedia();
        }, 600);
      },
      deep: true,
    },
  },
  mounted() {
    this.getMedia();
  },
  methods: {
    selectFile() {
      this.$emit("selectFile", this.selectedMedia);
    },
    close() {
      this.$emit("toggleMediaModal");
    },
    getMedia() {
      mediaRepository
        .getMedia(this.params)
        .then((result) => {
          this.mediaItems = result.data.data;
          const { current_page, total, total_pages } =
            result.data.meta.pagination;

          this.totalPages = total_pages;
          this.currentPage = current_page;
          this.totalItems = total;

          this.loading = false;
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t("notifications.something_went_wrong_fetching_media"),
          );
        });
    },
    showMedia(media) {
      this.loadMedia = true;
      setTimeout(() => {
        this.selectedMedia = media;
        this.showDetails = true;
        this.loadMedia = false;
      }, 50);
    },
    nextPage() {
      if (!(parseInt(this.params.page) >= this.totalPages)) {
        this.loading = true;
        this.params.page = (parseInt(this.params.page) + 1).toString();
        this.currentPage = parseInt(this.params.page);
        this.getMedia();
      }
    },
    previousPage() {
      if (this.params.page && this.params.page >= 2) {
        this.loading = true;
        this.params.page = (parseInt(this.params.page) - 1).toString();
        this.currentPage = parseInt(this.params.page);
        this.getMedia();
      }
    },
    toPage(p) {
      this.loading = true;
      this.params.page = p.toString();
      this.currentPage = p;
      this.getMedia();
    },
    sort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "ASC" ? "DESC" : "ASC";
        this.params.sort = this.currentSortDir;
      }
      this.currentSort = s;
      this.params.sortfield = this.currentSort;

      this.getMedia();
    },
    changeMediaToShow(val) {
      this.params.maxResult = val.toString();
      this.params.page = 1;
      this.getMedia();
    },
    searchAction(s) {
      this.search = s;
      this.params.query = s;
    },

    toggleLayout() {
      this.params.page = 1;
    },
  },
};
</script>

<style></style>
