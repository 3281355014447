<template>
  <ModalContainer>
    <div
      class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
    >
      <div class="bg-white shadow sm:rounded-lg">
        <div class="px-4 py-5 sm:p-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            {{ headerText }}
          </h3>
          <div class="mt-2 max-w-xl text-sm leading-5 text-gray-500">
            <p>{{ subHeaderText }}</p>
          </div>
          <div class="mt-2 max-w-xl text-sm leading-5 text-gray-500"></div>
          <div class="relative pt-1">
            <div class="flex mb-2 items-center justify-between">
              <div>
                <span
                  class="text-xs font-semibold inline-block py-1 uppercase rounded-full text-gray-900"
                >
                  {{ progress === 100 ? "Finalizing..." : `${progress}%` }}
                </span>
              </div>
              <div class="text-right">
                <span class="text-xs font-semibold inline-block text-gray-900">
                  {{ `${formatBytes(done)} / ${formatBytes(total)}` }}
                </span>
              </div>
            </div>
            <div
              class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-indigo-200"
            >
              <div
                :style="{ width: `${progress}%` }"
                class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-linearBlue"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ModalContainer>
</template>

<script>
import ModalContainer from "../ModalContainer";
import formatBytes from "@/mixins/formatBytes";

export default {
  name: "UploadMessageModal",
  components: { ModalContainer },
  mixins: [formatBytes],
  props: {
    headerText: {
      type: String,
      default: "",
    },
    subHeaderText: {
      type: String,
      default: "",
    },
    cancelButtonText: {
      type: String,
      default: "",
    },
    progress: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
    done: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style></style>
