<template>
  <PageContentContainer>
    <div
      v-if="loading"
      class="flex flex-col flex-1 height justify-center items-center"
    >
      <Loader />
    </div>

    <div v-else>
      <div v-if="versionId">
        <div class="mb-6">
          <div class="flex flex-row items-center">
            <Button
              button-type="editor-primary"
              :title="$t('editor.sort_chapters')"
              @clickAction="showSortModal = true"
            />
          </div>
        </div>
        <Chapter
          v-for="(chapter, index) in chapters"
          :key="chapter.id"
          :index="index"
          :chapter="chapter"
        />
        <div
          @click="addChapter()"
          class="flex justify-left items-center z-10 mt-5 mb-12 cursor-pointer"
        >
          <font-awesome-icon icon="plus" class="text-white text-18" />
          <b class="ml-3 text-white text-16">{{ $t("editor.add_chapter") }}</b>
        </div>
        <AddLevel v-if="showLevelModal === 'addLevel'" @cancel="cancel()" />
        <EditLevel v-if="showLevelModal === 'editLevel'" @cancel="cancel()" />
        <AddChapter v-if="showLevelModal === 'addChapter'" @cancel="cancel()" />
        <EditChapter
          v-if="showLevelModal === 'editChapter'"
          @cancel="cancel()"
        />
        <AddSubChapter
          v-if="showLevelModal === 'addSubChapter'"
          @cancel="cancel()"
        />
        <EditSubChapter
          v-if="showLevelModal === 'editSubChapter'"
          @cancel="cancel()"
        />
        <Sort v-if="showSortModal" @close="showSortModal = false" />
      </div>
      <div v-else class="mb-6 text-white">
        <font-awesome-icon
          icon="exclamation-triangle"
          class="text-yellow-300 text-18"
        />
        {{ $t("editor.no_game_notice") }}
      </div>
    </div>
  </PageContentContainer>
</template>

<script>
import { mapState } from "vuex";
import Chapter from "@/views/editor/components/Chapter";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const trainingRepository = RepositoryFactory.get("training");
import EditChapter from "./components/modals/chapter/Edit";
import AddChapter from "./components/modals/chapter/Add";
import EditSubChapter from "./components/modals/subChapter/Edit";
import AddSubChapter from "./components/modals/subChapter/Add";
import AddLevel from "./components/modals/level/Add";
import EditLevel from "./components/modals/level/Edit";
import Button from "@/components/form/Button";
import PageContentContainer from "@/components/helpers/PageContentContainer";
import Loader from "@/views/editor/components/Loader";
import Sort from "@/views/editor/components/modals/chapter/Sort";

export default {
  name: "Levels",

  components: {
    Chapter,
    AddChapter,
    EditChapter,
    EditSubChapter,
    AddSubChapter,
    Button,
    PageContentContainer,
    AddLevel,
    EditLevel,
    Loader,
    Sort,
  },

  data() {
    return {
      showSortModal: false,
    };
  },

  computed: {
    ...mapState("trainingStore", [
      "versionId",
      "subchapter",
      "chapters",
      "showLevelModal",
      "loading",
    ]),
  },

  mounted() {
    // Refresh the chapters/levels so when coming back from a level that was fixed the error state is correct
    if (this.versionId) {
      this.$store.dispatch("trainingStore/GET_CHAPTERS");
    }
  },

  methods: {
    addChapter() {
      this.$store.dispatch("trainingStore/SHOW_LEVEL_MODAL", "addChapter");
    },

    cancel() {
      this.$store.dispatch("trainingStore/SHOW_LEVEL_MODAL", "none");
    },
    emitter(value) {
      this.$emit("update:modelValue", value);
    },
  },
};
</script>
