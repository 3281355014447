<template>
  <div class="w-full">
    <div class="flex justify-between">
      <label
        for="input"
        class="block text-sm font-medium leading-5 text-gray-200"
      >
        {{ title }}
        <span
          v-if="requiredInput"
          class="text-gray-200"
          v-tooltip="{
            content: $t('game.required'),
            boundary: 'window',
          }"
        >
          {{ "*" }}
        </span>
      </label>
      <div class="flex">
        <div
          v-if="hint"
          class="flex justify-center bg-white items-center h-3 w-3 rounded-full mt-1 ml-3"
          v-tooltip="{ content: hint, boundary: 'window' }"
        >
          <p class="text-sidebar font-bold text-9">?</p>
        </div>
        <ErrorPopup
          v-if="errorState !== 0"
          :title="$t('editor.this_action_property_contains_an_error')"
          :message="errorMessage"
          icon-size="s"
          class="flex items-center justify-center mr-1 ml-1"
        />
      </div>
    </div>
    <div class="relative rounded-md shadow-sm">
      <div
        v-for="(val, index) in newValue"
        :key="`answerarray-${title}-${index}`"
        class="flex justify-between w-full mt-2 -mb-3"
      >
        <InputField
          class="w-full"
          :title="`${title} ${index + 1}`"
          :active-color="newValue[index].isCorrect ? '#1c64f2' : activeColor"
          v-model="newValue[index].answerString"
          rules="required|min:1|max:255"
        />

        <div class="flex flex-col justify-between items-end ml-4">
          <CheckBox class="mt-5" v-model="newValue[index].isCorrect"></CheckBox>
          <font-awesome-icon
            v-if="newValue.length > 1"
            icon="trash"
            class="text-red-500 text-16 rotate mt-1 mr-3 mb-6"
            @click="deleteItem(index)"
          />
        </div>
      </div>
      <button
        @click.prevent="addItem"
        class="flex justify-center items-center mt-5"
      >
        <span class="text-14 text-white mt-px mr-2">
          {{ $t("editor.add_item") }}
        </span>
        <font-awesome-icon icon="plus" class="text-white text-14 rotate" />
      </button>
      <div class="h-6 mt-px"></div>
    </div>
  </div>
</template>

<script>
import InputField from "@/views/editor/components/inputs/InputField";
import CheckBox from "@/views/editor/components/inputs/CheckBox";
import ErrorPopup from "@/views/editor/components/modals/ErrorPopup";

export default {
  components: {
    InputField,
    CheckBox,
    ErrorPopup,
  },
  props: {
    title: {
      type: String,
      default: "",
      required: true,
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    defaultValue: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    activeColor: {
      type: String,
      default: "#6b7280",
    },
    hint: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "",
    },
    requiredInput: {
      type: Boolean,
      default: false,
    },
    errorState: {
      type: Number,
      default: 0,
    },
    errorMessage: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      newValue: [
        {
          answerString: "Item",
          isCorrect: false,
        },
      ],
    };
  },
  watch: {
    modelValue: {
      handler() {
        this.updateValue();
      },
      deep: true,
    },
  },
  mounted() {
    if (this.modelValue) {
      this.newValue = this.modelValue;
    } else {
    }
  },
  methods: {
    addItem() {
      this.newValue.push({
        answerString: "Item",
        isCorrect: false,
      });
    },
    deleteItem(index) {
      this.newValue.splice(index, 1);
    },
    updateValue() {
      this.$emit("update:modelValue", this.newValue);
    },
  },
};
</script>

<style></style>
