<template>
  <div
    v-click-outside="onClickOutside"
    class="flex w-full items-center cursor-pointer"
    :class="positionButton"
  >
    <VDropdown :triggers="['click']">
      <button
        class="flex items-center text-white hover:text-white focus:outline-none focus:text-white"
        :class="color"
        aria-label="Options"
        id="options-menu"
      >
        <font-awesome-icon
          :icon="icon"
          class="mb-1"
          :class="[
            active ? 'text-blue-500' : color,
            hover ? 'hover:text-blue-500' : '',
          ]"
        />
        <span v-if="text" class="ml-1">
          {{ text }}
        </span>
      </button>
      <template #popper>
        <div class="rounded-md w-full h-full ring-1 ring-black ring-opacity-5">
          <a v-close-popper>
            <div
              v-for="(option, index) in options"
              :key="option.option"
              class="w-full text-left block px-4 py-3 text-sm leading-5 cursor-pointer hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
              :class="
                ({
                  'border-b border-gray-300': separationLine === index + 1,
                },
                option.disabled ? 'text-gray-400' : 'text-gray-700')
              "
              role="menuitem"
              @click="
                option.disabled ? '' : $emit(`${option.option}`);
                active = !active;
              "
            >
              {{ $t(`optionsmenu.${option.option}`) }}
            </div>
          </a>
        </div>
      </template>
    </VDropdown>
  </div>
</template>

<script>
export default {
  name: "OptionMenu",
  // directives: {
  //   clickOutside: vClickOutside.directive,
  // },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    icon: {
      type: String,
      default: "ellipsis-v",
    },
    position: {
      type: String,
      default: "right-0",
    },
    iconStyle: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "text-gray-400",
    },
    separationLine: {
      type: Number,
      default: null,
    },
    hover: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  computed: {
    setIcon: function () {
      if (this.icon === "plus") {
        return "text-white text-15 cursor-pointer}";
      } else return "";
    },
    setIconStyle: function () {
      if (this.iconStyle === "m") {
        return "text-white text-15 cursor-pointer}";
      } else return "";
    },
    positionButton: function () {
      if (this.position === "left") {
        return "justify-start";
      } else if (this.position === "center") {
        return "justify-center";
      } else return "justify-end";
    },
  },
  methods: {
    onClickOutside(event) {
      if (this.active) {
        this.active = false;
      }
      this.showOptionMenu(false);
    },
    showOptionMenu(val) {
      this.$emit("showOptionMenu", val);
    },
    setActive() {
      this.active = !this.active;
      this.showOptionMenu(true);
    },
  },
};
</script>

<style>
.option-menu-popover.popover .popover-inner {
  border-radius: 5px;
  padding: 0px;
  overflow: hidden;
  cursor: pointer;
}

.option-menu-popover.popover .tooltip-arrow {
  display: none;
}
</style>
