import Repository from "./Repository";

const resource = "/api/v3/uploads";

export default {
  createSignedUrl(file) {
    return Repository.post(`${resource}`, {
      file_name: file,
    });
  },
};
