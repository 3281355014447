<template>
  <div
    class="mx-10 flex flex-col w-90% backgroundRow mobile:mx-1 border-2 cursor-pointer"
    :class="{ 'border-rapportRed': failed, 'border-rapportGreen': !failed }"
    @click="showDropdown"
  >
    <div class="h-10 flex items-center justify-between px-5">
      <div class="flex items-center">
        <font-awesome-icon
          :icon="failed ? 'times-circle' : 'check-circle'"
          class="fa-lg"
          :style="{ color: failed ? '#ED5565' : '#1AB394' }"
        />
        <p
          class="text-17 ml-5 leading-none"
          :class="{ 'text-rapportRed': failed, 'text-rapportGreen': !failed }"
        >
          {{ title }}
        </p>
      </div>
      <font-awesome-icon
        icon="chevron-down"
        class="fa rotate"
        :class="this.rotate"
        :style="{ color: failed ? '#ED5565' : '#1AB394' }"
      />
    </div>
    <transition name="slide" transition="slide">
      <div v-if="show" class="flex flex-col px-5 pb-5">
        <div class="flex flex-col">
          <div class="flex flex-row mt-10 mb-1">
            <p class="flex-4 text-17 font-medium text-lightAlineaColor">
              {{ $t("results.actions") }}
            </p>
            <p class="flex-1 text-17 font-medium text-lightAlineaColor">
              {{ $t("results.penalty") }}
            </p>
            <p
              class="flex-1 text-17 font-medium text-lightAlineaColor text-right"
            >
              {{ $t("results.result") }}
            </p>
          </div>
          <div
            class="flex flex-row"
            v-for="action in stepActions.data"
            :key="action.actionId"
          >
            <p class="flex-4 text-16 font-light text-lightAlineaColor">
              {{ action.actionName }}
            </p>
            <p class="flex-1 text-16 font-light text-lightAlineaColor">
              {{ action.penalty }}
            </p>
            <p
              class="flex-1 text-16 font-light text-lightAlineaColor text-right"
            >
              <font-awesome-icon
                v-if="!action.failed"
                icon="check"
                class="leading-none text-rapportGreen"
              />
              <font-awesome-icon
                v-else
                icon="times"
                class="leading-none text-rapportRed"
              />
            </p>
          </div>
          <div class="flex flex-row mt-30">
            <p class="flex-4 text-17 font-medium text-lightAlineaColor">
              {{ $t("results.max_score") }}
            </p>
            <p class="flex-1 text-17 font-medium text-lightAlineaColor">
              {{ $t("results.total_penalties") }}
            </p>
            <p
              class="flex-1 text-17 font-medium text-lightAlineaColor text-right"
            >
              {{ $t("results.total_score") }}
            </p>
          </div>
          <div class="flex flex-row">
            <p class="flex-4 text-16 font-light text-lightAlineaColor">
              {{ totalScore }}
            </p>
            <p class="flex-1 text-16 font-light text-lightAlineaColor">
              {{ totalPenalty }}
            </p>
            <p
              class="flex-1 text-16 font-light text-lightAlineaColor text-right"
            >
              {{ totalScore - totalPenalty }}
            </p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "GameResultRow",
  props: {
    failed: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Title",
    },
    stepActions: {
      type: Object,
      default: () => {},
    },
    totalScore: {
      type: Number,
      default: 0,
    },
    totalPenalty: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    show: false,
    rotate: "up",
  }),
  methods: {
    showDropdown() {
      this.show = !this.show;
      if (this.rotate === "down") {
        this.rotate = "up";
      } else {
        this.rotate = "down";
      }
    },
  },
};
</script>

<style></style>
