<template>
  <div class="flex flex-col justify-center items-center">
    <div
      v-if="!image"
      :class="`${size}`"
      class="rounded-full bg-primaryColor flex justify-center items-center"
    >
      <p class="text-alineaColor text-white text-22 mobile:text-18">
        {{ getInitials.toUpperCase() }}
      </p>
    </div>
    <img
      v-if="image"
      :src="image"
      alt="profile image"
      :class="`${size}`"
      class="rounded-full bg-primaryColor flex justify-center items-center object-cover"
    />

    <p
      v-if="allowChange"
      class="mt-4 text-sm text-blue-500 hover:text-blue-400 cursor-pointer"
      @click="showCropper"
    >
      {{ $t("profile.change_picture") }}
    </p>
  </div>
</template>

<script>
export default {
  name: "ProfileImage",
  props: {
    image: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "",
    },
    firstname: {
      type: String,
      default: "",
    },
    lastname: {
      type: String,
      default: "",
    },
    allowChange: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getInitials: function () {
      return `${this.firstname.charAt(0)}${this.lastname.charAt(0)}`;
    },
  },

  methods: {
    showCropper() {
      this.$emit("showCropper");
    },
  },
};
</script>

<style></style>
