<template>
  <div
    class="p-3 mobile:w-4/12"
    :class="{ 'hover:bg-gray-200 cursor-pointer': collapsible }"
    @click="showTrophies"
  >
    <p class="text-40 leading-none font-medium text-gray-100">
      {{ value }}
    </p>
    <p
      v-if="!collapsible"
      class="text-17 leading-none font-light text-gray-300 pt-1"
    >
      {{ title }}
    </p>
    <div
      v-else-if="collapsible"
      class="flex flex-row justify-between items-center pt-1"
    >
      <p class="text-17 leading-none font-light text-lightAlineaColor">
        {{ title }}
      </p>
      <font-awesome-icon
        icon="chevron-down"
        class="h-14 leading-none rotate"
        :class="this.rotate"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    value: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: "Title",
    },
    collapsible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rotate: "down",
    };
  },
  methods: {
    isMobile() {
      return screen.width <= 820;
    },
    showTrophies() {
      this.$emit("showcollapsible");
      if (this.rotate === "down") {
        this.rotate = "up";
      } else {
        this.rotate = "down";
      }
    },
  },
};
</script>

<style></style>
