<template>
  <div
    class="bg-lightAlineaColor flex justify-center items-center cursor-pointer"
    :class="{
      'rounded-full w-6': rounded === 'full',
      rounded: rounded === 'corners',
      'hover:opacity-25': hover,
    }"
    @click="clickAction"
  >
    <p
      class="px-badgeLR py-badgeLR text-secondaryColor text-badge font-medium leading-none"
    >
      {{ title }}
    </p>
  </div>
</template>

<script>
export default {
  name: "Badge",
  components: {},
  props: {
    // eslint-disable-next-line vue/require-default-prop
    title: String,
    hover: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: String,
      default: "corners",
      // full / corners
    },
  },
  data: () => ({
    data: "data",
  }),
  methods: {
    clickAction() {
      this.$emit("clickAction");
    },
  },
};
</script>
