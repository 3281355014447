<template>
  <ModalContainer @cancel="close">
    <div
      class="flex flex-col justify-between min-w-250 max-h-10/12 overflow-y-auto bg-gray-100 p-4 rounded-md pb-5"
    >
      <div class="flex justify-between">
        <h1
          class="flex text-xl leading-6 font-medium text-gray-900 justify-center pl-4 pt-4"
        >
          {{ $t("shortcuts.keyboard_shortcuts") }}
        </h1>
        <button
          @click="close"
          class="inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
        >
          <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div class="flex flex-wrap">
        <div
          v-for="(
            keyboardShortcutCategories, keyboardShortcutsIndex
          ) in keyboardShortcuts"
          class="w-full md:w-350 p-4"
          :key="keyboardShortcutsIndex"
        >
          <div class="flex flex-1 flex-col">
            <h3
              class="flex text-md leading-6 font-medium text-gray-900 justify-start mb-2 mt-2"
            >
              {{ $t(`shortcuts.${keyboardShortcutCategories.name}`) }}
            </h3>
            <div
              v-for="(
                keyboardShortcut, keyboardShortcutIndex
              ) in keyboardShortcutCategories.shortcuts"
              :key="keyboardShortcutIndex"
              class="flex flex-row items-center"
            >
              <div class="text-sm font-normal w-1/2 break-words">
                {{ $t(`shortcuts.${keyboardShortcut.name}`) }}
              </div>
              <div
                v-for="(key, index) in keyboardShortcut.check_os &&
                operatingSystem === 'macos'
                  ? keyboardShortcut.shortcut_mac
                  : keyboardShortcut.shortcut"
                :key="`${key}-${index}`"
                class="flex flex-row items-center mb-2"
              >
                <div class="pl-2 pr-2 pt-1 pb-1 text-14 bg-gray-200 rounded-md">
                  {{ key }}
                </div>
                <span
                  v-if="keyboardShortcut.shortcut.length - 1 !== index"
                  class="ml-1 mr-1 text-14"
                >
                  +
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ModalContainer>
</template>

<script>
import ModalContainer from "@/components/helpers/ModalContainer";
import { keyboardShortcutList } from "@/helpers/keyboardShortcutList";
import closeModal from "@/mixins/closeModal";

export default {
  name: "ShortcutModal",
  components: { ModalContainer },
  mixins: [closeModal],
  props: {
    operatingSystem: {
      type: String,
      default: "windows",
    },
  },
  data() {
    return {
      keyboardShortcuts: keyboardShortcutList,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
