<template>
  <PageContentContainer>
    <div>
      <div class="flex flex-row justify-start mb-30 items-end">
        <div
          class="mobile:my-10 w-auto self-end flex items-center relative rounded-full"
        >
          <select
            class="appearance-none w-full h-10 text-darkAlineaColor px-4 pr-10 bg-standardBgColor rounded-full font-medium leading-tight cursor-pointer"
            v-model="selectedGame"
            @input="gameClick($event.target.value)"
          >
            <option
              v-for="game in trainingsWithVersion"
              :key="game.id"
              :value="game.version"
            >
              {{ game.title }}
            </option>
          </select>
          <div
            class="pointer-events-none items-center text-darkAlineaColor absolute right-2"
          >
            <svg
              class="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>

        <div
          class="mobile:my-10 w-auto ml-3 self-end flex items-center relative rounded-full"
        >
          <select
            class="appearance-none w-full h-10 text-darkAlineaColor px-4 pr-10 bg-standardBgColor rounded-full font-medium leading-tight cursor-pointer"
            v-model="selectedLesson"
            @input="setSelectedLesson($event.target.value)"
          >
            <template v-if="lessonList.length">
              <option
                v-for="lesson in lessonList"
                :key="lesson.id"
                :value="lesson.id"
              >
                {{ truncate(lesson.name) }}
              </option>
            </template>
            <template v-else>
              <option value="" selected disabled>
                {{ $t("highscore.no_levels") }}
              </option>
            </template>
          </select>
          <div
            class="pointer-events-none items-center text-darkAlineaColor absolute right-2"
          >
            <svg
              class="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
      </div>
      <div
        v-if="highscoreList.length"
        class="h-60 flex items-end justify-center mobile:h-60"
      >
        <div
          class="mt-2 flex items-center mobile:px-10 h-50 pr-10 flex-row flex-1 justify-between mobile:h-10"
        >
          <div class="flex-1 flex items-center mobile:hidden">
            <p
              class="text-20 text-lightAlineaColor text-left flex-2 mobile:flex-5 font-medium mobile:text-16"
            >
              {{ $t("highscore.position") }}
            </p>
          </div>

          <div class="flex flex-2 desktop:hidden mobile:flex-2">
            <p
              class="text-20 text-lightAlineaColor text-left flex-2 font-medium mobile:text-16 desktop:hidden"
            >
              #
            </p>
          </div>
          <div class="flex-2 flex items-center mobile:text-16 mobile:flex-5">
            <p
              class="text-20 text-lightAlineaColor text-left flex-2 mobile:flex-5 font-medium mobile:text-16"
            >
              {{ $t("highscore.player") }}
            </p>
          </div>
          <div
            class="h-35 flex flex-col justify-center flex-1 desktop:ml-1 mobile:flex-2"
          >
            <p
              class="text-20 text-lightAlineaColor text-left flex-1 font-medium mobile:flex-2 mobile:text-16"
            >
              {{ $t("highscore.score") }}
            </p>
          </div>
          <div class="h-35 flex flex-col justify-center flex-1 mobile:hidden">
            <p
              class="text-20 text-lightAlineaColor text-left flex-1 font-medium mobile:hidden"
            >
              {{ $t("highscore.time") }}
            </p>
          </div>
          <div class="h-35 flex flex-col justify-center flex-1 mobile:flex-2">
            <p
              class="text-20 text-lightAlineaColor text-left flex-1 font-medium mobile:flex-2 mobile:text-16"
            >
              {{ $t("highscore.date") }}
            </p>
          </div>
        </div>
      </div>

      <transition-group name="fadeScore" tag="span">
        <HighscoreRow
          v-for="item in highscoreList"
          :key="item.firstName"
          :position="item.rank"
          :image="item.image"
          :firstname="item.firstName"
          :lastname="item.surName"
          :score="`${item.finishedPercentage}%`"
          :time="item.time"
          :points="item.score"
          :date="item.start_time"
        />
      </transition-group>

      <transition name="fadeEmpty">
        <ListPlaceholder
          v-if="!highscoreList.length && selectedLesson !== 'select'"
          :text="this.$t('highscore.no_highscores')"
          class="mt-5"
        />
      </transition>
      <transition name="fadeEmpty">
        <ListPlaceholder
          v-if="selectedLesson === 'select'"
          :text="this.$t('highscore.no_level_selected')"
          class="mt-5"
        />
      </transition>
    </div>
  </PageContentContainer>
</template>

<script>
import PageContentContainer from "@/components/helpers/PageContentContainer";
import { mapState } from "vuex";
import HighscoreRow from "@/components/highscore/HighscoreRow";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import ListPlaceholder from "../components/helpers/ListPlaceholder";
import { showNotification } from "@/services/notificationService";
const lessonRepository = RepositoryFactory.get("lesson");

export default {
  name: "Highscores",
  components: {
    HighscoreRow,
    ListPlaceholder,
    PageContentContainer,
  },
  data() {
    return {
      lessonList: {},
      highscoreList: {},
      selectedLesson: "none",
      selectedGame: "",
      gameSelected: false,
      oneGame: false,
      trainingsWithVersion: [],
    };
  },
  computed: {
    ...mapState("trainingStore", [
      "trainings",
      "activeTraining",
      "activeEditorTraining",
    ]),
  },
  watch: {
    selectedLesson: {
      handler(newSelectedLesson, oldSelectedLesson) {
        if (newSelectedLesson === "none") {
          this.highscoreList = {};
        } else {
          this.getHighscore(newSelectedLesson);
        }
      },
      deep: true,
    },
    activeEditorTraining: {
      handler(val) {
        this.getLesson(val.version);
      },
      deep: true,
    },
    trainings: {
      handler(val) {
        this.trainingsWithVersion = val.filter(function (game) {
          return game.version != null;
        });
        this.setSelected();
      },
      deep: true,
    },
  },
  mounted() {
    this.trainingsWithVersion = this.trainings.filter(function (game) {
      return game.version != null;
    });
    this.setSelected();
  },

  methods: {
    setSelected() {
      if (localStorage.getItem("selectedGame")) {
        this.selectedGame = localStorage.getItem("selectedGame");
        this.getLesson(this.selectedGame);
      } else {
        if (this.activeTraining) {
          this.gameSelected = true;
          this.selectedGame = this.activeTraining.version;
          this.getLesson(this.selectedGame);
        }
        if (this.trainingsWithVersion.length) {
          this.gameSelected = true;
          this.selectedGame = this.trainingsWithVersion[0].version;
          this.getLesson(this.trainingsWithVersion[0].version);
        }
      }
    },
    getLesson(versionId) {
      lessonRepository.getLessons(versionId).then((result) => {
        this.lessonList = result.data.data;
        this.lessonList.sort(function (a, b) {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        if (localStorage.getItem("selectedLesson")) {
          const lessonId = localStorage.getItem("selectedLesson");
          if (this.lessonList.some((lesson) => lesson.id === lessonId)) {
            this.selectedLesson = lessonId;
          } else {
            localStorage.removeItem("selectedLesson");
          }
        } else if (this.lessonList.length) {
          this.selectedLesson = this.lessonList[0].id;
        }
      });
    },
    getHighscore(selectedLesson) {
      lessonRepository
        .getLessonHighscore(selectedLesson)
        .then((result) => {
          this.highscoreList = result.data.data;
        })
        .catch(() => {
          showNotification(
            this.$t("highscore.highscores"),
            this.$t(
              "highscore.something_went_wrong_while_getting_highscores_for_this_level",
            ),
            "error",
          );
          localStorage.removeItem("selectedLesson");
        });
    },
    truncate(text) {
      if (text.length > 27) {
        text = text.substring(0, 27) + "...";
      }
      return text;
    },
    gameClick(game) {
      this.gameSelected = true;
      this.setSelectedGame(game);
      this.getLesson(game);
    },
    setSelectedGame(selectedGame) {
      localStorage.setItem("selectedGame", selectedGame);
    },
    setSelectedLesson(selectedLesson) {
      localStorage.setItem("selectedLesson", selectedLesson);
    },
  },
};
</script>

<style scoped></style>
