import Repository from "./Repository";

const resource = "/api/v3/tts";

export default {
  getVoices() {
    return Repository.get(`${resource}/voices`);
  },
  synthesizeToUrl(text) {
    return Repository.post(`${resource}/synthesize-to-url`, text);
  },
  regenerateTts(lesson_id) {
    return Repository.patch(`${resource}/regenerate-tts/${lesson_id}`);
  },
};
