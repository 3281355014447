<template>
  <div>
    <fieldset>
      <div>
        <div class="relative flex items-start">
          <div class="absolute flex items-center h-5">
            <input
              :id="placeholder"
              type="checkbox"
              :checked="modelValue"
              class="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out border-0.5 border-gray-400"
              @change="updateValue"
            />
          </div>
          <div class="pl-6 text-sm leading-5">
            <label :for="placeholder" class="font-medium text-gray-700">
              {{ title }}
            </label>
          </div>
        </div>
      </div>
      <div class="h-6 mt-px"></div>
    </fieldset>
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    title: {
      type: String,
      default: "",
      required: true,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  methods: {
    updateValue(e) {
      this.$emit("update:modelValue", e.target.checked);
    },
  },
};
</script>

<style scoped>
/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 300 !important;
  color: #102a43 !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #ffffff;
  border: 1px solid #bcccdc;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #bcccdc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #bcccdc;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 8px;
  height: 14px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
