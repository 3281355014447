<template>
  <div class="w-full mt-1">
    <div class="flex justify-between">
      <label
        for="input"
        class="block text-sm font-medium leading-5 text-gray-200"
      >
        {{ title }}
        <span
          v-if="requiredInput"
          class="text-gray-200"
          v-tooltip="{
            content: $t('game.required'),
            boundary: 'window',
          }"
        >
          {{ "*" }}
        </span>
      </label>
      <div class="flex">
        <div
          v-if="hint"
          class="flex justify-center bg-white items-center h-3 w-3 rounded-full mt-1 ml-3"
          v-tooltip="{ content: hint, boundary: 'window' }"
        >
          <p class="text-sidebar font-bold text-9">?</p>
        </div>
        <ErrorPopup
          v-if="errorState !== 0"
          :title="$t('editor.this_action_property_contains_an_error')"
          :message="errorMessage"
          icon-size="s"
          class="flex items-center justify-center mr-1 ml-1"
        />
      </div>
    </div>
    <div class="mt-1 relative rounded-md shadow-sm">
      <div
        class="w-full rounded-md overflow-hidden bg-white border shadow-md background"
        :style="{
          borderColor: activeColor,
        }"
        :class="{
          'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red':
            errorMessage,
        }"
      >
        <div v-if="fileSrc">
          <vue-plyr v-if="mediaType === 'audio'" :key="fileSrc">
            <audio>
              <source :src="fileSrc" type="audio/mp3" />
              <source :src="fileSrc" type="audio/ogg" />
              <source :src="fileSrc" type="audio/wav" />
            </audio>
          </vue-plyr>
        </div>

        <Field
          :name="title"
          :rules="`${requiredInput ? 'required' : ''}||min:1`"
          v-model="fileSrc"
          v-slot="{ handleChange, handleBlur }"
        >
          <input hidden type="file" @change="handleChange" @blur="handleBlur" />
        </Field>
      </div>
      <div class="mb-3 mt-3" v-if="fileName">
        <p class="block text-xs font-medium leading-4 text-gray-400">
          {{ $t("general.file_name") }}
        </p>
        <p class="block text-sm font-normal leading-4 text-gray-200">
          {{ fileName }}
        </p>
        <p class="block mt-2 text-xs font-medium leading-4 text-gray-400">
          {{ $t("general.file_type") }}
        </p>
        <p class="block text-sm font-normal leading-4 text-gray-200">
          {{ fileType }}
        </p>
      </div>
      <div class="relative">
        <label
          class="py-3 px-3 mt-2 rounded-md text-sm leading-4 w-auto flex justify-center items-center font-medium bg-gray-500 text-gray-100 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
          @click="toggleMediaModal"
          >{{ $t("button.select") }}
        </label>
      </div>
      <div v-if="uploadError" class="h-6 mt-px">
        <span class="text-sm text-red-600">
          {{ $t("editor.error_file_has_not_been_uploaded") }}
        </span>
      </div>
      <!--        <div-->
      <!--          @click="removeUpload()"-->
      <!--          class="py-3 px-3 mt-2 rounded-md text-sm leading-4 w-24  flex justify-center items-center font-medium bg-gray-500 text-gray-100 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"-->
      <!--        >-->
      <!--          <label>-->
      <!--            <span>{{ $t("general.remove") }}</span>-->
      <!--          </label>-->
      <!--        </div>-->
      <div
        v-if="errorMessage"
        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
      >
        <svg
          class="h-5 w-5 text-red-500"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </div>
    <div class="h-6 mt-px">
      <p v-if="errorMessage" class="text-sm text-red-600">
        {{ errorMessage }}
      </p>
    </div>
    <MediaModal
      v-if="showMediaModal"
      :media-modal-type="mediaType"
      required
      @toggleMediaModal="toggleMediaModal"
      @initializeUpload="initializeUpload"
      @finishUpload="finishUpload"
      @selectFile="selectFile"
      @handleUploadError="handleUploadError"
      @finalizeUpload="finalizeUpload"
    />
  </div>
</template>

<script>
import MediaModal from "@/views/editor/components/modals/mediaModal/MediaModal";
import uploadMediaHelper from "@/mixins/uploadMediaHelper";
import ErrorPopup from "@/views/editor/components/modals/ErrorPopup";
import { Field } from "vee-validate";

export default {
  components: { MediaModal, ErrorPopup, Field },
  mixins: [uploadMediaHelper],
  props: {
    modelValue: {
      type: [String, Number],
      default: "",
    },
    title: {
      type: String,
      default: "Title",
    },
    defaultValue: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    requiredInput: {
      type: Boolean,
      default: false,
    },
    activeColor: {
      type: String,
      default: "#6b7280",
    },
    hint: {
      type: String,
      default: "",
    },
    rules: {
      type: Object,
      default: () => {},
    },
    inputType: {
      type: String,
      default: "text",
    },
    unityPackageActionPropertyId: {
      type: String,
      default: "",
    },
    media: {
      type: Object,
      default: () => {},
    },
    mediaType: {
      type: String,
      default: null,
    },
    errorState: {
      type: Number,
      default: 0,
    },
    errorMessage: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      fileUrl: "",
      fileType: "",
      fileName: "",
      file: "",
    };
  },
  mounted() {
    if (this.media?.data?.uri) {
      this.fileSrc = this.media?.data?.uri || this.modelValue;
      const file = this.fileSrc.split("/").pop();
      this.fileName = file.split(".").reverse().pop();
      this.fileType = file.split(".").pop();
      // TODO
      // this.$refs.provider.validate(this.media.data.uri);
    } else {
      this.fileSrc = this.defaultValue;
    }
  },
  methods: {
    setFileInfo(file, isFile) {
      if (isFile) {
        this.fileSrc = URL.createObjectURL(file[0]);
        this.fileType = file[0].type;
        const name = file[0].name;
        const lastDot = name.indexOf(".");
        this.fileName = lastDot >= 0 ? name.substring(0, lastDot) : name;
      } else {
        this.fileSrc = file.uri;
        this.fileName = file.name;
        this.fileType = file.uri.split(".").pop();
      }
    },
    // async removeUpload() {
    //   this.fileUrl = "";
    //   this.$emit("input", this.fileUrl);
    //   this.audioSrc = "";
    //   this.fileName = "";
    //   this.fileType = "";
    //   await this.$refs.provider.validate();
    // }
  },
};
</script>

<style></style>
