<template>
  <div
    v-if="!disabled"
    id="highscore-row"
    class="mt-2 flex items-center mobile:px-10 h-50 backgroundRow pr-10 flex-row flex-1 justify-between mobile:h-10"
  >
    <div class="flex-1 py-1 pl-1 mobile:hidden h-full">
      <div
        class="poly rounded-l-lg bg-primaryColor h-full w-70 flex justify-center items-center pr-5"
      >
        <p class="text-22 text-lightAlineaColor">
          {{ position }}
        </p>
      </div>
    </div>
    <div class="flex flex-2 desktop:hidden mobile:flex-2">
      <p class="text-center text-18 text-lightAlineaColor">
        {{ position }}
      </p>
    </div>
    <div class="flex-2 flex items-center mobile:text-16 mobile:flex-5">
      <ProfileImage
        class="desktop:hidden"
        :image="image"
        :firstname="firstname"
        :lastname="lastname"
        size="w-30 h-30"
      />
      <ProfileImage
        class="mobile:hidden"
        :image="image"
        :firstname="firstname"
        :lastname="lastname"
        size="w-10 h-10"
      />
      <div class="h-35 pl-5 flex flex-col justify-center">
        <p
          class="text-17 text-lightAlineaColor text-left font-normal leading-none mobile:text-14"
        >
          {{ firstname + " " + lastname }}
        </p>
      </div>
    </div>
    <div
      class="h-35 flex flex-col justify-center flex-1 desktop:ml-1 mobile:flex-2"
    >
      <div>
        <p
          class="text-17 text-lightAlineaColor leading-none font-normal mobile:text-14"
        >
          {{ score }}
        </p>
        <p
          v-if="labeled"
          class="text-13 text-lightAlineaColor font-light leading-none mobile:text-10"
        >
          {{ $t("highscore.score") }}
        </p>
      </div>
    </div>
    <div class="h-35 flex flex-col justify-center flex-1 mobile:hidden">
      <div>
        <p
          class="text-17 text-lightAlineaColor leading-none font-normal mobile:text-14"
        >
          {{ time }}
        </p>
        <p
          v-if="labeled"
          class="text-13 text-lightAlineaColor font-light leading-none mobile:text-10"
        >
          {{ $t("highscore.score") }}
        </p>
      </div>
    </div>
    <div class="h-35 flex flex-col justify-center flex-1 mobile:flex-2">
      <div>
        <p
          class="text-17 text-lightAlineaColor self-center text-left leading-none font-normal mobile:text-14"
        >
          {{ formatDate }}
        </p>
        <p
          v-if="labeled"
          class="text-13 text-lightAlineaColor font-light leading-none mobile:text-10"
        >
          {{ $t("highscore.date") }}
        </p>
      </div>
    </div>
  </div>
  <HighscoreRowDisabled v-else :position="position" />
</template>

<script>
import HighscoreRowDisabled from "./HighscoreRowDisabled";
import ProfileImage from "./../profile/ProfileImage";
import { format } from "date-fns";
export default {
  components: {
    HighscoreRowDisabled,
    ProfileImage,
  },
  props: {
    position: {
      type: Number,
      default: 0,
    },
    image: {
      type: String,
      default: "",
    },
    firstname: {
      type: String,
      default: "FirstName",
    },
    lastname: {
      type: String,
      default: "LastName",
    },
    level: {
      type: String,
      default: "Level",
    },
    score: {
      type: String,
      default: "Score",
    },
    points: {
      type: String,
      default: "Points",
    },
    date: {
      type: String,
      default: "date",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    labeled: {
      type: Boolean,
      default: false,
    },
    time: {
      type: String,
      default: "Time",
    },
  },
  data() {
    return {};
  },
  computed: {
    formatDate: function () {
      return format(
        new Date(this.date.replace(/-/g, "/")),
        this.$i18n.locale === "en" ? "dd MMM yyyy" : "dd-MM-yyyy",
      );
    },
  },
};
</script>

<style scoped>
.poly {
  clip-path: polygon(0 0, 100% 0%, 65% 100%, 0% 100%);
}
</style>
