<template>
  <div class="w-full mt-1">
    <div class="flex justify-between">
      <label
        for="input"
        class="block text-sm font-medium leading-5 text-gray-200"
      >
        {{ title }}
        <span
          v-if="requiredInput"
          class="text-gray-200"
          v-tooltip="{
            content: $t('game.required'),
            boundary: 'window',
          }"
        >
          {{ "*" }}
        </span>
      </label>
      <div class="flex">
        <div
          v-if="hint"
          class="flex justify-center bg-white items-center h-3 w-3 rounded-full mt-1 ml-3"
          v-tooltip="{ content: hint, boundary: 'window' }"
        >
          <p class="text-sidebar font-bold text-9">?</p>
        </div>
        <ErrorPopup
          v-if="errorState !== 0"
          :title="$t('editor.this_action_property_contains_an_error')"
          :message="errorMessage"
          icon-size="s"
          class="flex items-center justify-center mr-1 ml-1"
        />
      </div>
    </div>
    <div class="mt-1 relative rounded-md shadow-sm">
      <input
        v-on="validationListeners"
        :style="{
          borderColor: activeColor,
        }"
        type="number"
        :min="inputConstraint('min')"
        :max="inputConstraint('max')"
        :placeholder="defaultValue"
        :value="inputValue"
        @focusin="disableShortcuts(true)"
        @focusout="disableShortcuts(false)"
        class="form-input block w-full text-gray-200 placeholder-gray-600 bg-sidebar sm:text-sm sm:leading-5"
        :class="{
          'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red':
            errors,
        }"
      />
      <div
        v-if="errors"
        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
      >
        <svg
          class="h-5 w-5 text-red-500"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </div>
    <div class="h-6 mt-px">
      <p v-if="errors" class="text-sm text-red-600">
        {{ errors }}
      </p>
    </div>
  </div>
</template>

<script>
import ErrorPopup from "@/views/editor/components/modals/ErrorPopup";
import { disableShortcuts } from "@/services/disableShortcutsService";
import useValidation from "@/helpers/useValidation";

export default {
  name: "Int",

  components: { ErrorPopup },

  props: {
    modelValue: {
      type: [Number, String],
      default: 0,
    },
    title: {
      type: String,
      default: "Title",
    },
    defaultValue: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    requiredInput: {
      type: Boolean,
      default: false,
    },
    activeColor: {
      type: String,
      default: "#6b7280",
    },
    hint: {
      type: String,
      default: "",
    },
    rules: {
      type: Object,
      default: () => {},
    },
    inputType: {
      type: String,
      default: "text",
    },
    constraintType: {
      type: String,
      default: "",
    },
    constraintProperties: {
      type: Object,
      default: () => {},
    },
    unity: {
      type: String,
      default: "",
    },
    errorState: {
      type: Number,
      default: 0,
    },
    errorMessage: {
      type: String,
      default: "",
    },
  },

  setup(props, { emit }) {
    const {
      validationListeners,
      errorMessage: errors,
      inputValue,
      meta,
      updateField,
    } = useValidation(props, emit);

    return {
      validationListeners,
      errors,
      inputValue,
      updateField,
      meta,
    };
  },

  data() {
    return {
      newValue: this.modelValue ?? this.defaultValue,
    };
  },

  methods: {
    inputConstraint(arg) {
      if (this.constraintType === "GreaterThanOrEqual" && arg === "min") {
        return this.constraintProperties.value;
      }
      if (this.constraintType === "GreaterThanOrEqual" && arg === "max") {
        return "";
      }
      if (this.constraintType === "Range" && arg === "min") {
        return this.constraintProperties.min;
      }
      if (this.constraintType === "Range" && arg === "max") {
        return this.constraintProperties.max;
      }
      return "";
    },

    updateValue() {
      this.$emit("update:modelValue", this.newValue);
    },

    disableShortcuts(boolean) {
      disableShortcuts(boolean);
    },
  },
};
</script>

<style></style>
