<template>
  <router-link :to="{ name: link }" custom v-slot="{ navigate }">
    <button
      @click="navigate"
      class="card flex flex-row justify-between items-center h-10 bg-secondaryOpacityButton mr-15 mb-15 cursor-pointer p-3 rounded-full text-lightAlineaColor"
      :class="active ? 'bg-blue-500' : 'hover:bg-secondaryColor'"
    >
      <font-awesome-icon :icon="icon" class="flex fa" />
      <span class="text-17 font-medium leading-none ml-3 mobile:hidden">
        {{ title }}
      </span>
    </button>
  </router-link>
</template>

<script>
export default {
  name: "ReportNavButton",
  props: {
    title: {
      type: String,
      default: "Title",
      required: true,
    },
    icon: {
      type: String,
      default: "play",
      required: true,
    },
    link: {
      type: String,
      default: "play",
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.active {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
</style>
