<template>
  <div class="w-full mt-1">
    <div class="flex justify-between">
      <label
        for="input"
        class="block text-sm font-medium leading-5 text-gray-200"
      >
        {{ title }}
        <span
          v-if="requiredInput"
          class="text-gray-200"
          v-tooltip="{
            content: $t('game.required'),
            boundary: 'window',
          }"
        >
          {{ "*" }}
        </span>
      </label>
      <div class="flex">
        <div
          v-if="hint"
          class="flex justify-center bg-white items-center h-3 w-3 rounded-full mt-1 ml-3"
          v-tooltip="{ content: hint, boundary: 'window' }"
        >
          <p class="text-sidebar font-bold text-9">?</p>
        </div>
        <ErrorPopup
          v-if="errorState !== 0"
          :title="$t('editor.this_action_property_contains_an_error')"
          :message="errorMessage"
          icon-size="s"
          class="flex items-center justify-center mr-1 ml-1"
        />
      </div>
    </div>
    <div class="mt-1 relative rounded-md shadow-sm">
      <div class="flex justify-between items-center">
        <AutoComplete
          v-model="selectedValue"
          dropdown
          force-selection
          :suggestions="suggestions"
          @complete="search"
          @change="updateValue"
          @focus="disableShortcuts(true)"
          @blur="disableShortcuts(false)"
        />
      </div>
      <div
        v-if="errorMessage"
        class="absolute inset-y-0 right-0 pr-8 flex items-center pointer-events-none"
      >
        <svg
          class="h-5 w-5 text-red-500"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </div>

    <div class="h-6 mt-px">
      <p v-if="errorMessage" class="text-sm text-red-600">
        {{ errorMessage }}
      </p>
    </div>
  </div>
</template>

<script>
import ErrorPopup from "@/views/editor/components/modals/ErrorPopup";
import { useField } from "vee-validate";
import { disableShortcuts } from "@/services/disableShortcutsService";
import AutoComplete from "primevue/autocomplete";

export default {
  name: "Choice",
  components: { ErrorPopup, AutoComplete },
  props: {
    title: {
      type: String,
      default: "",
      required: true,
    },
    modelValue: {
      type: [String, Number],
      default: "",
      required: false,
    },
    defaultValue: {
      type: [String, Number],
      default: "",
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    activeColor: {
      type: String,
      default: "#6b7280",
    },
    hint: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "",
    },
    requiredInput: {
      type: Boolean,
      default: false,
    },
    errorState: {
      type: Number,
      default: 0,
    },
    errorMessage: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue", "update"],
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.title, props.requiredInput ? "required" : "", {
      initialValue: props.modelValue,
      syncVModel: true,
    });

    return {
      handleChange,
      handleBlur,
      inputValue,
      meta,
    };
  },
  data() {
    return {
      selectedValue: this.modelValue,
      suggestions: [...this.options],
      items: [...Array(10).keys()],
    };
  },
  mounted() {
    // This is needed to set the already selected TTS voice or other sibling related data
    this.$emit("update", {
      propertyName: this.title,
      value: this.inputValue,
    });
  },
  methods: {
    updateValue() {
      this.inputValue = this.selectedValue;

      this.$emit("update", {
        propertyName: this.title,
        value: this.inputValue,
      });
      this.$emit("update:modelValue", this.inputValue);
    },
    disableShortcuts(boolean) {
      disableShortcuts(boolean);
    },
    search(event) {
      this.suggestions = [...this.options].filter(
        (element) =>
          element.toLowerCase().indexOf(event.query.toLowerCase()) !== -1,
      );
    },
  },
};
</script>
