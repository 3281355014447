<template>
  <div
    class="mb-2 flex flex-row items-center backgroundRow w-full p-4"
    :class="{
      'hover:border-secondaryColor hover:border cursor-pointer':
        passedAndFinished,
    }"
    @click="clickAction"
  >
    <div class="flex flex-4 flex-col">
      <p class="text-17 font-medium leading-none text-lightAlineaColor">
        {{ levelname }}
      </p>
    </div>
    <div class="flex flex-2 flex-col">
      <p class="text-17 font-medium leading-none text-lightAlineaColor">
        {{ scorePoints }}
      </p>
    </div>
    <div class="flex flex-3 flex-col mobile:hidden">
      <p class="text-17 font-medium leading-none text-lightAlineaColor">
        {{ scorePercentage }}%
      </p>
    </div>
    <div class="flex flex-3 flex-col mobile:hidden">
      <p class="text-17 font-medium leading-none text-lightAlineaColor">
        {{ passingPercentage }}%
      </p>
    </div>
    <div class="flex flex-2 flex-col">
      <p class="text-17 font-medium leading-none text-lightAlineaColor">
        {{ time }}
      </p>
    </div>
    <div class="flex flex-2 flex-col mobile:hidden">
      <p class="text-17 font-medium leading-none text-lightAlineaColor">
        {{ date }}
      </p>
    </div>
    <div class="flex flex-2 flex-row justify-end">
      <font-awesome-icon
        v-if="passedAndFinished"
        icon="check"
        class="fa-2x mr-15 w-30 leading-none text-rapportGreen"
      />
      <font-awesome-icon
        v-else
        icon="times"
        class="fa-2x mr-15 w-30 leading-none text-rapportRed"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    levelname: {
      type: String,
      default: "Levelname",
    },
    passingPercentage: {
      type: Number,
      default: 0,
    },
    scorePercentage: {
      type: Number,
      default: 0,
    },
    scorePoints: {
      type: Number,
      default: 0,
    },
    rank: {
      type: String,
      default: "Rank",
    },
    failed: {
      type: Number,
      default: 10,
    },
    passed: {
      type: Number,
      default: 10,
    },
    time: {
      type: String,
      default: "Time",
    },
    date: {
      type: String,
      default: "Date",
    },
    passedAndFinished: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  methods: {
    isMobile() {
      return screen.width <= 820;
    },
    clickAction() {
      if (this.passedAndFinished) {
        this.$emit("clickAction");
      }
    },
  },
};
</script>

<style></style>
