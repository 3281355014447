<template>
  <div :class="{ 'w-full': fullwidth }">
    <div v-if="title || requiredInput" class="flex justify-between mb-1">
      <label
        for="input"
        class="block text-sm font-medium leading-5"
        :class="[textColor]"
      >
        {{ title }}
      </label>
      <span v-if="requiredInput" class="text-sm leading-5" :class="[textColor]">
        {{ $t("game.required") }}
      </span>
    </div>
    <div class="mt-1 sm:mt-0 sm:col-span-2" :class="{ 'w-full': fullwidth }">
      <div class="max-w-full rounded-md shadow-sm">
        <select
          id="selector"
          class="block form-select w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 border-gray-300"
          :class="[style]"
          :value="modelValue"
          @input="updateValue"
        >
          <slot />
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectorField",
  props: {
    title: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Object, Number],
      default: "",
    },
    light: {
      type: Boolean,
      default: false,
    },
    requiredInput: {
      type: Boolean,
      default: false,
    },
    fullwidth: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    style: function () {
      if (this.light) return "text-gray-100 bg-rowHover";
      else return "border-gray-300";
    },
    textColor: function () {
      if (this.light) return "text-gray-100";
      else return "text-gray-700";
    },
  },
  methods: {
    updateValue(e) {
      this.$emit("update:modelValue", e.target.value);
    },
  },
};
</script>

<style></style>
