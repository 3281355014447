<template>
  <tr>
    <td class="px-6 py-4 whitespace-nowrap">
      <div class="flex items-center">
        <div>
          <div class="text-normal leading-5 font-medium text-gray-100">
            {{ name }}
          </div>
        </div>
      </div>
    </td>
    <td class="px-6 py-4 whitespace-nowrap">
      <div class="flex items-center">
        <div>
          <div class="text-normal leading-5 font-medium text-gray-100">
            {{ description }}
          </div>
        </div>
      </div>
    </td>
    <td
      class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-200 flex justify-end"
    >
      <Button
        button-type="editor-primary"
        class="mr-2"
        icon="edit"
        @clickAction="$emit('edit')"
      />
      <Button
        button-type="trashcan"
        hover="red"
        :loading="loading"
        class="mr-2"
        icon="trash"
        @clickAction="deleteTrophy()"
      />
    </td>
  </tr>
</template>

<script>
import Button from "../../../components/form/Button";
import { mapState } from "vuex";
import { showNotification } from "@/services/notificationService";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const trainingRepository = RepositoryFactory.get("training");

export default {
  name: "TableRow",
  components: { Button },
  props: {
    id: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState("trainingStore", ["trainingId"]),
  },
  methods: {
    deleteTrophy() {
      this.loading = true;
      trainingRepository
        .deleteTrophies(this.trainingId, this.id)
        .then(() => {
          this.loading = false;
          showNotification(
            this.$t("notifications.trophy_delete"),
            this.$t("notifications.trophy_is_successfully_deleted"),
            "success",
          );
          this.$emit("deleted", this.id);
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
.fileContainer {
  overflow: hidden;
  position: relative;
}

.fileContainer [type="file"] {
  cursor: inherit;
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}
</style>
