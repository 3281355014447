<template>
  <div class="flex flex-1 flex-col p-2 cursor-pointer">
    <div class="flex justify-between mb-3">
      <h3 class="block text-base font-medium text-gray-50">
        {{ `${$t("editor.actions")} (${actionResults.length})` }}
      </h3>
    </div>
    <div class="flex flex-col justify-between">
      <div
        v-for="(action, index) in actionResults"
        :key="action.id"
        class="block bg-actionBlue shadow text-white p-2 mb-2 hover:bg-activeAction"
        @mouseover="showJumpMessage = index"
        @mouseleave="showJumpMessage = null"
      >
        <div
          class="flex flex-row w-full justify-between items-center"
          @click="pickResult(action.id, action.step_id)"
        >
          <div class="flex flex-col w-2/3">
            <label class="cursor-pointer">
              {{ action.name }}
            </label>
            <label class="text-13 text-gray-200 cursor-pointer">
              {{ `${$t("editor.step_name")}: ${action.step_name}` }}
            </label>
          </div>
          <div
            class="flex justify-end w-1/3"
            :class="showJumpMessage === index ? 'visible' : 'invisible'"
          >
            {{ $t("editor.jump_to") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActionResults",
  props: {
    actionResults: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showJumpMessage: false,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    pickResult(id, stepId) {
      const pickedResult = {
        searchTarget: "action",
        searchTargetId: id,
        searchStepId: stepId,
      };
      this.$emit("pickResult", pickedResult);
    },
  },
};
</script>

<style></style>
