<template>
  <ModalContainer @cancel="close">
    <div class="flex flex-col bg-gray-100 shadow rounded-lg w-3/4 h-3/4">
      <div class="border-b border-gray-200 flex justify-between">
        <nav class="flex -mb-px">
          <a
            @click="activeTab = 1"
            class="cursor-pointer ml-8 group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
            :class="{
              'border-blue-500 text-blue-600 focus:outline-none focus:text-blue-800 focus:border-blue-700':
                activeTab === 1,
            }"
          >
            <font-awesome-icon
              icon="list"
              class="-ml-0.5 mr-2 h-5 w-5 fa-sm text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600"
              :class="{
                'text-blue-500 group-focus:text-blue-600': activeTab === 1,
              }"
            />
            <span>{{ $t("media.select_media") }}</span>
          </a>
        </nav>
        <div class="flex items-center justify-center mt-2 mb-2">
          <font-awesome-icon
            icon="times"
            @click="close"
            class="mr-8 h-5 w-5 fa-sm text-gray-600 cursor-pointer group-hover:text-gray-800 group-focus:text-gray-700"
          />
        </div>
      </div>

      <RecentUploads
        v-if="mediaModalType === 'recent-translation-files'"
        :media-modal-type="mediaModalType"
        @selectFile="selectFile"
      />
    </div>
  </ModalContainer>
</template>

<script>
import RecentUploads from "./RecentUploads";
import ModalContainer from "@/components/helpers/ModalContainer";
export default {
  name: "FilesModal",
  components: {
    ModalContainer,
    RecentUploads,
  },
  props: {
    mediaModalType: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  data: () => {
    return {
      activeTab: 1,
    };
  },
  methods: {
    close() {
      this.$emit("toggleMediaModal");
    },
    selectFile(file) {
      this.$emit("selectFile", file);
    },
  },
};
</script>

<style scoped></style>
