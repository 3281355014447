<template>
  <ModalContainer @cancel="close">
    <div class="fixed inset-0 transition-opacity">
      <div class="absolute inset-0 bg-modalBackground"></div>
    </div>
    <div
      v-click-outside="close"
      class="bg-gray-50 rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
    >
      <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div
            v-if="icon === 'warning'"
            class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
          >
            <svg
              class="h-6 w-6 text-red-600"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3
              v-if="this.title"
              class="text-lg leading-6 font-medium text-gray-900"
            >
              {{ this.title }}
            </h3>
            <div class="mt-2">
              <p class="text-sm leading-5 text-gray-500">
                {{ this.message }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-50 px-4 py-3 sm:px-6 flex flex-row justify-end">
        <Button
          type="button"
          button-type="secondary"
          :title="$t('general.cancel')"
          @clickAction="cancel()"
        />
        <Button
          class="ml-3"
          type="button"
          button-type="primary"
          :title="$t('general.confirm')"
          @clickAction="ok()"
        />
      </div>
    </div>
  </ModalContainer>
</template>

<script>
import ModalContainer from "../ModalContainer";
import Button from "../../helpers/Button";

export default {
  components: { Button, ModalContainer },

  props: {
    title: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: null,
    },
  },

  methods: {
    ok() {
      this.$emit("ok");
    },
    cancel() {
      this.$emit("cancel");
    },
    close() {
      this.$emit("cancel");
    },
  },
};
</script>

<style></style>
