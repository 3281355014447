<template>
  <ModalContainer @cancel="cancel">
    <div class="bg-white shadow sm:rounded-lg">
      <div class="px-4 py-5 sm:px-6">
        <div class="border-b border-gray-200 pb-5 flex items-center">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            {{ $t("dealer.add_user_to_dealer") }}
          </h3>
          <font-awesome-icon
            icon="times"
            class="text-22 mt-1 ml-10"
            @click="cancel()"
          />
        </div>

        <div class="mt-5 sm:flex sm:items-end flex-col">
          <div class="max-w-xs w-full mb-5">
            <p v-if="!users.length">
              {{ $t("dealers.no_available_users") }}
            </p>
            <SelectorField
              :title="$t('customers.users')"
              v-model="selectedCustomer"
            >
              <option
                v-for="(customerOption, index) in customers"
                :key="`${customerOption.firstname}-${index}`"
                :value="selectedCustomer"
              >
                {{ customerOption.firstname + " " + customerOption.lastname }}
              </option>
            </SelectorField>
          </div>
        </div>

        <div class="border-t border-gray-200 pt-6">
          <div class="flex flex-row justify-end">
            <Button
              button-type="primary"
              :loading="loading"
              :title="$t('dealers.add')"
              @clickAction="send()"
            />
          </div>
        </div>
      </div>
    </div>
  </ModalContainer>
</template>

<script>
import ModalContainer from "./../../ModalContainer";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import SelectorField from "../../../form/SelectorField";
import Button from "../../../form/Button";
import { errorHandlingAndMessage } from "@/services/ErrorService";
const usersRepository = RepositoryFactory.get("customer");

export default {
  name: "AddUserModal",
  components: {
    ModalContainer,
    Button,
    SelectorField,
  },
  props: {
    dealer: {
      type: Number,
      default: null,
    },
    customer: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      customers: [],
      selectedCustomer: {},
    };
  },
  created() {
    usersRepository
      .getCustomers()
      .then((result) => {
        this.customers = result.data.data;
        // this.customers = result.data.data.filter(val => {
        //   return !val.dealer;
        // });
      })
      .catch((err) => {
        errorHandlingAndMessage(
          err,
          this.$t("notifications.something_went_wrong_receiving_customers"),
        );
      });
  },
  methods: {
    addUser() {
      if (this.selectedCustomer) {
        const customer = JSON.parse(this.selectedCustomer);
        customer.dealer_id = this.dealer;
        usersRepository
          .updateUser(user)
          .then((result) => {
            showNotification(
              this.$t("notifications.customer_added_to_dealer"),
              this.$t(
                "notifications.the_customer_is_successfully_added_to_the_dealer",
              ),
              "success",
            );
            this.$emit("cancel");
          })
          .catch((error) => {
            errorHandlingAndMessage(
              err,
              this.$t("notifications.there_is_no_customer_selected"),
            );
            this.$emit("cancel");
          });
      } else {
      }
    },

    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style></style>
