import Repository from "./Repository";
import { format } from "date-fns";
const resource = "/api/v3/reporting";

export default {
  getMonthlyUsagePeriod(start_date, end_date) {
    return Repository.get(`${resource}/total-playtime`, {
      params: {
        start_date,
        end_date,
      },
    });
  },

  getMonthlyNumberOfUsersPlayedLevel(start_date, end_date) {
    return Repository.get(`${resource}/users-played-level`, {
      params: {
        start_date,
        end_date,
      },
    });
  },

  getAllAveragePercentage(start_date, end_date) {
    return Repository.get(`${resource}/all-average-percentage`, {
      params: {
        start_date,
        end_date,
      },
    });
  },

  getAllAveragePlaytime(start_date, end_date) {
    return Repository.get(`${resource}/all-average-playtime`, {
      params: {
        start_date,
        end_date,
      },
    });
  },

  getTopPlaytime(start_date, end_date, sort) {
    return Repository.get(`${resource}/top-playtime`, {
      params: {
        start_date,
        end_date,
        sort,
      },
    });
  },

  getActivityDataCSV(startdate, enddate) {
    const start_date = format(startdate, "yyyy-MM-dd");
    const end_date = format(enddate, "yyyy-MM-dd");
    return Repository.get(`${resource}/download-activity-data`, {
      params: {
        start_date,
        end_date,
      },
    });
  },

  downloadCSV(url) {
    return Repository.get(`${url}`);
  },
};
