<template>
  <ModalContainer @cancel="close">
    <div
      class="flex flex-col justify-between w-3/4 lg:w-1/2 bg-gray-50 p-4 rounded-md overflow-y-visible"
    >
      <p
        class="flex self-start text-gray-700 font-normal text-20 tracking-widest pb-4"
      >
        {{ $t("editor.add_level").toUpperCase() }}
      </p>
      <Form
        class="flex flex-col h-full justify-between mt-3"
        @keypress.enter="saveLesson"
        @submit="saveLesson"
        v-slot="{ errors }"
      >
        <div class="w-full">
          <InputField
            class="mt-3"
            :title="$t('editor.name')"
            v-model="createLesson.name"
            required-input
            rules="required|min:4|max:255"
          />
          <InputField
            class="mt-3"
            :title="$t('editor.introduction')"
            v-model="createLesson.intro"
            required-input
            rules="required|min:4|max:255"
          />
          <div class="flex flex-row">
            <InputField
              class="mt-3 flex-1 mr-3"
              :title="$t('editor.description')"
              v-model="createLesson.description"
              required-input
              rules="required|min:4|max:255"
            />
            <InputField
              class="mt-3 flex-1 ml-3"
              :title="$t('editor.reference')"
              v-model="createLesson.reference"
              required-input
              rules="required|min:4|max:255"
            />
          </div>
          <div class="flex flex-row">
            <div class="flex-1 mr-2">
              <InputField
                class="mt-3 flex-1 mr-3 mb-8"
                :title="$t('editor.passing_percentage')"
                type="number"
                v-model="createLesson.passing_percentage"
                required-input
                rules="required|min_value:0|max_value:100"
              />
              <div
                v-if="createLesson.trophies"
                class="sm:mt-0 sm:col-span-2 mb-8 mr-1"
              >
                <TrophySelect
                  v-model="createLesson.trophies"
                  :selected-trophies="createLesson.trophy_dependencies"
                  :options="trophies"
                  :title="$t('trophies.trophies_earned')"
                  rules="minArrayLength"
                />
              </div>
              <div
                v-if="createLesson.trophies"
                class="sm:mt-0 sm:col-span-2 my-8 mr-1"
              >
                <TrophySelect
                  v-model="createLesson.trophy_dependencies"
                  :selected-trophies="createLesson.trophies"
                  :options="trophies"
                  :title="$t('trophies.trophies_required')"
                />
              </div>
              <CheckBox
                :title="$t('editor.active')"
                v-model="createLesson.active"
              ></CheckBox>
              <CheckBox
                :title="$t('editor.demo')"
                v-model="createLesson.demo"
              ></CheckBox>
              <CheckBox
                :title="$t('editor.is_vr_ready')"
                v-model="createLesson.is_vr_ready"
              ></CheckBox>
            </div>
            <div class="mt-3 flex-1 ml-3">
              <div
                class="w-full rounded-md overflow-hidden bg-white border border-gray-300 shadow-md background"
              >
                <div
                  v-if="fileSrc"
                  :style="{
                    height: '275px',
                    maxWidth: 'initial',
                    backgroundImage: 'url(' + fileSrc + ')',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                  }"
                ></div>
                <div
                  v-else
                  class="flex justify-center items-center"
                  :style="{ height: '275px', maxWidth: 'initial' }"
                >
                  <font-awesome-icon icon="image" class="fa-7x text-gray-300" />
                </div>
              </div>
              <div class="flex flex-row">
                <div
                  v-if="!fileUrl"
                  class="py-3 px-3 mt-2 mr-2 rounded-md text-sm leading-4 w-24 flex justify-center items-center font-medium bg-gray-500 text-gray-100 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
                >
                  <label class="fileContainer">
                    <span>{{ setButtonStatus() }}</span>
                    <input
                      type="file"
                      @change="uploadFile($event.target.files, false)"
                    />
                  </label>
                </div>
                <div
                  v-else
                  class="py-3 px-3 mt-2 rounded-md text-sm leading-4 w-24 flex justify-center items-center font-medium bg-gray-500 text-gray-100 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
                >
                  <label>
                    <span @click="removeImage">{{ $t("general.remove") }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-row justify-end">
            <Button
              class="mr-3"
              :title="$t('editor.create')"
              type="submit"
              button-type="primary"
              :invalid="
                Object.keys(errors).length !== 0 ||
                (Object.keys(errors).length === 0 && input)
              "
            />
            <Button
              icon="upload"
              button-type="secondary"
              class="mr-3"
              @clickAction="toggleModal"
            />
            <Button
              type="button"
              button-type="secondary"
              :title="$t('editor.cancel')"
              @clickAction="close"
            />
          </div>
          <div v-if="showImportModal">
            <ImportModal
              header-text="Import step"
              sub-header-text="Select a valid JSON and upload it"
              target="level"
              :step-id="trainingId"
              @close="toggleModal"
            />
          </div>
        </div>
      </Form>
    </div>
  </ModalContainer>
</template>

<script>
import InputField from "@/components/form/InputField";
import Button from "@/components/form/Button";
import { showNotification } from "@/services/notificationService.js";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const trainingRepository = RepositoryFactory.get("training");
import { mapState } from "vuex";
import ImportModal from "@/views/editor/components/modals/ImportModal";
import ModalContainer from "@/components/helpers/ModalContainer";
import uploadFile from "@/mixins/uploadFile";
import CheckBox from "@/components/form/Checkbox";
import TrophySelect from "@/views/editor/components/modals/level/TrophySelect";

export default {
  name: "AddLevel",
  components: {
    InputField,
    Button,
    ModalContainer,
    CheckBox,
    ImportModal,
    TrophySelect,
  },
  mixins: [uploadFile],
  data() {
    return {
      loading: false,
      showImportModal: false,
      createLesson: {
        name: "",
        description: "",
        intro: "",
        active: true,
        is_vr_ready: false,
        reference: "",
        passing_percentage: 0,
        trophy_dependencies: [],
        trophies: [],
        sort: 0,
        demo: false,
        image: this.fileUrl,
      },
      trophies: [],
      fileSrc: "",
      fileUrl: "",
      fileLoading: "",
    };
  },
  computed: {
    ...mapState("trainingStore", ["trainingId", "versionId", "subchapter"]),

    input: function () {
      return !this.createLesson.trophies?.length;
    },
  },
  mounted() {
    this.getTrophies();
  },
  methods: {
    saveLesson() {
      const lesson = {
        name: this.createLesson.name,
        description: this.createLesson.description,
        intro: this.createLesson.intro,
        active: this.createLesson.active,
        is_vr_ready: this.createLesson.is_vr_ready,
        reference: this.createLesson.reference,
        passing_percentage: parseInt(this.createLesson.passing_percentage),
        trophy_dependencies: this.createLesson.trophy_dependencies,
        trophies: this.createLesson.trophies,
        sort: parseInt(this.createLesson.sort),
        parent: this.subchapter.id,
        demo: this.createLesson.demo,
        image: this.fileUrl,
      };
      trainingRepository
        .createLesson(this.trainingId, this.versionId, lesson)
        .then(() => {
          this.$store.dispatch("trainingStore/GET_CHAPTERS");
          showNotification(
            this.$t("notifications.level_created"),
            this.$t("notifications.level_is_successfully_created"),
            "success",
          );
          this.close();
        })
        .catch(() => {
          showNotification(
            this.$t("notifications.create_level"),
            this.$t(
              "notifications.something_went_wrong_while_creating_this_level",
            ),
            "error",
          );
        });
    },
    close() {
      this.$store.dispatch("trainingStore/SHOW_LEVEL_MODAL", "none");
    },

    getTrophies() {
      this.loading = true;
      trainingRepository
        .getTrophies(this.trainingId)
        .then((result) => {
          this.trophies = result.data.data;
          this.loading = false;
        })
        .catch(() => {});
    },
    setFileInfo(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.fileSrc = e.target.result;
      };
      reader.readAsDataURL(file[0]);
    },
    removeImage() {
      this.fileUrl = "";
      this.fileSrc = "";
    },
    toggleModal() {
      this.showImportModal = !this.showImportModal;
    },
  },
};
</script>

<style></style>
