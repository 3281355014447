<template>
  <div class="w-full mt-1">
    <div class="flex justify-between">
      <label
        for="input"
        class="block text-sm font-medium leading-5 text-gray-200"
      >
        {{ title }}
        <span
          v-if="requiredInput"
          class="text-gray-200"
          v-tooltip="{
            content: $t('game.required'),
            boundary: 'window',
          }"
        >
          {{ "*" }}
        </span>
      </label>
      <div class="flex">
        <div
          v-if="hint"
          class="flex justify-center bg-white items-center h-3 w-3 rounded-full mt-1 ml-3"
          v-tooltip="{ content: hint, boundary: 'window' }"
        >
          <p class="text-sidebar font-bold text-9">?</p>
        </div>
        <ErrorPopup
          v-if="errorState !== 0"
          :title="$t('editor.this_action_property_contains_an_error')"
          :message="errorMessage"
          icon-size="s"
          class="flex items-center justify-center mr-1 ml-1"
        />
      </div>
    </div>
    <div class="mt-1 relative rounded-md shadow-sm">
      <div
        class="w-full rounded-md overflow-hidden bg-white border border-gray-300 shadow-md background"
        :style="{
          borderColor: activeColor,
        }"
        :class="{
          'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red':
            errors.length,
        }"
      >
        <div
          v-if="fileSrc"
          :key="fileSrc"
          :style="{
            height: '275px',
            maxWidth: 'initial',
            backgroundImage: 'url(' + fileSrc + ')',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }"
        ></div>
        <div
          v-else
          class="flex justify-center items-center"
          :style="{ height: '275px', maxWidth: 'initial' }"
        >
          <font-awesome-icon icon="image" class="fa-7x text-gray-300" />
        </div>
      </div>

      <div class="relative">
        <label
          class="py-3 px-3 mt-2 rounded-md text-sm leading-4 w-auto flex justify-center items-center font-medium bg-gray-500 text-gray-100 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
          @click="toggleMediaModal"
          >{{ $t("button.select") }}
        </label>
        <div
          v-if="errors.length"
          class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
        >
          <svg
            class="h-5 w-5 text-red-500"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </div>

      <Field
        :name="title"
        :rules="`${requiredInput ? 'required' : ''}||min:1`"
        v-model="fileSrc"
        v-slot="{ handleChange, handleBlur }"
      >
        <input hidden type="file" @change="handleChange" @blur="handleBlur" />
      </Field>

      <div v-if="uploadError" class="h-6 mt-px">
        <span class="text-sm text-red-600">
          {{ $t("editor.error_file_has_not_been_uploaded") }}
        </span>
      </div>
    </div>
    <div class="h-6 mt-px">
      <p v-if="errors.length" class="text-sm text-red-600">
        {{ errors[0] }}
      </p>
    </div>
    <MediaModal
      v-if="showMediaModal"
      media-modal-type="image"
      :required="true"
      @toggleMediaModal="toggleMediaModal"
      @initializeUpload="initializeUpload"
      @finishUpload="finishUpload"
      @selectFile="selectFile"
      @handleUploadError="handleUploadError"
      @finalizeUpload="finalizeUpload"
    />
  </div>
</template>

<script>
import uploadMediaHelper from "@/mixins/uploadMediaHelper";
import MediaModal from "@/views/editor/components/modals/mediaModal/MediaModal";
import ErrorPopup from "@/views/editor/components/modals/ErrorPopup";
import { Field } from "vee-validate";

export default {
  name: "File",
  components: { MediaModal, ErrorPopup, Field },
  mixins: [uploadMediaHelper],
  props: {
    modelValue: {
      type: [String, Number],
      default: "",
    },
    title: {
      type: String,
      default: "Title",
    },
    defaultValue: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    requiredInput: {
      type: Boolean,
      default: false,
    },
    activeColor: {
      type: String,
      default: "#6b7280",
    },
    hint: {
      type: String,
      default: "",
    },
    rules: {
      type: Object,
      default: () => {},
    },
    inputType: {
      type: String,
      default: "text",
    },
    media: {
      type: Object,
      default: () => {},
    },
    unityPackageActionPropertyId: {
      type: String,
      required: true,
    },
    errorState: {
      type: Number,
      default: 0,
    },
    errorMessage: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      fileSrc: "",
      fileType: "",
      errors: [],
    };
  },
  mounted() {
    if (this.media?.data?.uri) {
      this.fileSrc = this.media?.data?.uri || this.value;
      const file = this.fileSrc.split("/").pop();
      this.fileName = file.split(".").reverse().pop();
      this.fileType = file.split(".").pop();
      // this.$refs.provider.validate(this.media.data.uri);
    } else {
      this.fileSrc = this.defaultValue;
    }
  },
  methods: {
    setFileInfo(file, isFile) {
      if (isFile) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.fileSrc = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      } else {
        this.fileSrc = file.uri;
        this.fileName = file.name;
      }
    },
  },
};
</script>

<style></style>
