<template>
  <svg
    width="20"
    height="34"
    viewBox="-1.8 2.5 10 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <title>Layer 1</title>
      <rect
        stroke="null"
        fill="#161e2e"
        id="svg_6"
        height="4.12287"
        width="1.77573"
        y="19.43583"
        x="0.00322"
      />
      <path
        id="svg_1"
        fill="white"
        d="m9.35355,19.3536c0.19527,-0.1953 0.19527,-0.5119 0,-0.7072l-3.18198,-3.1819c-0.19526,-0.1953 -0.51184,-0.1953 -0.7071,0c-0.19527,0.1952 -0.19527,0.5118 0,0.7071l2.82842,2.8284l-2.82842,2.8284c-0.19527,0.1953 -0.19527,0.5119 0,0.7071c0.19526,0.1953 0.51184,0.1953 0.7071,0l3.18198,-3.1819zm-9.35355,0.1464l9,0l0,-1l-9,0l0,1z"
      />
      <line
        v-if="showLine"
        id="svg_2"
        stroke="white"
        y2="24"
        x2="0"
        y1="24"
        x1="9"
      />

      <rect
        id="svg_5"
        height="0.03288"
        width="0.06577"
        y="12.52466"
        x="-2.79349"
        stroke="#161e2e"
      />
      <rect
        stroke="null"
        x="-0.03127"
        y="24.49804"
        width="1.80072"
        height="0.50429"
        id="svg_7"
        fill="#161e2e"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "SecondArrow",

  props: {
    showLine: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
