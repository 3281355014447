<template>
  <tr
    @click="
      $router.push({ name: 'customersDetail', params: { id: customer.id } })
    "
    class="hover:bg-rowHover focus:bg-gray-100 cursor-pointer"
  >
    <td class="px-6 py-4 whitespace-nowrap">
      <div class="flex items-center">
        <div>
          <div class="text-sm leading-5 font-medium text-gray-100">
            {{ customer.name }}
          </div>
        </div>
      </div>
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-200">
      {{ customer.client_id }}
    </td>
    <td class="px-6 py-4 whitespace-nowrap">
      <font-awesome-icon
        :icon="customer.enabled ? 'check' : 'times'"
        class="fa-md"
        :class="{
          'text-green-500': customer.enabled,
          'text-red-500': !customer.enabled,
        }"
      />
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-200">
      {{ formatDate(customer.start_date) }}
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-200">
      {{ formatDate(customer.stop_date) }}
    </td>
    <td
      class="px-6 py-4 whitespace-nowrap text-right text-sm leading-5 font-medium"
    >
      <Button
        button-type="transparent"
        icon="edit"
        hover="blue"
        class="mr-1"
        @clickAction="$emit('edit')"
      />
      <!--      <Button-->
      <!--        icon="trash"-->
      <!--        size="m"-->
      <!--        hover="red"-->
      <!--        button-type="trashcan"-->
      <!--        @clickAction="$emit('delete')"-->
      <!--      />-->
    </td>
  </tr>
</template>

<script>
import Button from "@/components/form/Button";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  components: {
    FontAwesomeIcon,
    Button,
  },

  props: {
    customer: {
      type: Object,
      required: true,
    },
  },

  methods: {
    formatDate(date) {
      if (date) {
        return this.$dayjs(date).format("DD MMM YYYY");
      }

      return "";
    },
  },
};
</script>

<style></style>
