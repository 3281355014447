import Vue from "vue";
import store from "../store/store";

export function showNotification(title, content, type) {
  store
    .dispatch("notificationStore/SHOW_NOTIFICATION", {
      title: title,
      content: content,
      type: type,
    })
    .catch(() => {});
}
