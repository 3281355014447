<template>
  <div
    id="setup-track"
    class="flex flex-row border-t border-editorInput"
    :class="setHeight"
    @click="activeTrack !== 1 ? $emit('onClick') : undefined"
  >
    <div
      v-if="activeTrack === 1"
      class="sticky left-0 w-35 flex flex-col bg-editorOrange cursor-pointer z-1"
      @click="$emit('onClick')"
    >
      <div class="bg-editorOrange flex justify-center items-center h-5">
        <font-awesome-icon
          icon="chevron-down"
          :class="this.rotate"
          class="text-white text-15 fa rotate"
        />
      </div>
      <div class="flex flex-1 justify-center items-center">
        <p class="vertical tracking-widest text-white py-1">
          {{ activeTrack !== 1 ? "" : $t("editor.setup") }}
        </p>
      </div>
    </div>

    <div
      v-if="activeTrack !== 1"
      class="w-screen flex flex-row justify-center items-center bg-editorOrange hover:bg-green-400 cursor-pointer z-1"
      @click="$emit('onClick')"
    >
      <div class="flex flex-row justify-center items-center">
        <div class="flex flex-1 justify-center items-center">
          <p class="tracking-widest text-white py-1">
            {{
              $t("editor.setup_steps_container_number_steps", {
                number: setupSteps.length,
              })
            }}
          </p>
        </div>
        <div class="flex justify-center items-center h-5 ml-3">
          <font-awesome-icon icon="chevron-down" class="text-white text-15" />
        </div>
      </div>
    </div>

    <div
      v-else
      class="w-100 pt-15 pl-15 flex flex-col overflow-y-auto overflow-x-hidden"
    >
      <draggable
        class="w-full pt-15 pl-15 flex flex-col"
        :list="setupSteps"
        @end="changed($event)"
        group="setupSteps"
        item-key="id"
      >
        <template #item="{ element }">
          <NonLinearStep
            class="w-350"
            :key="element.id"
            :id="element.id"
            :title="element.name"
            :step-id="element.id"
            kind="setup"
            :sort="element.sequence"
            :intended="element.is_intended"
            :actions="element.actions.data"
            :remark="element.remark"
            :non-linear-random="element.randomization"
            :on-checklist="element.add_to_checklist"
            :repeatable="element.repeatable"
            :max-score="element.max_score"
            :error-state="element.error_state"
            :opportunity="element.is_opportunity"
            :required="element.is_required"
            :enabled="element.enabled"
            :size="activeTrack === 1 ? 'L' : 'M'"
            @export="toggleExportModal"
          />
        </template>
      </draggable>
      <div
        v-if="activeTrack === 1"
        class="flex items-center cursor-pointer pb-22"
      >
        <div class="relative inline-block text-right ml-5 mb-2">
          <div>
            <OptionMenu
              icon="plus"
              position="left"
              color="text-gray-50"
              :options="options"
              :text="$t('editor.add_new_setup_step')"
              @addSetupStep="addSetupStep"
              @importSetupStep="toggleImportModal"
              @pasteStep="pasteStep('nonLinear')"
            />
          </div>
        </div>
      </div>
      <div v-if="showImportModal">
        <ImportModal
          :header-text="$t('editor.import_step')"
          :sub-header-text="$t('editor.upload_json_to_lesson')"
          target="step"
          :sort="sequence"
          @close="toggleImportModal"
        />
      </div>
      <div v-if="showExportModal">
        <ExportModal
          :header-text="$t('editor.export_step')"
          :sub-header-text="$t('editor.export_this_step_as_json')"
          target="step"
          :step-id="activeStep.id"
          :lesson-id="activeLesson.id"
          @close="toggleExportModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import NonLinearStep from "../components/steps/NonLinearStep";
import { mapState } from "vuex";
import OptionMenu from "@/components/helpers/OptionMenu";
import ImportModal from "@/views/editor/components/modals/ImportModal";
import ExportModal from "@/views/editor/components/modals/ExportModal";
import sortStep from "@/mixins/sortStep";

export default {
  name: "SetupTrack",
  components: {
    draggable,
    NonLinearStep,
    OptionMenu,
    ImportModal,
    ExportModal,
  },
  mixins: [sortStep],
  props: {
    activeTrack: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      rotate: "up",
      idList: [],
      dragId: null,
      showImportModal: false,
      showExportModal: false,
      stepsWithNon: [],
    };
  },
  computed: {
    ...mapState("editorStore", ["setupSteps", "activeLesson", "activeStep"]),
    ...mapState("keyboardShortcutStore", ["clipboard"]),
    setHeight: function () {
      if (this.activeTrack === 1) {
        return "h-[calc(100%-35px)]";
      } else if (this.activeTrack === 0) {
        return "h-35";
      } else {
        return "h-35";
      }
    },
    sequence: function () {
      if (this?.setupSteps[this.setupSteps.length - 1]?.sequence) {
        return this?.setupSteps[this.setupSteps.length - 1]?.sequence;
      } else {
        return 0;
      }
    },
    options() {
      return [
        { option: "addSetupStep", name: "Add new setup step" },
        { option: "importSetupStep", name: "Import setup step" },
        {
          option: "pasteStep",
          name: "Paste step",
          disabled: this.clipboard.target !== "nonLinear",
        },
      ];
    },
  },
  watch: {
    activeTrack: {
      handler() {
        if (this.activeTrack === 1) {
          this.rotate = "down";
        } else {
          this.rotate = "up";
        }
      },
      deep: true,
    },
    setupSteps: {
      handler(val) {
        this.stepsList = val;
      },
      deep: true,
    },
  },

  methods: {
    addSetupStep() {
      this.$store.dispatch("editorStore/SET_ACTIVE_STEP", {
        id: null,
        kind: "setup",
        type: "create",
        activeType: "step",
      });
    },
    toggleImportModal() {
      this.showImportModal = !this.showImportModal;
    },
    toggleExportModal() {
      this.showExportModal = !this.showExportModal;
    },
    pasteStep() {
      this.$store.dispatch(
        "keyboardShortcutStore/PASTE_FROM_CLIPBOARD",
        this.setupSteps.length,
      );
    },
  },
};
</script>

<style></style>
