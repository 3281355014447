<template>
  <div
    class="heightSide overflow-y-scroll scrollbar-thin scrollbar-thumb-white scrollbar-thumb-rounded-full scrollbar-track-rounded-full z-1"
  >
    <div class="flex items-center flex-col">
      <div class="flex flex-row w-full justify-between items-center">
        <p
          class="flex self-start pl-4 pr-4 pt-1 text-white text-18 tracking-widest"
        >
          {{ title ? title : $t("editor.no_action_or_step_selected") }}
        </p>
        <font-awesome-icon
          v-if="showTranslationIcon"
          icon="language"
          class="text-lg mr-4 text-gray-50 cursor-pointer hover:text-blue-500"
          @click="$emit('toggleActionPropertiesTranslationModal')"
        />
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Tab",
  props: {
    title: {
      type: String,
      required: true,
      default: "",
    },
    showTranslationIcon: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.heightSide {
  height: calc(100vh - 90px);
}
</style>
