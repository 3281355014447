<template>
  <div class="flex flex-row items-center justify-between w-full">
    <div class="flex-1 flex justify-start items-center">
      <div
        class="w-80 self-end flex bg-gray-100 items-center relative rounded-full"
      >
        <select
          class="appearance-none w-full h-10 text-gray-500 px-4 rounded-full font-medium leading-tight cursor-pointer"
          id="grid-state"
          v-model="showAmount"
          :selected="maxResult"
        >
          <option :value="7">7</option>
          <option :value="10">10</option>
          <option :value="25">25</option>
          <option :value="50">50</option>
          <option :value="100">100</option>
        </select>
        <div
          class="pointer-events-none items-center mr-2 absolute right-0 text-darkAlineaColor"
        >
          <font-awesome-icon icon="chevron-down" class="fa-sm text-gray-500" />
        </div>
      </div>
      <div v-if="mediaLayout" class="flex items-center relative">
        <div
          class="w-full h-full items-center flex justify-center appearance-none h-10 text-gray-500 px-4 rounded-full font-medium leading-tight mr-3"
        >
          <div class="text-sm">
            {{ `${$t("pagination.total_items")}: ${totalItems}` }}
          </div>
        </div>
      </div>
    </div>

    <div
      class="hidden sm:flex-1 sm:flex items-center justify-end mt-2 lg:mt-0 shadow lg:shadow-none"
    >
      <div>
        <nav class="relative z-0 inline-flex bg-gray-100 rounded-full h-10">
          <button
            v-if="currentPage !== 1"
            type="button"
            class="relative inline-flex items-center px-4 rounded-l-md text-sm leading-5 font-medium hover:text-gray-400 active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
            aria-label="Previous"
            @click="$emit('previous')"
          >
            <font-awesome-icon
              icon="chevron-left"
              class="fa-sm text-gray-500"
            />
          </button>
          <button
            v-if="currentPage > 3 && totalPages > 5"
            type="button"
            class="-ml-px relative inline-flex text-gray-500 items-center px-4 py-2 rounded-r-md text-sm leading-5 font-medium hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
            aria-label="Next"
            @click="$emit('toPage', 1)"
          >
            1
          </button>
          <button
            v-if="currentPage > 3 && totalPages > 5"
            type="button"
            class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md text-sm leading-5 font-medium hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
            aria-label="Next"
            @click="$emit('toPage', currentPage - 5 < 1 ? 1 : currentPage - 5)"
          >
            ...
          </button>
          <button
            v-for="index in displayPages"
            :key="`displayPages-${index}`"
            type="button"
            :class="{
              ' font-black text-secondaryColor': index === currentPage,
              'text-gray-500': index !== currentPage,
            }"
            class="-ml-px relative inline-flex items-center px-4 text-sm leading-5 font-medium hover:text-gray-500 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
            @click="$emit('toPage', index)"
          >
            {{ index }}
          </button>
          <button
            v-if="currentPage < totalPages - 2 && totalPages > 5"
            type="button"
            class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md text-sm leading-5 font-medium hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
            aria-label="Next"
            @click="
              $emit(
                'toPage',
                currentPage + 5 > totalPages ? totalPages : currentPage + 5,
              )
            "
          >
            ...
          </button>
          <button
            v-if="totalPages > 5 && currentPage + 2 < totalPages"
            type="button"
            class="-ml-px relative inline-flex text-gray-500 items-center px-4 py-2 rounded-r-md text-sm leading-5 font-medium hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
            @click="$emit('toPage', totalPages)"
          >
            {{ totalPages }}
          </button>
          <button
            v-if="currentPage !== totalPages"
            type="button"
            class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md text-sm leading-5 font-medium hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
            @click="$emit('next')"
          >
            <font-awesome-icon
              icon="chevron-right"
              class="fa-sm text-gray-500"
            />
          </button>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    totalPages: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 0,
    },
    maxResult: {
      type: [Number, String],
      default: 7,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    layout: {
      type: String,
      default: "list",
    },
    mediaLayout: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showNextButton: true,
      showPrevButton: true,
      showAmount: 7,
    };
  },
  computed: {
    displayPages() {
      const totalPages = this.totalPages;
      let currentPage = this.currentPage;

      if (totalPages > 5) {
        if ([1, 2].includes(currentPage)) {
          currentPage = 3;
        } else if ([totalPages - 1, totalPages].includes(currentPage)) {
          currentPage = totalPages - Math.trunc(5 / 2);
        }
        return [...Array(5).keys()].map(
          (i) => i - Math.trunc(5 / 2) + currentPage,
        );
      } else {
        return totalPages;
      }
    },
  },
  watch: {
    showAmount: {
      handler(val) {
        this.$emit("changeAmountToShow", val);
        this.$emit("toPage", 1);
      },
      deep: true,
    },
  },
  mounted() {
    this.newLayout = this.layout;
    if (this.maxResult) {
      this.showAmount = this.maxResult;
    }
  },
};
</script>

<style></style>
