import Repository from "./Repository";
const resource = "/api/v3/customers";

export default {
  getCustomers(params) {
    if (params) {
      return Repository.get(`${resource}${params}`);
    } else {
      return Repository.get(`${resource}`);
    }
  },

  getCustomer(id) {
    return Repository.get(`${resource}/${id}?include=users,dealer`);
  },

  updateCustomer(payload) {
    const customer = {
      name: payload.name,
      client_id: payload.client_id,
      start_date: payload.start_date,
      stop_date: payload.stop_date,
      enabled: payload.enabled,
      dealer_id: payload.dealer.data.id,
      demo: payload.demo ? payload.demo : false,
      activation_code: payload.activation_code,
    };
    return Repository.patch(`${resource}/${payload.id}`, {
      customer: customer,
    });
  },

  createCustomer(payload) {
    const customer = payload;
    customer.start_date = payload.start_date;
    customer.stop_date = payload.stop_date;
    customer.demo = payload.demo ? payload.demo : false;
    return Repository.post(`${resource}`, {
      customer,
    });
  },

  deleteCustomer(payload) {
    return Repository.delete(`${resource}/${payload}`);
  },
};
