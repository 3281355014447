import Vue from "vue";
import axios from "axios";
import router from "@/router";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import * as Sentry from "@sentry/browser";
// import { Integrations } from "@sentry/tracing";
// import { sentryDSN } from "@/config";
//
// Sentry.init({
//   dsn: sentryDSN,
//   integrations: [
//     new Integrations.BrowserTracing({
//       app,
//       attachProps: true,
//       logErrors: true,
//     }),
//   ],
//   blacklistUrls: "/^.*(localhost).*$/gi",
// });
const baseDomain =
  process.env.VUE_APP_API_BASE_URL || process.env.VUE_APP_DEFAULT_API_BASE_URL;
const baseURL = `${baseDomain}`;
const axiosInstance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Accept-Language": "en_US",
  },
});

const refreshAuthLogic = (failedRequest) => {
  return axios
    .post(`${baseDomain}api/token/refresh`, {
      refresh_token: localStorage.getItem("refresh-user-token"),
    })
    .then((tokenRefreshResponse) => {
      localStorage.setItem("user-token", tokenRefreshResponse.data.token);
      failedRequest.response.config.headers["Authentication"] =
        "Bearer " + tokenRefreshResponse.data.token;
      return Promise.resolve();
    })
    .catch((err) => {
      localStorage.removeItem("user-token");
      localStorage.removeItem("refresh-user-token");
      router.push("/login");
    });
};

createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("user-token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    if (error.response && error.response.status >= 500) {
      Sentry.captureException(new Error(error.response.data.message));
      router.push({
        name: "error500",
        params: { error: error.response.status },
      });
    }
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      if (!localStorage.getItem("refresh-user-token")) {
        localStorage.removeItem("user-token");
        localStorage.removeItem("refresh-user-token");
        router.push("/login");
      }
    }

    if (error.response && error.response.status === 403) {
      router.push({
        name: "error403",
        params: { error: error.response.status },
      });
    }

    if (error.response && error.response.status >= 500) {
      Sentry.captureException(new Error(error.response.data.message));
      router.push({
        name: "error500",
        params: { error: error.response.status },
      });
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
