<template>
  <div
    class="w-full flex flex-col justify-center items-center p-60 backgroundCard"
  >
    <font-awesome-icon :icon="icon" class="fa-7x text-lightAlineaColor" />
    <p class="text-lightAlineaColor text-22 font-medium pt-10">
      {{ text.toUpperCase() }}
    </p>
    <p
      class="text-lightAlineaColor text-20 font-normal mt-10 text-center w-400"
    >
      {{ subtext }}
    </p>
  </div>
</template>

<script>
export default {
  name: "ListPlaceholder",
  props: {
    icon: {
      type: String,
      default: "list",
    },
    text: {
      type: String,
      default: "No items to show!",
    },
    subtext: {
      type: String,
      default: "",
    },
  },
};
</script>

<style></style>
