<template>
  <div :ref="id" class="flex flex-row mb-1">
    <StaggerRender :id="id" width="w-350" :render-on-idle="true">
      <div
        v-click-outside="disableSelected"
        class="flex flex-col cursor-pointer"
        :class="[kind === 'nonLinear' ? 'w-full' : 'w-350']"
      >
        <div :class="{ 'border-red-600 border-2': errorState }">
          <div
            :class="[bgColor, borderColor, opacity]"
            class="flex flex-col p-0.5 border-2 items-end"
            @click="
              toggleCollapse();
              setActive();
            "
          >
            <div class="flex flex-row w-full">
              <div
                v-if="size === 'L'"
                class="w-3 flex items-center justify-center border-r-2 border-transparent"
                @click.stop="collapsed = !collapsed"
              >
                <font-awesome-icon
                  icon="caret-down"
                  class="text-white text-15 rotate"
                  :class="this.rotate"
                />
              </div>

              <VTooltip
                :offset="[0, 8]"
                :triggers="['hover']"
                placement="top"
                boundary="window"
              >
                <div class="flex flex-1 items-center justify-between">
                  <p
                    class="text-white ml-2"
                    :class="[
                      { truncate: !showFullTitles },
                      { 'w-300': !showFullTitles },
                    ]"
                  >
                    {{ title }}
                  </p>
                </div>
                <template #popper>
                  <h2 class="popover-header text-lg -mt-2">
                    {{ title }}
                  </h2>
                  <div class="h-0.5 w-full bg-gray-400 mb-3"></div>
                  <div class="popover-body flex flex-row">
                    <div class="">
                      <p>{{ `${$t("editor.repeatable")}:` }}</p>
                      <p>{{ `${$t("editor.non_linear_random")}:` }}</p>
                      <p>{{ `${$t("editor.max_score")}:` }}</p>
                      <p>{{ `${$t("editor.remark")}:` }}</p>
                    </div>
                    <div class="ml-3">
                      <p>{{ repeatable }}</p>
                      <p>{{ nonLinearRandom }}</p>
                      <p>{{ maxScore }}</p>
                      <p>{{ remark }}</p>
                    </div>
                  </div>
                </template>
              </VTooltip>
            </div>
            <div class="flex px-2 text-white pt-1 pb-1">
              <div
                class="flex flex-auto justify-end"
                :class="[{ 'w-full mt-1 mb-1': showFullTitles }]"
              >
                <font-awesome-icon
                  v-tooltip="{
                    content: $t('shortcuts.duplicate_step'),
                    boundary: 'window',
                  }"
                  icon="clone"
                  class="mr-2 text-white hover:text-gray-200 text-15 outline-none hover:opacity-75 cursor-pointer"
                  @click.stop="duplicateStep"
                />
                <font-awesome-icon
                  v-tooltip="{
                    content: $t('editor.export_step'),
                    boundary: 'window',
                  }"
                  icon="download"
                  class="mr-2 text-white hover:text-gray-200 text-15 outline-none hover:opacity-75 cursor-pointer"
                  @click.stop="toggleExportModal"
                />
                <div :class="newEnabled ? '' : 'hidden'" class="-mt-1">
                  <font-awesome-icon
                    v-tooltip="{
                      content: $t(
                        'editor.required_this_is_a_required_step_to_execute',
                      ),
                      boundary: 'window',
                    }"
                    icon="check"
                    class="text-white hover:text-gray-200 text-15 outline-none hover:opacity-75 cursor-pointer"
                    :class="{ 'opacity-25': !newRequired }"
                    @click.stop="toggle('required')"
                  />
                  <font-awesome-icon
                    v-tooltip="{
                      content: $t(
                        'editor.opportunity_the_player_will_be_rewarded_with_points_when_finishing_this_step',
                      ),
                      boundary: 'window',
                    }"
                    icon="exclamation"
                    class="ml-2 text-white hover:text-gray-200 text-14 outline-none hover:opacity-75 cursor-pointer"
                    :class="{ 'opacity-25': !newOpportunity }"
                    @click.stop="toggle('opportunity')"
                  />
                  <font-awesome-icon
                    v-tooltip="{
                      content: $t(
                        'editor.correct_behavior_the_player_will_be_rewarded_with_score_when_finishing_this_step_otherwise_the_player_will_lose_the_score',
                      ),
                      boundary: 'window',
                    }"
                    icon="lightbulb"
                    class="ml-2 text-white outline-none hover:text-gray-200 text-15 outline-none hover:opacity-75 cursor-pointer"
                    :class="{ 'opacity-25': !newIntended }"
                    @click.stop="toggle('intended')"
                  />
                  <font-awesome-icon
                    v-tooltip="{
                      content: $t('editor.is_on_checklist'),
                      boundary: 'window',
                    }"
                    icon="bullseye"
                    class="ml-2 text-white hover:text-gray-200 text-14 outline-none hover:opacity-75 cursor-pointer"
                    :class="{ 'opacity-25': !newOnChecklist }"
                    @click.stop="toggle('onCheckList')"
                  />
                </div>
                <font-awesome-icon
                  v-tooltip="{
                    content: newEnabled
                      ? $t('editor.disable')
                      : $t('editor.enable'),
                    boundary: 'window',
                  }"
                  :icon="newEnabled ? 'eye' : 'eye-slash'"
                  class="ml-2 text-white outline-none hover:text-gray-200 text-15 mr-1 outline-none"
                  @click.stop="toggle('enable')"
                />
                <ErrorPopup
                  v-if="errorState"
                  :title="$t('editor.this_step_contains_an_error')"
                  class="flex items-center justify-center -mb-2 -mt-1"
                  size="m"
                />
              </div>
            </div>
          </div>
          <div
            v-if="collapsed && size === 'L'"
            :class="[borderColor, borderOpacity]"
            class="flex flex-col border-2 border-t-1 cursor-pointer"
          >
            <draggable
              class="h-100 w-full overflow-y-hidden"
              :class="opacity"
              :list="actionList"
              group="actions"
              :id="id"
              :disabled="false"
              @end="actionChanged($event)"
              item-key="id"
            >
              <template #item="{ element, index }">
                <Action
                  :key="element.id"
                  :index="index"
                  :id="element.id"
                  :kind="kind"
                  :enabled="element.enabled"
                  :sort="element.sequence"
                  :name="element.name"
                  :step-id="stepId"
                  :lesson-id="activeLesson.id"
                  :error-state="element.error_state"
                  :icon="
                    element.unity_package_action?.data.unity_package_action_type
                      .data.icon
                  "
                  :enabled-list="enabledList"
                  size="L"
                  :selected="selected"
                  @setActiveStep="setActive"
                  @toggleCollapse="toggleCollapse"
                  @toggleEnabled="toggleEnabled"
                />
              </template>
            </draggable>
            <div class="flex w-full cursor-pointer py-1">
              <div
                class="relative inline-block text-right text-white text-15 cursor-pointer h-100 ml-6"
              >
                <OptionMenu
                  icon="plus"
                  icon-style="s"
                  position="left"
                  color="text-gray-50"
                  :text="
                    actions.length
                      ? $t('editor.add_action')
                      : $t('editor.add_first_action')
                  "
                  :options="optionsAction"
                  @addAction="addAction()"
                  @pasteAction="pasteAction"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ActionSelectModal
        v-if="showActionSelector"
        :step-id="stepId"
        @close="showActionSelector = false"
      />
      <ExportModal
        v-if="showExportModal"
        :header-text="$t('editor.export_step')"
        :sub-header-text="$t('editor.export_this_step_as_json')"
        target="step"
        :step-id="stepId"
        :lesson-id="activeLesson.id"
        @close="toggleExportModal"
      />
    </StaggerRender>
  </div>
</template>

<script>
import { mapState } from "vuex";
import draggable from "vuedraggable";
import Action from "../Action";
import ActionSelectModal from "../modals/ActionSelectModal";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const stepRepository = RepositoryFactory.get("step");
import ErrorPopup from "@/views/editor/components/modals/ErrorPopup";
import sortStep from "@/mixins/sortStep";
import sortAction from "@/mixins/sortAction";
import scrollToSearchResult from "@/mixins/scrollToSearchResult";
import OptionMenu from "@/components/helpers/OptionMenu";
import ExportModal from "@/views/editor/components/modals/ExportModal";
import StaggerRender from "@/helpers/StaggerRender";
export default {
  name: "NonLinearStep",
  components: {
    draggable,
    Action,
    ActionSelectModal,
    ErrorPopup,
    OptionMenu,
    ExportModal,
    StaggerRender,
  },
  mixins: [sortStep, sortAction, scrollToSearchResult],
  props: {
    kind: {
      type: String, // setup || listener || nonLinear || linear
      default: "setup",
    },
    size: {
      type: String, // toolbox == S || expanded row = L || else M
      default: "L",
    },
    title: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    sort: {
      type: Number,
      default: 0,
    },
    intended: {
      type: Boolean,
      default: false,
    },
    opportunity: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    enabled: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Array,
      default: () => [],
    },
    stepId: {
      type: String,
      required: true,
    },
    maxScore: {
      type: Number,
      default: 0,
    },
    nonLinearRandom: {
      type: Number,
      default: 0,
    },
    repeatable: {
      type: Number,
      default: 0,
    },
    remark: {
      type: String,
      default: "",
    },
    onChecklist: {
      type: Boolean,
      default: false,
    },
    errorState: {
      type: Boolean,
      default: false,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: false,
      showActionSelector: false,
      disabled: false,
      collapsed: false,
      rotate: "up",
      newEnabled: false,
      newRequired: false,
      newOpportunity: false,
      newIntended: false,
      newOnChecklist: false,
      showExportModal: false,
    };
  },

  computed: {
    ...mapState("editorStore", [
      "activeLesson",
      "mainSteps",
      "activeStep",
      "activeType",
    ]),
    ...mapState("keyboardShortcutStore", [
      "showFullTitles",
      "collapseAll",
      "clipboard",
    ]),
    optionsAction() {
      return [
        { option: "addAction", name: "Add new action" },
        {
          option: "pasteAction",
          name: "Paste action",
          disabled: this.clipboard.kind !== "action",
        },
      ];
    },
    opacity: function () {
      if (this.activeStep.id === this.id) {
        return "opacity-100";
      } else {
        return "opacity-60";
      }
    },
    bgColor: function () {
      if (!this.newEnabled && this.activeStep.id !== this.id) {
        return "bg-gray-600";
      } else if (!this.newEnabled && this.activeStep.id === this.id) {
        return "bg-activeAction";
      } else if (
        this.newEnabled &&
        this.activeStep.id === this.id &&
        this.activeType === "step"
      ) {
        return "bg-activeAction";
      } else {
        return "bg-nonLinearBlue";
      }
    },
    borderColor: function () {
      if (!this.newEnabled && this.activeStep.id !== this.id) {
        return "border-gray-600";
      } else if (!this.newEnabled && this.activeStep.id === this.id) {
        return "border-activeAction";
      } else if (
        this.newEnabled &&
        this.activeStep.id === this.id &&
        this.activeType === "step"
      ) {
        return "border-activeAction";
      } else {
        return "border-nonLinearBlue";
      }
    },
    enabledList: function () {
      return this.actionList.map((action) => action.enabled);
    },
    actionList: function () {
      return this.actions;
    },
    borderOpacity: function () {
      if (this.activeStep.id !== this.id) {
        return "border-opacity-60";
      } else {
        return "";
      }
    },
  },
  watch: {
    collapsed: {
      handler() {
        if (!this.collapsed) {
          this.rotate = "up";
        } else {
          this.rotate = "down";
        }
      },
      deep: true,
    },
    collapseAll: {
      handler(val) {
        if (this.isVisible || this.kind === "setup") {
          if (val === 0) {
            this.collapsed = false;
          } else if (val === 2) {
            this.collapsed = true;
          }
        }
      },
      deep: true,
    },
    enabled: {
      handler(val) {
        this.newEnabled = val;
      },
      deep: true,
    },
    required: {
      handler(val) {
        this.newRequired = val;
      },
      deep: true,
    },
    opportunity: {
      handler(val) {
        this.newOpportunity = val;
      },
      deep: true,
    },
    intended: {
      handler(val) {
        this.newIntended = val;
      },
      deep: true,
    },
    onChecklist: {
      handler(val) {
        this.newOnChecklist = val;
      },
      deep: true,
    },
  },
  mounted() {
    this.newEnabled = this.enabled;
    this.newRequired = this.required;
    this.newOpportunity = this.opportunity;
    this.newIntended = this.intended;
    this.newOnChecklist = this.onChecklist;
  },
  methods: {
    updateValue(e) {
      this.$emit("update:modelValue", e.target.value);
    },
    pasteAction(type) {
      this.$store.dispatch(
        "keyboardShortcutStore/PASTE_FROM_CLIPBOARD",
        this.id,
      );
    },
    setActive() {
      this.$emit("disableSelected");
      this.selected = true;
      this.$store.dispatch("editorStore/SET_ACTIVE_STEP", {
        id: this.id,
        kind: this.kind,
        type: "edit",
        sort: this.sort,
        activeType: "step",
        actionAmount: this.actions.length,
      });
      this.$store.dispatch("keyboardShortcutStore/TOGGLE_COLLAPSE_ALL", 1);
      this.$store.dispatch("editorStore/GET_CHAPTER_TREE");
    },
    addAction() {
      this.setActive();
      this.showActionSelector = true;
    },
    toggle(arg) {
      let payload;

      switch (arg) {
        case "enable":
          payload = { enabled: !this.newEnabled };
          this.newEnabled = !this.newEnabled;
          break;
        case "required":
          payload = { is_required: !this.newRequired };
          this.newRequired = !this.newRequired;
          break;
        case "opportunity":
          payload = { is_opportunity: !this.newOpportunity };
          this.newOpportunity = !this.newOpportunity;
          break;
        case "intended":
          payload = { is_intended: !this.newIntended };
          this.newIntended = !this.newIntended;
          break;
        case "onCheckList":
          payload = { add_to_checklist: !this.newOnChecklist };
          this.newOnChecklist = !this.newOnChecklist;
          break;
      }

      stepRepository
        .editStep(this.activeLesson.id, payload, this.id)
        .then(() => {
          this.$store.dispatch("editorStore/GET_CHAPTER_TREE");
          this.$store.dispatch("editorStore/SET_ACTIVE_STEP", {
            id: this.id,
            kind: this.kind,
            type: "edit",
            activeType: "step",
          });
        });
    },
    toggleEnabled(index) {
      this.actionList[index].enabled = !this.actionList[index].enabled;
    },
    toggleCollapse() {
      if (this.id === this.activeStep.id && this.activeType === "step") {
        this.collapsed = !this.collapsed;
      } else if (this.id !== this.activeStep.id && !this.collapsed) {
        this.collapsed = !this.collapsed;
      }
    },
    duplicateStep() {
      this.$store.dispatch("keyboardShortcutStore/DUPLICATE_STEP", {
        lessonId: this.activeLesson.id,
        stepId: this.id,
        newSort: this.sort,
      });
    },
    disableSelected() {
      this.selected = false;
    },
    toggleExportModal() {
      this.showExportModal = !this.showExportModal;
    },
  },
};
</script>
