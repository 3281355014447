export default {
  data() {
    return {
      currentPage: 1,
      totalPages: 1,
      totalEntries: null,
      currentSort: "name",
      currentSortDir: "ASC",
      search: "",
      debounce: null,
    };
  },

  watch: {
    search: {
      handler(val) {
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          this.params.page = 1;
          this.currentPage = 1;
          this.getEntries();
        }, 600);
      },
      deep: true,
    },
  },

  methods: {
    nextPage() {
      if (!(parseInt(this.params.page) >= this.totalPages)) {
        this.loading = true;
        this.params.page = (parseInt(this.params.page) + 1).toString();
        this.currentPage = parseInt(this.params.page);
        this.getEntries();
      }
    },
    previousPage() {
      if (this.params.page && this.params.page >= 2) {
        this.loading = true;
        this.params.page = (parseInt(this.params.page) - 1).toString();
        this.currentPage = parseInt(this.params.page);
        this.getEntries();
      }
    },
    toPage(p) {
      this.loading = true;
      this.params.page = p.toString();
      this.currentPage = p;
      this.getEntries();
    },
    sort(s) {
      this.currentSortDir = this.currentSortDir === "ASC" ? "DESC" : "ASC";
      this.params.sort = this.currentSortDir;

      this.currentSort = s;
      this.params.sortfield = this.currentSort;

      this.getEntries();
    },
    changeAmountToShow(val) {
      this.params.maxResult = val.toString();
      this.setMaxResults(val);
      this.getEntries();
    },
    searchAction(s) {
      this.search = s;
      this.params.query = s;
    },
  },
};
