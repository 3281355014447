import { createStore } from "vuex";
import authStore from "./modules/authStore";
import userStore from "./modules/userStore";
import trainingStore from "./modules/trainingStore";
import editorStore from "./modules/editorStore";
import notificationStore from "./modules/notificationStore";
import modalStore from "./modules/modalStore";
import translationStore from "@/store/modules/translationStore";
import searchStore from "@/store/modules/searchStore";
import keyboardShortcutStore from "@/store/modules/keyboardShortcutStore";

const store = createStore({
  namespaced: true,
  modules: {
    authStore,
    userStore,
    trainingStore,
    editorStore,
    notificationStore,
    modalStore,
    translationStore,
    searchStore,
    keyboardShortcutStore,
  },
});

export default store;
