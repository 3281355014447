<template>
  <td class="px-6 py-4 align-top overflow-auto">
    <div class="w-300 flex flex-col">
      <template v-if="$route.name === 'gameTranslations'">
        <InputField
          v-if="mediaType === 'None'"
          class="mb-3"
          :title="$t('translation.content')"
          v-model="newProductField.content"
          :blur="true"
          rules="min:1|max:255"
          @updateField="saveProductField"
        />
        <InputField
          :title="$t('translation.description')"
          v-model="newProductField.description"
          :blur="true"
          rules="min:1|max:255"
          @updateField="saveProductField"
        />
      </template>
      <template v-if="$route.name === 'lessonTranslations'">
        <div
          v-if="newProductField.unity_package_action_property"
          class="mt-1 relative rounded-md shadow-sm"
        >
          <span
            class="text-left text-sm leading-4 font-bold text-gray-100 tracking-wider"
          >
            {{ `${$t("translation.description")}` }}
          </span>
          <p
            class="text-left text-sm leading-4 font-normal text-gray-100 tracking-wider mt-2"
          >
            {{ newProductField.unity_package_action_property.data.description }}
          </p>
          <p
            v-if="required"
            class="text-left text-sm leading-4 font-bold text-gray-100 tracking-wider mt-4"
          >
            {{ $t("general.required") }}
          </p>
        </div>
      </template>
    </div>
  </td>
</template>

<script>
import InputField from "@/views/editor/components/inputs/InputField";

export default {
  name: "EditProductField",
  components: { InputField },
  props: {
    productField: {
      type: Object,
      required: true,
    },
    mediaType: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newProductField: {},
    };
  },
  watch: {
    productField: {
      handler(val) {
        this.newProductField = val;
      },
      deep: true,
    },
  },
  mounted() {
    this.newProductField = this.productField;
  },
  methods: {
    saveProductField() {
      this.$emit("edit", this.newProductField);
    },
  },
};
</script>

<style></style>
