<template>
  <div class="m-10 p-5 backgroundCard text-lightAlineaColor changelog">
    <!--    <CHANGELOG></CHANGELOG>-->
  </div>
</template>

<script>
// import CHANGELOG from "./../../CHANGELOG.md";

export default {
  name: "App",
  // components: { CHANGELOG },
  data: function () {
    return {
      fileContent: null,
    };
  },
  created: function () {},
  // methods: {
  //   getContent() {
  //     this.fileContent = CHANGELOG;
  //   }
  // }
};
</script>

<style>
.changelog > section > h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.changelog > section > h2 {
  font-size: 2rem;
  margin-bottom: 0.4rem;
}

.changelog > section > ul {
  list-style: circle;
  padding-left: 30px;
}
</style>
