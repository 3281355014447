<template>
  <div class="flex flex-row items-center">
    <SearchBar @search="searching" />
    <div class="flex items-center ml-3">
      <template v-if="$route.name === 'gameTranslations'">
        <span class="text-white mr-3">{{ $t("translation.category") }}: </span>
        <v-select
          class="mediaselect w-48 h-10 text-gray-800"
          v-model="category"
          :options="categoryOptions"
          label="name"
          :clearable="false"
          :reduce="(cat) => cat.name"
          @update:modelValue="changeCategory"
        ></v-select>
      </template>
      <span class="text-white mr-3 ml-3">{{ $t("translation.type") }}:</span>
      <v-select
        class="mediaselect w-56 h-10 text-gray-800"
        v-model="type"
        :options="typesOptions"
        label="name"
        :clearable="false"
        @update:modelValue="changeType"
      >
        <template #selected-option="option">
          {{ $t(`translation.${option.name}`) }}
        </template>
        <template #option="option">
          {{ $t(`translation.${option.name}`) }}
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import SearchBar from "@/components/helpers/SearchBar";

export default {
  name: "MediaTranslations",
  components: {
    SearchBar,
  },
  props: {
    selectedCategory: {
      type: String,
      default: "",
    },
    categoryOptions: {
      type: Array,
      required: true,
    },
    selectedType: {
      type: String,
      default: "",
    },
    typesOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      category: "",
      type: "",
    };
  },
  watch: {
    selectedCategory: {
      handler(val) {
        this.category = val;
      },
      deep: true,
    },
    selectedType: {
      handler(val) {
        this.type = val;
      },
      deep: true,
    },
  },
  mounted() {
    this.category = this.selectedCategory;
    this.type = this.selectedType;
    this.changeType(this.typesOptions[0]);
  },
  methods: {
    searching(input) {
      this.$emit("searching", input);
    },
    changeType(input) {
      this.$emit("changeType", input);
    },
    changeCategory(input) {
      this.$emit("changeCategory", input);
    },
  },
};
</script>

<style>
.mediaselect {
  color: black;
}
.mediaselect .vs__dropdown-toggle,
.mediaselect .style-chooser__title,
.mediaselect .vs-state-active-color,
.mediaselect .vs-dropdown-bg {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  color: black;
  font-size: 0.875rem;
  border-radius: 3rem !important;
  padding: 0.4rem 0.4rem 0.4rem;
  width: 100%;
}
.mediaselect .vs__dropdown-option {
  --tw-bg-opacity: 1;
  font-size: 0.875rem;
  padding: 0.4rem 0.4rem 0.4rem;
  width: 100%;
}
.mediaselect .vs__dropdown-menu {
  margin-top: 0.5rem;
  border-radius: 1rem;
}
.mediaselect .vs__selected {
  color: black;
}
.mediaselect .vs__clear,
.mediaselect .vs__open-indicator {
  fill: grey;
}
</style>
