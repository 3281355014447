<template>
  <PageContentContainer>
    <div class="grid grid-cols-1 gap-6">
      <div class="backgroundRow overflow-hidden shadow sm:rounded-md">
        <div class="px-4 py-5 sm:p-6">
          <Form
            v-if="dealer"
            @keypress.enter="updateDealer"
            @submit="updateDealer"
            v-slot="{ errors }"
          >
            <InputField
              class="mt-inputPadding w-full"
              :title="$t('customers.name')"
              rules="required|min:4|max:100"
              :required-input="true"
              type="text"
              placeholder=""
              :light="true"
              v-model="dealer.name"
              id="dealerName"
            />

            <InputField
              class="mt-inputPadding w-full"
              :title="$t('customers.client_id')"
              rules="required"
              :required-input="true"
              type="text"
              placeholder=""
              :light="true"
              v-model="dealer.client_id"
              id="clientID"
            />

            <DatePicker
              v-model="dealer.start_date"
              rules="required"
              :locale="$i18n.locale"
              :required-input="true"
              :title="$t('customers.start_date')"
            />

            <DatePicker
              v-model="dealer.stop_date"
              :locale="$i18n.locale"
              :title="$t('customers.stop_date')"
            />

            <!-- </div> -->

            <ToggleButton
              :title="$t('general.enabled')"
              :val="dealer.enabled"
              :light="true"
              @update="dealer.enabled = !dealer.enabled"
              id="dealerEnabled"
            />

            <ToggleButton
              v-if="showOwner"
              class="mt-4"
              :title="$t('customers.owner')"
              :val="dealer.owner"
              :light="true"
              @update="dealer.owner = !dealer.owner"
              id="dealerOwner"
            />

            <div class="mt-8 pt-5">
              <div class="flex justify-end">
                <Button
                  class="mr-3"
                  type="submit"
                  :invalid="Object.keys(errors).length !== 0"
                  button-type="lightPrimary"
                  :title="$t('editor.save')"
                  id="saveDealer"
                />
                <Button
                  button-type="lightSecondary"
                  :title="$t('customers.cancel')"
                  @clickAction="
                    $router.push({
                      name: 'dealers',
                    })
                  "
                  cy="cancelEdit"
                />
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </PageContentContainer>
</template>

<script>
import PageContentContainer from "@/components/helpers/PageContentContainer";
import ToggleButton from "@/components/form/ToggleButton";
import Button from "@/components/form/Button";
import InputField from "@/components/form/InputField";
import DatePicker from "@/components/form/DatePicker";
import { showNotification } from "@/services/notificationService";
import { errorHandlingAndMessage } from "@/services/ErrorService";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const dealerRepository = RepositoryFactory.get("dealer");
import { mapState } from "vuex";

export default {
  components: {
    PageContentContainer,
    Button,
    InputField,
    ToggleButton,
    DatePicker,
  },
  props: {
    create: {
      default: false,
      type: Boolean,
    },
    id: {
      type: [String, Number],
      default: "",
    },
  },
  data: () => {
    return {
      mode: "single",
      dealer: null,
    };
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
    showOwner: function () {
      return this.currentUser.roles.includes("ROLE_SUPER_ADMIN");
    },
  },
  watch: {
    dealer: {},
  },
  created() {
    if (this.id) {
      dealerRepository
        .getDealer(this.id)
        .then((result) => {
          this.dealer = result.data.data;
        })
        .catch((err) => {
          this.$router.push({
            name: "dealers",
          });
        });
    }
  },
  methods: {
    updateDealer() {
      this.dealer.start_date = this.dealer.start_date
        ? this.dealer.start_date
        : this.dealer.startdate;
      this.dealer.stop_date = this.dealer.stop_date
        ? this.dealer.stop_date
        : this.dealer.stopdate;
      dealerRepository
        .updateDealer(this.dealer)
        .then(() => {
          showNotification(
            this.$t("notifications.dealer_edited"),
            this.$t("notifications.dealer_is_successfully_edited"),
            "success",
          );
          this.$router.push({
            name: "dealers",
          });
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t("notifications.something_went_wrong_updating_this_dealer"),
          );
        });
    },
  },
};
</script>

<style></style>
