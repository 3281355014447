export default {
  created() {
    window.addEventListener("keydown", (e) => {
      if (e.keyCode === 27) {
        e.preventDefault();
        this.$emit("close");
      }
    });
  },
};
