<template>
  <div>
    <div
      class="backgroundCard flex w-full flex-col justify-start justify-items-start desktop:mr-30 mobile:my-5"
    >
      <div class="mb-5">
        <DatePicker
          :range="selectedRange"
          :locale="$i18n.locale"
          @update:range="updateSelectedRange"
        />
      </div>
    </div>
    <div v-if="loading" class="flex justify-center flex-col items-center h-550">
      <Loader />
      <p class="text-17 font-medium text-white leading-none mt-6">
        {{ $t("reporting.loading_content") }}...
      </p>
    </div>
    <div v-else class="flex flex-col backgroundCard mt-15 w-full">
      <highcharts class="pt-30" :options="chartOptions"></highcharts>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import { format, startOfMonth } from "date-fns";
import { chartStylingOptions } from "@/config";
import Loader from "@/components/helpers/Loader";
import DatePicker from "@/components/reporting/DatePicker";
import { monthIndexes } from "@/helpers/dateHelpers";
import i18n from "@/i18n";
const usersRepository = RepositoryFactory.get("user");
const reportingRepository = RepositoryFactory.get("reporting");

export default {
  components: {
    highcharts: Chart,
    DatePicker,
    Loader,
  },
  data() {
    return {
      selectedRange: {
        start: startOfMonth(new Date().setDate(-20)),
        end: new Date(),
      },
      userData: [],
      gameData: [],
      sortedSeries: { one: null, two: null },
      loading: true,
    };
  },
  computed: {
    chartOptions: function () {
      return {
        ...chartStylingOptions,
        title: {
          text: "",
        },
        chart: {
          backgroundColor: "#ffffff00",
          type: ["column"],
          marginRight: 40,
          animation: false,
        },
        series: [
          {
            name: this.$t("reporting.user_playtime"),
            type: "column",
            color: "#7cb5ec",
            borderWidth: 0,
            data: this.sortedSeries.one,
            dataLabels: {
              enabled: true,
              color: "#fff",
              crop: false,
              overflow: "none",
              inside: false,
              x: -5,
            },
          },
          {
            type: "spline",
            name: this.$t("reporting.total_playtime"),
            color: "#7cb5ec",
            borderWidth: 0,
            data: this.sortedSeries.two,
            dataLabels: {
              style: {
                color: "white",
                fontSize: "11px",
                fontWeight: "medium",
                textOutline: `1px ${process.env.VUE_APP_SECONDARY_COLOR}`,
              },
              enabled: true,
              crop: false,
              overflow: "none",
              inside: false,
            },
          },
        ],
        xAxis: {
          type: "datetime",
          lineColor: "white",
          gridLineColor: "black",
          labels: {
            style: {
              color: "#ffffff",
            },
            formatter: function () {
              return Highcharts.dateFormat("%b %Y", this.value);
            },
          },
        },
        yAxis: {
          type: "datetime",
          gridLineColor: "#ffffff00",
          title: {
            text: this.$t("reporting.hours"),
            style: {
              color: "white",
            },
          },
          labels: {
            style: {
              color: "#ffffff",
            },
            formatter: function () {
              const hours = parseInt(
                this.value / 3600000 < 0 ? 0 : this.value / 3600000,
              );
              return `${hours.toLocaleString(
                i18n.global.locale.value,
              )}${i18n.global.t("reporting.h")}`;
            },
          },
        },
        tooltip: {
          formatter: function () {
            const hours = parseInt(this.y / 3600000);
            const mins = parseInt(parseInt(this.y % 3600000) / 60000);
            if (this.series.type === "spline") {
              return (
                i18n.global.t("reporting.total_playtime") +
                " " +
                hours.toLocaleString(i18n.global.locale.value) +
                " " +
                i18n.global.t("reporting.hours") +
                " " +
                (mins < 10 ? "0" : "") +
                mins.toLocaleString(i18n.global.locale.value) +
                " " +
                i18n.global.t("reporting.minutes")
              );
            } else {
              return (
                i18n.global.t("reporting.user_playtime") +
                hours.toLocaleString(i18n.global.locale.value) +
                " " +
                i18n.global.t("reporting.hours") +
                " " +
                (mins < 10 ? "0" : "") +
                mins.toLocaleString(i18n.global.locale.value) +
                " " +
                i18n.global.t("reporting.minutes")
              );
            }
          },
        },
        plotOptions: {
          series: {
            dataLabels: {
              formatter: function () {
                const hours = parseInt(this.y / 3600000);
                const mins = parseInt((this.y % 3600000) / 60000);
                return (
                  `${hours.toLocaleString(
                    i18n.global.locale.value,
                  )}${i18n.global.t("reporting.h")}` +
                  " " +
                  `${mins.toLocaleString(
                    i18n.global.locale.value,
                  )}${i18n.global.t("reporting.m")}`
                );
              },
            },
          },
        },
      };
    },
    ...mapState("userStore", ["currentUser"]),
  },
  watch: {
    selectedRange: {
      handler() {
        this.gameData = [];
        this.userData = [];
        this.loading = true;
        this.fetch();
      },
      deep: true,
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      usersRepository
        .getMonthlyUsagePeriodForUser(
          this.currentUser.id,
          format(this.selectedRange.start, "yyyy-MM-dd"),
          format(this.selectedRange.end, "yyyy-MM-dd"),
        )
        .then((result) => {
          this.createSeries(result.data.total, this.userData, 0);
        });

      reportingRepository
        .getMonthlyUsagePeriod(
          format(this.selectedRange.start, "yyyy-MM-dd"),
          format(this.selectedRange.end, "yyyy-MM-dd"),
        )
        .then((result) => {
          this.createSeries(result.data.total, this.gameData, 1);
          this.loading = false;
        });
    },
    createSeries(result, data, series) {
      result.map((r) => {
        data.push({
          x: new Date(r.Year, monthIndexes[r.Month.toLowerCase()]),
          y: parseInt(r.Seconds) * 1000,
        });
      });
      const sorted = data.sort(function (a, b) {
        return new Date(a.x) - new Date(b.x);
      });

      if (series === 0) {
        this.sortedSeries.one = sorted;
      } else {
        this.sortedSeries.two = sorted;
      }
    },
    updateSelectedRange(range) {
      this.selectedRange = range;
    },
  },
};
</script>
