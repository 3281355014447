import { showNotification } from "@/services/notificationService";
import MediaModal from "@/views/editor/components/modals/mediaModal/MediaModal";
import SelectLocaleModal from "@/components/helpers/modals/SelectLocaleModal";
import ExportModal from "@/views/editor/components/modals/ExportModal";
import { mapState } from "vuex";

export default {
  components: {
    ExportModal,
    MediaModal,
    SelectLocaleModal,
  },
  computed: {
    ...mapState("translationStore", [
      "showImportModal",
      "showExportModal",
      "showTranslationFilesModal",
      "entityName",
      "entityId",
    ]),
  },
  data() {
    return {
      showSelectLocaleModal: false,
      file: "",
      fileName: "",
    };
  },

  methods: {
    showTranslationModal(type, entityName, entityId) {
      this.$store.dispatch("translationStore/SHOW_TRANSLATION_MODAL", {
        type,
        entityName,
        entityId,
      });
    },
    closeTranslationModal(type) {
      this.$store.dispatch("translationStore/CLOSE_TRANSLATION_MODAL", type);
    },
    finishUpload(file) {
      this.file = file;
      this.fileName = this.file[0].name;
      this.closeTranslationModal("import");
      this.toggleSelectLocaleModal();
    },
    toggleSelectLocaleModal() {
      this.showSelectLocaleModal = !this.showSelectLocaleModal;
    },
    finishTranslationImport(locale) {
      const payload = {
        entityId: this.entityId,
        entityName: this.entityName,
        uploaded_file: this.file[0].name,
        locale: locale,
      };
      this.$store
        .dispatch("translationStore/IMPORT_TRANSLATION", payload)
        .then((result) => {
          if (result.status === 202) {
            showNotification(
              this.$t("notifications.translation_import"),
              this.$t("notifications.translation_successfully_imported"),
              "success",
            );

            this.toggleSelectLocaleModal();
            this.refreshData();
          }
        });
    },
    refreshData() {
      if (this.entityName === "product") {
        this.$store.dispatch("trainingStore/GET_TRAININGS");
      }
      if (this.entityName === "category") {
        this.$store.dispatch("trainingStore/GET_CHAPTERS");
      }
      if (this.entityName === "lesson") {
        this.$store.dispatch("editorStore/GET_CHAPTER_TREE");
      }
    },
  },
};
