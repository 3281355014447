import Vue from "vue";
import store from "../store/store";

export function showModal(title, message, type, required) {
  store
    .dispatch("modalStore/SHOW_MODAL", {
      title: title,
      message: message,
      type: type,
      required: required,
    })
    .catch(() => {});
}

export function closeModal() {
  store.dispatch("modalStore/HIDE_MODAL").catch(() => {});
}
