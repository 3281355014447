<template>
  <div
    v-if="!loading"
    class="flex flex-col flex-3 mobile:justify-between mobile:pt-30"
  >
    <div class="flex flex-col">
      <p class="font-medium text-24 text-lightAlineaColor leading-none mb-15">
        {{ $t("results.trophies") }}
      </p>
    </div>
    <div class="flex flex-4 flex-col justify-start">
      <div v-if="trophies.length" class="m-1">
        <div
          v-for="trophy in trophies"
          :key="trophy.id"
          class="flex flex-row justify-start items-center p-2"
        >
          <div class="flex justify-start items-center">
            <font-awesome-icon
              icon="trophy"
              class="text-4xl mt-1 text-gray-50"
            />
          </div>
          <div class="flex flex-col justify-start items-center ml-3">
            <p class="text-gray-50 font-medium text-24">
              {{ trophy.name }}
            </p>
            <p class="text-gray-50 font-light text-16">
              {{ trophy.description }}
            </p>
          </div>
        </div>
      </div>

      <div v-else>
        <div class="flex flex-row justify-start items-center">
          <div class="flex flex-col justify-start items-center">
            <p class="text-gray-50 font-light text-18">
              {{ $t("results.no_trophies") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const lessonRepository = RepositoryFactory.get("lesson");

export default {
  components: {},
  props: {
    lessonId: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    loading: true,
    trophies: [],
  }),
  mounted() {
    if (this.lessonId) {
      this.getLesson();
    } else {
      this.loading = false;
    }
  },
  methods: {
    getLesson() {
      lessonRepository.getLesson(this.lessonId, "trophies").then((result) => {
        this.trophies = result.data.data.trophies.data;
        this.loading = false;
      });
    },
  },
};
</script>

<style></style>
