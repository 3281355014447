<template>
  <div
    class="flex flex-row justify-start"
    @keypress.enter.prevent.stop="search(value)"
  >
    <div class="w-full">
      <label for="search" class="sr-only">{{ $t("general.search") }}</label>
      <div class="relative">
        <div
          class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
        >
          <svg
            class="h-5 w-5 text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fill-rule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <input
          id="search"
          class="block w-full pl-10 pr-3 py-2 border border-gray-300 leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:border-blue-300 focus:ring-blue sm:text-sm transition duration-150 ease-in-out"
          placeholder="Search"
          v-model="value"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MediaSearch",
  data() {
    return {
      value: "",
    };
  },
  watch: {
    value: {
      handler(val) {
        this.search(val);
      },
    },
  },
  methods: {
    search(value) {
      this.$emit("search", value);
    },
  },
};
</script>

<style></style>
