import { RepositoryFactory } from "@/repository/RepositoryFactory";
const AuthRepository = RepositoryFactory.get("auth");

const state = {
  token: localStorage.getItem("user-token") || "",
  refresh_token: localStorage.getItem("user-token") || "",
  status: "",
};

const getters = {
  isAuthenticated(state) {
    return !!state.token;
  },

  authState(state) {
    return state.status;
  },
};

const actions = {
  AUTH_REQUEST: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      commit("AUTH_REQUEST");
      AuthRepository.createUserToken(user)
        .then((resp) => {
          const token = resp.data.token;
          const refresh_token = resp.data.refresh_token;
          localStorage.setItem("user-token", token);
          localStorage.setItem("refresh-user-token", refresh_token);
          commit("AUTH_SUCCESS", token, refresh_token);
          resolve(resp);
        })
        .catch((err) => {
          commit("AUTH_ERROR", err);
          localStorage.removeItem("user-token");
          reject(err);
        });
    });
  },

  AUTH_LOGOUT: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      localStorage.removeItem("user-token");
      localStorage.removeItem("refresh-user-token");
      resolve();
    });
  },

  SET_TOKEN: ({ commit, dispatch }, payload) => {
    commit("AUTH_SUCCESS", payload);
  },
};

const mutations = {
  AUTH_REQUEST: (state) => {
    state.status = "loading";
  },
  AUTH_SUCCESS: (state, token, refresh_token) => {
    state.status = "success";
    state.token = token;
    state.refresh_token = refresh_token;
  },
  AUTH_ERROR: (state) => {
    state.status = "error";
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
