<template>
  <div class="relative flex items-start">
    <div class="flex items-center h-5">
      <input
        :id="title"
        type="checkbox"
        class="form-checkbox h-4 w-4 text-green-500 transition duration-150 ease-in-out"
        :placeholder="placeholder"
        :checked="modelValue"
        @change="$emit('update:modelValue', $event.target.checked)"
      />
    </div>
    <div class="ml-3 text-sm leading-5 flex">
      <label v-if="title" for="comments" class="font-medium text-gray-200">
        {{ title }}
      </label>
      <div
        v-if="hint"
        class="flex justify-center bg-white items-center h-3 w-3 rounded-full mt-1 ml-1"
        v-tooltip="{ content: hint, boundary: 'window' }"
      >
        <p class="text-sidebar font-bold text-9">?</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckBox",
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Placeholder",
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    activeColor: {
      type: String,
      default: "#6b7280",
    },
    hint: {
      type: String,
      default: "",
    },
    rules: {
      type: String,
      default: "",
    },
    inputType: {
      type: String,
      default: "text",
    },
  },
  methods: {
    updateValue(e) {
      this.$emit("update:modelValue", e.target.checked);
    },
  },
};
</script>
