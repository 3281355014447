<template>
  <td class="px-6 py-4 whitespace-nowrap align-top">
    <div
      v-for="(locale, value) in locales"
      :key="value"
      class="w-full block cursor-pointer py-2 px-2 rounded border-transparent font-medium mb-1 text-sm leading-5 text-white hover:text-white hover:bg-gray-600"
      :class="selected === locale.tag ? 'bg-gray-600' : 'bg-transparent'"
      @click="setSelected(locale.tag)"
    >
      <span> {{ locale.description }}</span>
    </div>
  </td>
</template>

<script>
export default {
  name: "TranslationSelector",
  props: {
    locales: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    setSelected(tag) {
      this.$emit("selected", tag);
    },
  },
};
</script>

<style></style>
