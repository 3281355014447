import Repository from "./Repository";
import { format } from "date-fns";
const resource = "/api/v3/dealers";

export default {
  getDealers(params) {
    if (params) {
      return Repository.get(`${resource}${params}`);
    } else {
      return Repository.get(`${resource}`);
    }
  },

  async getDealer(id) {
    const dealers = await Repository.get(`${resource}/${id}?include=users`);
    return dealers;
  },

  updateDealer(payload) {
    const dealer = {
      name: payload.name,
      client_id: payload.client_id,
      start_date: payload.start_date,
      stop_date: payload.stop_date,
      enabled: payload.enabled,
      owner: payload.owner,
    };
    return Repository.patch(`${resource}/${payload.id}`, {
      dealer,
    });
  },

  createDealer(payload) {
    const dealer = payload;
    dealer.start_date = payload.start_date;
    dealer.stop_date = payload.stop_date;
    return Repository.post(`${resource}`, {
      dealer,
    });
  },

  deleteDealer(payload) {
    return Repository.delete(`${resource}/${payload}`);
  },
};
