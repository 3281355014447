import Repository from "./Repository";
const resource = "/api/v3/trainings";

export default {
  index(id) {
    return Repository.get(`${resource}/${id}/gameplay`);
  },
  create(id, versionId) {
    return Repository.post(`${resource}/${id}/gameplay/${versionId}`);
  },
};
