<template>
  <li
    class="cursor-pointer"
    :class="[index >= 1 ? 'border-t border-gray-200' : '']"
    @click="showMedia"
  >
    <a
      class="block hover:text-white focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
      :class="[
        selected === mediaItem.id
          ? 'bg-blue-400 text-white hover:bg-blue-400'
          : 'text-gray-700 hover:bg-blue-400',
      ]"
    >
      <div class="flex items-center px-4 py-2">
        <div class="min-w-0 flex-1 flex items-center">
          <div class="flex-shrink-0">
            <img
              v-if="mediaType === 'image'"
              class="object-contain h-16 w-full"
              :src="mediaItem.uri"
              alt=""
            />
            <img
              v-if="mediaType === 'video'"
              class="object-contain h-16 w-full"
              :src="mediaItem.uri"
              alt=""
            />

            <div
              v-if="mediaType === 'audio'"
              class="h-12 w-12 flex justify-center items-center"
            >
              <font-awesome-icon
                icon="file-audio"
                class="text-gray-700 text-29 flex items-center"
              />
            </div>
          </div>

          <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-2">
            <div class="flex items-center">
              <div
                class="text-sm leading-5 font-light truncate md:whitespace-normal"
              >
                {{ mediaItem.name }}
              </div>
            </div>
            <div class="flex items-center">
              <div class="text-xs leading-5 font-bold">
                {{ formatBytes(mediaItem.size) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  </li>
</template>

<script>
import formatBytes from "@/mixins/formatBytes";

export default {
  name: "MediaItem",
  mixins: [formatBytes],
  props: {
    index: {
      type: Number,
      default: 0,
    },
    mediaItem: {
      type: Object,
      required: true,
    },
    mediaType: {
      type: String,
      default: "Image",
    },
    selected: {
      type: Number,
      default: null,
    },
  },
  methods: {
    showMedia() {
      this.$emit("showMedia", this.mediaItem);
    },
  },
};
</script>

<style></style>
