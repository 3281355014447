<template>
  <svg
    width="20"
    height="16"
    viewBox="-0.5 -1 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      stroke="null"
      id="svg_3"
      height="10.51905"
      width="20.08962"
      y="-0.01127"
      x="-0.01843"
      fill="#161e2e"
    />
    <path
      :fill="color"
      d="m13.3536,4.35355c0.1952,-0.19526 0.1952,-0.51184 0,-0.7071l-3.182,-3.18198c-0.19529,-0.19527 -0.51187,-0.19527 -0.70713,0c-0.19527,0.19526 -0.19527,0.51184 0,0.7071l2.82843,2.82843l-2.82843,2.82843c-0.19527,0.19526 -0.19527,0.51184 0,0.7071c0.19526,0.19527 0.51184,0.19527 0.70713,0l3.182,-3.18198zm-13.3536,0.14645l13,0l0,-1l-13,0l0,1z"
    />
    <line v-if="showLine" :stroke="color" y2="10" x2="4" y1="10" x1="13" />
  </svg>
</template>

<script>
export default {
  name: "FirstArrow",
  props: {
    color: {
      type: String, // setup || listener || nonLinear || linear
      default: "",
    },
    showLine: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
