<template>
  <Form>
    <div class="px-8 pt-6">
      <div class="flex flex-row">
        <div class="mb-2">
          <span class="block text-white text-sm text-left font-bold mb-2">
            {{ $t("download.select_a_date_range") }}
          </span>
          <div class="flex justify-start items-center">
            <v-date-picker
              v-model.range="pickerRange"
              mode="date"
              :masks="
                locale === 'en'
                  ? { input: ['DD MMM YYYY'] }
                  : { input: ['DD-MM-YYYY'] }
              "
            >
              <template #default="{ inputValue, inputEvents }">
                <div class="flex flex-row bg-white border px-2 py-1 rounded">
                  <input
                    class="text-center"
                    :value="inputValue.start"
                    v-on="inputEvents.start"
                  />
                  <p>-</p>
                  <input
                    class="text-center"
                    :value="inputValue.end"
                    v-on="inputEvents.end"
                  />
                </div>
              </template>
            </v-date-picker>
          </div>
        </div>
      </div>
    </div>
  </Form>
</template>

<script>
export default {
  name: "DatePicker",
  props: {
    range: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      val: {},
      start: "",
      pickerRange: null,
    };
  },
  watch: {
    pickerRange: {
      handler(val) {
        this.$emit("update:range", val);
      },
    },
  },
  mounted() {
    this.pickerRange = this.range;
  },
};
</script>

<style></style>
