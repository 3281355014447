<template>
  <div class="w-full h-full px-4 py-5 sm:p-6">
    <div
      v-if="!uploading"
      class="flex w-full h-full mt-1 justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md"
      :class="{
        'border-blue-500 bg-blue-100': fileOverDropArea,
        'border-gray-300': !fileOverDropArea,
      }"
      @drop.prevent="dropFile"
      @dragover.prevent
      @dragenter.prevent="fileOverDropArea = true"
      @dragleave.prevent="fileOverDropArea = false"
    >
      <div
        class="flex flex-col justify-center align-middle space-y-1 text-center"
      >
        <svg
          class="mx-auto h-12 w-12 text-gray-400"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 48 48"
          aria-hidden="true"
        >
          <path
            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div class="flex text-sm text-gray-600">
          <label
            for="file-upload"
            class="relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
          >
            <span>{{ title ? title : $t("media.upload_a_file") }}</span>
            <input
              id="file-upload"
              name="file-upload"
              type="file"
              :accept="`${mediaModalType}/*`"
              class="sr-only"
              @change="uploadFile($event.target.files)"
            />
          </label>
          <p class="pl-1">
            {{ $t("media.or_drag_and_drop") }}
          </p>
        </div>
      </div>
    </div>

    <div
      v-else
      class="flex w-full h-full mt-1 justify-center px-6 pt-5 pb-6 rounded-md"
    >
      <div
        class="flex flex-col justify-center align-middle space-y-1 text-center"
      >
        <div class="relative pt-1">
          <div class="flex flex-col mb-2 items-center justify-between">
            <div>
              <span>{{ `${$t("media.uploading")}: ${file.name}` }}...</span>
            </div>
            <div class="flex flex-row text-right p-1">
              <span class="text-xs font-semibold inline-block text-gray-900">
                {{ `${formatBytes(done)} / ${formatBytes(file.size)}` }}
              </span>

              <span
                class="text-xs font-semibold inline-block ml-2 uppercase rounded-full text-gray-900"
              >
                {{
                  progress === 100
                    ? `${$t("media.finalizing")}...`
                    : `${progress}%`
                }}
              </span>
            </div>
          </div>
          <div
            class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-indigo-200"
          >
            <div
              :style="{ width: `${progress}%` }"
              class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-linearBlue"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import formatBytes from "@/mixins/formatBytes";

export default {
  name: "UploadMediaTab",
  mixins: [formatBytes],
  props: {
    mediaModalType: {
      type: String,
      required: true,
    },
    uploading: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
    done: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {
      fileOverDropArea: false,
      file: null,
    };
  },
  methods: {
    dropFile(e) {
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      this.file = droppedFiles[0];
      this.fileOverDropArea = false;
      this.uploadFile(droppedFiles);
    },
    uploadFile(file) {
      this.file = file[0];
      this.$emit("uploadFile", file);
    },
  },
};
</script>
