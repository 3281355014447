<template>
  <div>
    <div
      class="backgroundCard flex w-full flex-col justify-start justify-items-start desktop:mr-30 mobile:my-5"
    >
      <div class="mb-5">
        <DatePicker
          :range="selectedRange"
          :locale="this.$i18n.locale"
          @update:range="updateSelectedRange"
        />
      </div>
    </div>
    <div v-if="!loading" class="flex flex-col backgroundCard mt-15 w-full">
      <highcharts class="pt-30" :options="chartOptions"></highcharts>
    </div>
    <div v-else class="flex justify-center flex-col items-center h-550">
      <Loader />
      <p class="text-17 font-medium text-white leading-none mt-6">
        {{ $t("reporting.loading_content") }}...
      </p>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import { Chart } from "highcharts-vue";
import { format, subDays } from "date-fns";
import { chartStylingOptions } from "@/config";
import { monthIndexes } from "@/helpers/dateHelpers";
import DatePicker from "../../components/reporting/DatePicker";
import Loader from "../../components/helpers/Loader";
import Highcharts from "highcharts";

const reportingRepository = RepositoryFactory.get("reporting");

export default {
  name: "Playtime",
  components: {
    highcharts: Chart,
    DatePicker,
    Loader,
  },
  data() {
    return {
      selectedRange: {
        start: subDays(new Date(), 365),
        end: new Date(),
      },
      sortedSeries: { one: null },
      gameData: [],
      users: null,
      loading: true,
    };
  },
  computed: {
    chartOptions() {
      return {
        ...chartStylingOptions,
        title: {
          text: "",
        },
        chart: {
          backgroundColor: "#ffffff00",
          type: ["column"],
          marginRight: 40,
          animation: false,
        },
        series: [
          {
            name: this.$t("reporting.active_users"),
            type: "column",
            color: "#7cb5ec",
            borderWidth: 0,
            data: this.sortedSeries.one,
            dataLabels: {
              style: {
                color: "white",
                fontSize: "11px",
                fontWeight: "medium",
                textOutline: `1px ${process.env.VUE_APP_SECONDARY_COLOR}`,
              },
              enabled: true,
              crop: false,
              overflow: "none",
              inside: false,
            },
          },
        ],
        xAxis: {
          type: "datetime",
          lineColor: "white",
          gridLineColor: "black",
          labels: {
            style: {
              color: "#ffffff",
            },
            formatter: function () {
              return Highcharts.dateFormat("%b %Y", this.value);
            },
          },
        },
        yAxis: {
          gridLineColor: "#ffffff00",
          title: {
            style: {
              color: "white",
            },
            text: this.$t("reporting.number_of_users"),
          },
          labels: {
            style: {
              color: "#ffffff",
            },
          },
        },
        plotOptions: {
          series: {
            events: {
              legendItemClick: function () {
                return false;
              },
            },
          },
        },
        tooltip: {
          formatter: function () {
            return `${format(this.x, "MMMM")} : ${this.y}`;
          },
        },
      };
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      reportingRepository
        .getMonthlyNumberOfUsersPlayedLevel(
          format(this.selectedRange.start, "yyyy-MM-dd"),
          format(this.selectedRange.end, "yyyy-MM-dd"),
        )
        .then((result) => {
          this.createSeries(result.data.total, this.gameData, 0);
        });
    },
    createSeries(result, data, series) {
      result.map((r) => {
        data.push({
          x: new Date(r.year, monthIndexes[r.month.toLowerCase()]),
          y: parseInt(r.users),
        });
      });
      this.sortedSeries.one = data.sort(function (a, b) {
        return new Date(a.x) - new Date(b.x);
      });
      this.loading = false;
    },
    updateSelectedRange(range) {
      this.loading = true;
      this.gameData = [];
      this.selectedRange = range;
      this.fetch();
    },
  },
};
</script>
