<template>
  <ModalContainer>
    <div
      v-if="locales.length"
      class="bg-white rounded-lg overflow-visible shadow-xl transform transition-all sm:max-w-4xl sm:w-full"
    >
      <div class="bg-white mt-2 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              {{ $t("translation.select_locale") }}
            </h3>
          </div>
        </div>
      </div>
      <div class="mt-3 text-center mr-2 sm:mt-0 sm:ml-4 sm:pl-6 sm:text-left">
        <div class="mt-2">
          <p class="text-md leading-5 text-gray-600 break-all">
            {{ `${$t("translation.file")}: ${fileName}` }}
          </p>
        </div>
      </div>
      <div class="bg-white px-4 pt-5 pb-4 sm:p-8 sm:pb-4">
        <v-select
          v-model="selectedLocale"
          :options="locales"
          :clearable="false"
          label="description"
        >
        </v-select>
      </div>
      <div
        class="bg-gray-50 mr-2 mb-2 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"
      >
        <div class="flex justify-end">
          <Button
            button-type="primary"
            :title="$t('button.confirm')"
            @clickAction="confirm"
          />
          <Button
            class="ml-3"
            button-type="secondary"
            :title="$t('button.cancel')"
            @clickAction="cancel"
          />
        </div>
      </div>
    </div>
  </ModalContainer>
</template>

<script>
import ModalContainer from "../ModalContainer";
import Button from "../../helpers/Button";

export default {
  name: "SelectLocaleModal",
  components: { ModalContainer, Button },
  props: {
    fileName: {
      type: String,
      default: null,
    },
  },
  emits: ["confirm", "cancel"],
  data: () => {
    return {
      locales: [],
      selectedLocale: {},
    };
  },
  mounted() {
    this.getLocales();
  },
  methods: {
    getLocales() {
      this.$store.dispatch("translationStore/GET_LOCALES").then((result) => {
        this.locales = result.data;

        const defaultLocale = this.locales.find((locale) => {
          return (
            this.fileName.toLowerCase().includes(locale.tag.toLowerCase()) ||
            this.fileName
              .toLowerCase()
              .includes(locale.tag.replace(/_/g, "-").toLowerCase())
          );
        });

        this.selectedLocale = defaultLocale || this.locales[0];
      });
    },
    confirm() {
      this.$emit("confirm", this.selectedLocale.tag);
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style></style>
