<template>
  <div class="absolute bottom-0 right-0 mb-16 mr-10 z-50">
    <div
      v-if="showMenu"
      class="mt-2 w-96 rounded-md shadow-lg transition-transform"
      v-click-outside="toggleMenu"
    >
      <div
        class="rounded-md bg-gray-50 ring-1 ring-black ring-opacity-5 transition-transform"
        role="menu"
      >
        <div class="py-1">
          <a
            class="group flex items-center px-4 py-2 text-md leading-5 text-gray-900"
          >
            {{ $t("shortcuts.quick_actions") }}
          </a>
        </div>
        <div class="border-t border-gray-300 ml-2 mr-2"></div>

        <div
          v-for="(
            keyboardShortcutCategories, keyboardShortcutsIndex
          ) in keyboardShortcuts"
          class="py-1"
          :key="keyboardShortcutsIndex"
        >
          <div class="flex flex-1 flex-col">
            <div
              v-for="(
                keyboardShortcut, keyboardShortcutIndex
              ) in keyboardShortcutCategories.shortcuts"
              :key="keyboardShortcutIndex"
              class="px-4 cursor-pointer hover:bg-blue-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            >
              <div
                v-if="keyboardShortcut.quick_action"
                class="flex flex-row"
                @click="
                  isActive(keyboardShortcut.name)
                    ? quickAction(keyboardShortcut.name)
                    : null
                "
              >
                <div
                  class="group flex flex-1 items-center py-2 text-sm leading-5"
                  :class="
                    isActive(keyboardShortcut.name)
                      ? 'text-gray-700'
                      : 'text-gray-300'
                  "
                >
                  {{ $t(`shortcuts.${keyboardShortcut.name}`) }}
                </div>
                <div
                  v-for="(key, index) in keyboardShortcut.check_os &&
                  operatingSystem === 'macos'
                    ? keyboardShortcut.shortcut_mac
                    : keyboardShortcut.shortcut"
                  :key="`keyboardShortcut-${index}`"
                  class="flex flex-row items-center justify-end text-10 leading-5 text-gray-700"
                >
                  <div
                    class="pl-2 pr-2 pt-0.5 pb-0.5 text-10 bg-gray-200 rounded-md"
                  >
                    {{ key }}
                  </div>
                  <span
                    v-if="keyboardShortcut.shortcut.length - 1 !== index"
                    class="ml-1 mr-1 text-14"
                  >
                    +
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-end mt-2">
      <div
        class="flex items-center justify-end rounded-full p-4 shadow transition duration-500 ease-in-out transform hover:scale-102"
        :class="showMenu ? 'bg-gray-50' : 'bg-blue-600 hover:bg-blue-500'"
        @click="toggleMenu"
      >
        <font-awesome-icon
          icon="bolt"
          class="text-20 w-6 h-6"
          :class="showMenu ? 'text-blue-500' : 'text-gray-50'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { keyboardShortcutList } from "@/helpers/keyboardShortcutList";
import { getOS } from "@/helpers/osCheck";
import { mapState } from "vuex";
import keyboardShortcuts from "@/mixins/keyboardShortcuts";

export default {
  name: "QuickActionMenu",
  mixins: [keyboardShortcuts],
  data() {
    return {
      showMenu: false,
      keyboardShortcuts: keyboardShortcutList,
      operatingSystem: "windows",
    };
  },
  computed: {
    ...mapState("editorStore", [
      "activeAction",
      "activeStep",
      "activeType",
      "activeLesson",
      "mainSteps",
    ]),
    ...mapState("keyboardShortcutStore", ["clipboard"]),
  },
  mounted() {
    this.operatingSystem = getOS();
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    quickAction(action) {
      switch (action) {
        case "expand_all_visible":
          this.expandSteps();
          break;
        case "collapse_all_visible":
          this.collapseSteps();
          break;
        case "show_full_title":
          this.showFullTitles();
          break;
        case "copy_id":
          this.copyId();
          break;
        case "copy_step_action":
          this.copyActive();
          break;
        case "cut_step_action":
          this.cutActive();
          break;
        case "paste_step_action":
          this.pasteClipboard();
          break;
      }
      this.toggleMenu();
    },
    isActive(action) {
      switch (action) {
        case "copy_id":
          return this.activeAction.id ?? this.activeStep.id;
        case "cut_step_action":
          return this.activeAction.id ?? this.activeStep.id;
        case "copy_step_action":
          return this.activeAction.id ?? this.activeStep.id;
        case "paste_step_action":
          return this.clipboard.target;
        default:
          return true;
      }
    },
  },
};
</script>

<style></style>
