<template>
  <ModalContainer @cancel="close">
    <div
      class="flex flex-col justify-between w-1/2 h-2/5 bg-gray-50 p-4 rounded-md"
    >
      <p
        class="flex self-start text-gray-700 font-normal uppercase text-20 tracking-widest pb-4"
      >
        {{ edit ? $t("trophies.edit_trophy") : $t("trophies.add_trophy") }}
      </p>
      <div class="w-full h-full">
        <Form
          class="flex flex-col h-full justify-between"
          @keypress.enter="edit ? editTrophy() : addTrophy()"
          @submit="edit ? editTrophy() : addTrophy()"
          v-slot="{ errors }"
        >
          <div class="flex flex-col">
            <InputField
              :title="$t('editor.name')"
              placeholder="Name"
              v-model="trophy.name"
              rules="required|min:4|max:255"
            />
            <InputField
              :title="$t('editor.introduction')"
              placeholder="Intro"
              v-model="trophy.description"
              rules="required|min:4|max:255"
            />
          </div>
          <!-- <ImageField class="mt-3" @click.native="showMediaModal = true" /> -->
          <div class="flex flex-row justify-end">
            <Button
              class="mr-3"
              type="submit"
              :loading="loading"
              button-type="primary"
              :title="$t('editor.save')"
              :invalid="Object.keys(errors).length !== 0"
            />
            <Button
              type="button"
              button-type="secondary"
              :title="$t('editor.cancel')"
              @clickAction="close"
            />
          </div>
        </Form>
      </div>
    </div>
  </ModalContainer>
</template>

<script>
import InputField from "@/components/form/InputField";
import { mapState } from "vuex";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import { showNotification } from "@/services/notificationService";
import Button from "@/components/form/Button";
const trainingRepository = RepositoryFactory.get("training");
import ModalContainer from "@/components/helpers/ModalContainer";

export default {
  name: "TrophyModal",
  components: { InputField, Button, ModalContainer },
  props: {
    edit: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["close"],
  data() {
    return {
      showMediaModal: false,
      trophy: {
        name: "",
        description: "",
      },
      loading: false,
    };
  },
  computed: {
    ...mapState("trainingStore", ["trainingId", "versionId", "chapter"]),
  },
  mounted() {
    if (this.edit) {
      this.trophy.name = this.edit.name;
      this.trophy.description = this.edit.description;
    }
  },
  methods: {
    addTrophy() {
      this.loading = true;
      trainingRepository
        .createTrophies(this.trainingId, this.trophy)
        .then(() => {
          this.loading = false;
          showNotification(
            this.$t("notifications.trophy_created"),
            this.$t("notifications.trophy_is_successfully_created"),
            "success",
          );
          this.$emit("close");
        });
    },
    editTrophy() {
      this.loading = true;
      trainingRepository
        .updateTrophies(this.trainingId, this.edit.id, this.trophy)
        .then(() => {
          this.loading = false;
          showNotification(
            this.$t("notifications.trophy_editted"),
            this.$t("notifications.trophy_is_successfully_editted"),
            "success",
          );
          this.$emit("close");
        });
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
