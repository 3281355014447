<template>
  <PageContentContainer>
    <div
      class="flex justify-start mobile:justify-between flex-row flex-wrap mt-15"
    >
      <ReportNavButton
        :title="$t('reporting.my_playtime')"
        :active="$route.name === 'playtime'"
        icon="user-clock"
        link="playtime"
      />
      <ReportNavButton
        :title="$t('reporting.active_users')"
        :active="$route.name === 'active-user'"
        icon="user-check"
        link="active-user"
      />
      <ReportNavButton
        :title="$t('reporting.level_scores')"
        :active="$route.name === 'lesson-score'"
        icon="gem"
        link="lesson-score"
      />
      <ReportNavButton
        :title="$t('reporting.playtime_scores')"
        :active="$route.name === 'playtime-score'"
        icon="calendar"
        link="playtime-score"
      />
      <ReportNavButton
        :title="$t('reporting.level_times')"
        :active="$route.name === 'lesson-times'"
        icon="clock"
        link="lesson-times"
      />
      <ReportNavButton
        :title="$t('reporting.download')"
        :active="$route.name === 'download'"
        icon="download"
        link="download"
      />
    </div>
    <router-view></router-view>
  </PageContentContainer>
</template>

<script>
import PageContentContainer from "@/components/helpers/PageContentContainer";
import ReportNavButton from "@/components/reporting/navButton";

export default {
  name: "ReportingOverview",
  components: { ReportNavButton, PageContentContainer },
};
</script>

<style></style>
