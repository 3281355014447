const state = {
  showNotification: false,
  content: null,
  title: "",
  type: "", // succes, error, warning
};

const getters = {};

const actions = {
  SHOW_NOTIFICATION: ({ commit, dispatch }, payload) => {
    commit("NOTIFICATION", payload);
  },
  CLOSE_NOTIFICATION: ({ commit, dispatch }, payload) => {
    commit("CLOSE_NOTIFICATION");
  },
};

const mutations = {
  NOTIFICATION: (state, payload) => {
    state.showNotification = true;
    state.content = payload.content;
    state.type = payload.type;
    state.title = payload.title;
    setTimeout(function () {
      state.showNotification = false;
      state.content = null;
      state.type = "";
      state.title = "";
    }, 5000);
  },
  CLOSE_NOTIFICATION: (state, payload) => {
    state.showNotification = false;
    state.content = null;
    state.type = "";
    state.title = "";
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
