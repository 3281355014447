<template>
  <div class="w-100p flex flex-col">
    <div>
      <div
        class="goldTrophy"
        :class="{
          bgGold: color === 'gold',
          bgSilver: color === 'silver',
          bgBronze: color === 'bronze',
          bgLocked: locked,
        }"
      >
        <div
          v-if="backgroundUrl && !locked"
          class="innerImg bg-lockedColor"
          :style="{
            backgroundImage: '',
          }"
        ></div>
        <div
          v-else
          class="innerImgLocked bg-lockedColor flex justify-center items-center"
        >
          <font-awesome-icon
            icon="lock"
            class="fa-lg"
            style="color: rgba(237, 85, 101, 0.6)"
          />
        </div>
      </div>
    </div>
    <p
      class="text-center text-15 text-lightAlineaColor font-medium mt-1 leading-tight"
    >
      {{ title }}
    </p>
  </div>
</template>

<script>
export default {
  name: "Trophy",
  props: {
    backgroundUrl: {
      type: String,
      default: "",
      require: true,
    },
    title: {
      type: String,
      default: "title",
      require: true,
    },
    color: {
      type: String,
      default: "none",
      require: true,
    }, //gold //silver //bronze
    locked: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.goldTrophy {
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerImg {
  background-position: center;
  background-size: cover;
  width: 74px;
  height: 74px;
  border-radius: 117px;
  margin-bottom: 3px;
  margin-right: 0px;
  box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.9);
}

.innerImgLocked {
  width: 74px;
  height: 74px;
  border-radius: 117px;
  margin-bottom: 3px;
  margin-right: 0px;
  background: rgba(150, 150, 150, 0.16);
  box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.2);
}

.bgGold {
  background-image: url("../../assets/trophies/goldTrophy.svg");
}

.bgSilver {
  background-image: url("../../assets/trophies/silverTrophy.svg");
}

.bgBronze {
  background-image: url("../../assets/trophies/bronzeTrophy.svg");
}

.bgLocked {
  background-image: url("../../assets/trophies/lockedTrophy.svg");
}
</style>
