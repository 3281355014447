<template>
  <PageContentContainer>
    <div class="grid grid-cols-1 gap-6">
      <div class="backgroundRow overflow-hidden shadow sm:rounded-md">
        <div class="px-4 py-5 sm:p-6">
          <Form
            @keypress.enter="createDealer"
            @submit="createDealer"
            v-slot="{ errors }"
          >
            <InputField
              class="mt-inputPadding w-full"
              :title="$t('customers.name')"
              rules="required|min:4|max:100"
              :required-input="true"
              type="text"
              placeholder=""
              :light="true"
              v-model="dealer.name"
              id="dealerName"
            />

            <InputField
              class="mt-inputPadding w-full"
              :title="$t('customers.client_id')"
              rules="required"
              :required-input="true"
              type="text"
              placeholder=""
              :light="true"
              v-model="dealer.client_id"
              id="dealerID"
            />

            <DatePicker
              :title="$t('customers.start_date')"
              rules="required"
              :locale="$i18n.locale"
              :required-input="true"
              @update="
                (val) => {
                  dealer.start_date = val;
                }
              "
            />

            <DatePicker
              :title="$t('customers.stop_date')"
              :locale="$i18n.locale"
              @update="
                (val) => {
                  dealer.stop_date = val;
                }
              "
            />

            <ToggleButton
              :title="$t('customers.enabled')"
              :val="dealer.enabled"
              :light="true"
              @update="dealer.enabled = !dealer.enabled"
              id="dealerEnabled"
            />

            <ToggleButton
              v-if="showOwner"
              class="mt-4"
              :title="$t('customers.owner')"
              :val="dealer.owner"
              :light="true"
              @update="dealer.owner = !dealer.owner"
              id="dealerOwner"
            />

            <div class="mt-8 pt-5">
              <div class="flex justify-end">
                <Button
                  type="submit"
                  class="mr-3"
                  :invalid="Object.keys(errors).length !== 0"
                  button-type="lightPrimary"
                  :title="$t('editor.save')"
                  id="dealerAcccept"
                />
                <Button
                  type="button"
                  button-type="lightSecondary"
                  :title="$t('customers.cancel')"
                  @clickAction="
                    $router.push({
                      name: 'dealers',
                    })
                  "
                  cy="dealerCancel"
                />
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </PageContentContainer>
</template>

<script>
import Button from "@/components/form/Button";
import InputField from "@/components/form/InputField";
import DatePicker from "@/components/form/DatePicker";
import ToggleButton from "@/components/form/ToggleButton";
import PageContentContainer from "@/components/helpers/PageContentContainer";
import { showNotification } from "@/services/notificationService";
import { errorHandlingAndMessage } from "@/services/ErrorService";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const dealerRepository = RepositoryFactory.get("dealer");
import { mapState } from "vuex";

export default {
  name: "DealerCreate",
  components: {
    PageContentContainer,
    Button,
    InputField,
    ToggleButton,
    DatePicker,
  },
  data: () => {
    return {
      dealer: {
        name: "",
        client_id: "",
        start_date: null,
        stop_date: null,
        enabled: true,
        owner: false,
      },
    };
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
    showOwner: function () {
      return this.currentUser.roles.includes("ROLE_SUPER_ADMIN");
    },
  },
  methods: {
    createDealer() {
      dealerRepository
        .createDealer(this.dealer)
        .then(() => {
          showNotification(
            this.$t("notifications.dealer_created"),
            this.$t("notifications.dealer_is_successfully_created"),
            "success",
          );
          this.$router.push({
            name: "dealers",
          });
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t("notifications.something_went_wrong_creating_this_dealer"),
          );
        });
    },
    toggleEnabled(val) {
      this.customer.enabled = val;
    },

    toggleOwner(val) {
      this.customer.owner = val;
    },
  },
};
</script>

<style></style>
