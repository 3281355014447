import Repository from "./Repository";

const resource = "/api/v3/settings";

export default {
  getSettingSettings() {
    return Repository.get(`${resource}/settings`);
  },

  getSettings(payload) {
    return Repository.get(`${resource}?filter=${payload}`);
  },

  newSetting(payload) {
    return Repository.post(`${resource}`, { setting: payload });
  },

  deleteSetting(payload) {
    return Repository.delete(`${resource}/${payload}`);
  },

  editSetting(setting, id) {
    return Repository.patch(`${resource}/${id}`, { setting });
  },
};
