import Repository from "./Repository";

const resource = "/api/v3/users";

export default {
  getUsers({ page, sort, sortfield, maxResult, query }) {
    return Repository.get(
      `${resource}?page=${page}&max_results=${maxResult}&sort_direction=${sort}&sort_by_field=${sortfield}&search_query=${query}`,
    );
  },

  getAllUsers() {
    return Repository.get(`${resource}`);
  },

  getUser(id) {
    return Repository.get(`${resource}/${id}`);
  },

  getCurrentUser() {
    return Repository.get(`${resource}/current?include=customer,played-games`);
  },

  getUserResults(payload) {
    return Repository.get(`${resource}/${payload}/results`);
  },

  getUserGamesessions(payload) {
    return Repository.get(`${resource}/${payload}/gamesessions`);
  },

  getMonthlyUsagePeriodForUser(payload, start_date, end_date) {
    return Repository.get(`${resource}/${payload}/reporting/total-playtime`, {
      params: {
        start_date,
        end_date,
      },
    });
  },

  getUserPincode(payload) {
    const { id, training_id } = payload;
    return Repository.get(`${resource}/${id}/pin/${training_id}`);
  },

  activateStudentRole(payload) {
    return Repository.post(`/api/v3/users/activate`, {
      activation_token: payload,
    });
  },

  activate(payload) {
    return Repository.post(`${resource}/classify`, {
      activation_code: payload,
    });
  },

  updateUser(payload) {
    const {
      id,
      firstname,
      lastname,
      email,
      image,
      password,
      dealer_id,
      customer_id,
      roles,
      active,
    } = payload;
    return Repository.patch(`${resource}/${id}`, {
      firstname: firstname,
      lastname: lastname,
      email: email,
      password: password,
      image: image,
      dealer_id: dealer_id,
      customer_id: customer_id,
      roles: roles,
      active: active,
    });
  },

  inviteUser(payload) {
    return Repository.post(`${resource}/invite`, payload);
  },

  deleteUser(payload) {
    return Repository.delete(`${resource}/${payload}`);
  },
};
