<template>
  <PageContentContainer>
    <div class="grid grid-cols-1 gap-6">
      <div class="backgroundRow overflow-hidden shadow sm:rounded-md">
        <div class="px-4 py-5 sm:p-6">
          <Form
            v-if="customer"
            @keypress.enter="updateCustomer"
            @submit="updateCustomer"
            v-slot="{ errors }"
          >
            <InputField
              class="mt-inputPadding w-full"
              :title="$t('customers.name')"
              rules="required|min:4|max:100"
              :required-input="true"
              type="text"
              placeholder=""
              :light="true"
              v-model="customer.name"
              id="customerName"
            />

            <InputField
              class="mt-inputPadding w-full"
              :title="$t('customers.client_id')"
              rules="required"
              :required-input="true"
              type="text"
              placeholder=""
              :light="true"
              v-model="customer.client_id"
              id="customerClientId"
            />

            <DatePicker
              v-model="customer.start_date"
              rules="required"
              :locale="$i18n.locale"
              :required-input="true"
              :title="$t('customers.start_date')"
            />

            <DatePicker
              v-model="customer.stop_date"
              :locale="$i18n.locale"
              :title="$t('customers.stop_date')"
            />

            <SelectorField
              class="mb-6"
              :title="$t('customers.dealer')"
              :light="true"
              v-model="dealerId"
              id="dealerId"
            >
              <option
                v-for="dealer in dealers"
                :key="dealer.id"
                :value="dealer.id"
                :selected="dealerId === dealer.id"
              >
                {{ dealer.name }}
              </option>
            </SelectorField>

            <InputField
              v-if="!customer.demo"
              class="mt-inputPadding w-full"
              :title="$t('customers.activation_code')"
              rules="required"
              :required-input="true"
              type="text"
              placeholder=""
              :light="true"
              v-model="customer.activation_code"
              id="customerClientId"
            />

            <ToggleButton
              :title="$t('general.enabled')"
              :val="customer.enabled"
              :light="true"
              @update="customer.enabled = !customer.enabled"
              id="customer-enabled"
            />

            <div class="mt-8 pt-5">
              <div class="flex justify-end">
                <Button
                  type="submit"
                  class="mr-3"
                  :invalid="Object.keys(errors).length !== 0"
                  button-type="lightPrimary"
                  :title="$t('editor.save')"
                  id="editSave"
                />
                <Button
                  button-type="lightSecondary"
                  :title="$t('customers.cancel')"
                  @clickAction="
                    $router.push({
                      name: 'customers',
                    })
                  "
                  id="cancelSave"
                />
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </PageContentContainer>
</template>

<script>
import Button from "@/components/form/Button";
import InputField from "@/components/form/InputField";
import DatePicker from "@/components/form/DatePicker";
import SelectorField from "@/components/form/SelectorField";
import ToggleButton from "@/components/form/ToggleButton.vue";
import PageContentContainer from "@/components/helpers/PageContentContainer";
import { showNotification } from "@/services/notificationService.js";
import { errorHandlingAndMessage } from "@/services/ErrorService";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const customerRepository = RepositoryFactory.get("customer");
const dealerRepository = RepositoryFactory.get("dealer");

export default {
  name: "CustomerEdit",
  components: {
    PageContentContainer,
    Button,
    InputField,
    SelectorField,
    DatePicker,
    ToggleButton,
  },
  props: {
    id: {
      type: [String, Number],
      default: "",
    },
  },
  data: () => {
    return {
      mode: "single",
      dealerId: {},
      dealers: [],
      customer: null,
    };
  },
  created() {
    this.getDealers();
    if (this.id) {
      customerRepository
        .getCustomer(this.id)
        .then((result) => {
          this.customer = result.data.data;
          this.dealerId = result.data.data.dealer.data.id;
        })
        .catch(() => {
          this.$router.push({
            name: "customers",
          });
        });
    } else {
      this.$router.push({
        name: "customers",
      });
    }
  },
  methods: {
    updateCustomer() {
      customerRepository
        .updateCustomer(this.customer)
        .then(() => {
          showNotification(
            this.$t("notifications.customer_edited"),
            this.$t("notifications.customer_is_successfully_edited"),
            "success",
          );
          this.$router.push({
            name: "customers",
          });
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t("notifications.something_went_wrong_updating_customers"),
          );
        });
    },
    getDealers() {
      dealerRepository
        .getDealers()
        .then((result) => {
          this.dealers = result.data.data;
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t("notifications.something_went_wrong_receiving_dealers"),
          );
        });
    },
  },
};
</script>

<style></style>
