<template>
  <div class="hidden lg:flex lg:flex-shrink-0">
    <div
      class="flex-col overflow-y-auto bg-secondaryColor scrollbar-thin items-center flex w-64 py-5 px-4"
    >
      <div
        id="logo-container"
        class="flex justify-start items-start h-topNavHeight cursor-pointer w-full"
        @click="$router.push({ name: 'home' })"
      >
        <img
          id="company-logo"
          class="h-full object-contain"
          :src="require(`../../assets/theme/logo.png`)"
          :alt="prefix"
        />
      </div>
      <div
        id="nav-button-container-student"
        class="flex-col mt-10 w-full"
        data-cy="studentContainer"
      >
        <NavButton
          :title="$t('navigation.home')"
          icon="gamepad"
          :link="$router.resolve({ name: 'home' }).href"
          :active="$route.name === 'home'"
        ></NavButton>
        <NavButton
          :title="$t('navigation.results')"
          icon="poll-h"
          :link="$router.resolve({ name: 'results' }).href"
          :active="$route.name === 'results'"
          data-cy="results"
        ></NavButton>
        <NavButton
          :title="$t('navigation.highscore')"
          icon="list-alt"
          :link="$router.resolve({ name: 'highscores' }).href"
          :active="$route.name === 'highscores'"
          data-cy="highscore"
        ></NavButton>
      </div>

      <div
        v-if="adminRole"
        class="flex-col mt-6 w-full"
        data-cy="adminContainer"
      >
        <div
          class="flex flex-row items-center justify-between cursor-pointer rounded-md p-2"
          @click="showAdmin = !showAdmin"
        >
          <p class="text-lightAlineaColor text-8 font-bold">
            {{ $t("navigation.admin") }}
          </p>
          <font-awesome-icon
            :icon="!showAdmin ? 'chevron-down' : 'chevron-up'"
            class="transition ease-in-out text-lightAlineaColor duration-150"
          />
        </div>
        <div v-if="showAdmin">
          <NavButton
            :title="$t('navigation.reporting')"
            icon="chart-pie"
            :link="$router.resolve({ name: 'playtime' }).href"
            :active="$route.name === 'playtime'"
          ></NavButton>
          <NavButton
            v-if="currentUser.roles_reachable.includes('ROLE_USER_ADMIN')"
            :title="$t('navigation.users')"
            icon="users"
            :link="$router.resolve({ name: 'users' }).href"
            :active="$route.name === 'users'"
            data-cy="users"
          ></NavButton>
          <NavButton
            v-if="currentUser.roles_reachable.includes('ROLE_DEALER_ADMIN')"
            :title="$t('navigation.dealers')"
            icon="building"
            :link="$router.resolve({ name: 'dealers' }).href"
            :active="$route.name === 'dealers'"
            data-cy="dealers"
          ></NavButton>
          <NavButton
            v-if="currentUser.roles_reachable.includes('ROLE_CUSTOMER_ADMIN')"
            :title="$t('navigation.customers')"
            icon="globe-europe"
            :link="$router.resolve({ name: 'customers' }).href"
            :active="$route.name === 'customers'"
            data-cy="customers"
          ></NavButton>
          <NavButton
            v-if="currentUser.roles_reachable.includes('ROLE_EDITOR')"
            :title="$t('navigation.editor')"
            icon="sliders-h"
            :link="$router.resolve({ name: 'levels' }).href"
            :active="$route.name === 'levels'"
            data-cy="editor"
          ></NavButton>
          <NavButton
            v-if="currentUser.roles_reachable.includes('ROLE_SUPER_ADMIN')"
            :title="$t('navigation.manager')"
            icon="tools"
            external
            :link="virtualStudioManagerUrl"
          ></NavButton>
        </div>
      </div>

      <div
        id="nav-button-container-logout"
        class="flex flex-col flex-1 justify-end mb-6 w-full"
      >
        <div
          v-if="showActivationButton"
          class="nav-btn w-full group flex flex-col items-start px-2 py-2 mb-1 transition ease-in-out duration-150 border-0.5 border-lightAlineaColor rounded-md"
        >
          <span class="text-lightAlineaColor leading-6 text-sm mb-2 font-light">
            {{
              $t(
                "layout.You_are_working_in_demo_mode_to_activate_your_account_press_the_button_below",
              )
            }}
          </span>
          <Button
            :title="$t('profile.activate')"
            button-type="editor-secondary"
            hover="blue"
            @clickAction="toggleActivationModal"
          />
        </div>
        <div
          tag="button"
          :class="{ 'bg-navButtonBackground': clicked }"
          class="nav-btn w-full group flex items-center px-2 py-2 mb-1 text-base leading-6 font-medium rounded-md cursor-pointer text-lightAlineaColor hover:bg-navButtonBackground transition ease-in-out duration-150"
          @click="showPersonalData = true"
          data-cy="username"
        >
          <font-awesome-icon
            icon="user-circle"
            class="fa-lg mr-4 h-6 w-6 transition ease-in-out duration-150"
          />
          {{ currentUser.firstname }}
        </div>

        <div
          tag="button"
          class="nav-btn w-full group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md cursor-pointer text-lightAlineaColor hover:bg-navButtonBackground transition ease-in-out duration-150"
          @click="logout"
          data-cy="logout"
        >
          <font-awesome-icon
            icon="sign-out-alt"
            class="fa-lg mr-4 h-6 w-6 transition ease-in-out duration-150"
          />

          {{ $t("navigation.logout") }}
        </div>

        <transition name="slide">
          <ProfileCard
            :show="showPersonalData"
            :user="currentUser"
            @updateUserData="showPersonalData = false"
          />
        </transition>
      </div>
      <div class="mb-1 flex w-full">
        <a
          v-if="showChangeLog"
          class="flex text-lightAlineaColor font-light opacity-50"
          data-cy="changelog"
          href="/changelog"
        >
          {{ appVersion }}
        </a>

        <p
          v-else
          data-cy="noChangelog"
          class="flex text-lightAlineaColor font-light opacity-50"
        >
          {{ appVersion }}
        </p>

        <p class="text-lightAlineaColor font-light opacity-50 mx-1">|</p>
        <a
          class="flex text-lightAlineaColor font-light opacity-50"
          href="/BLUETEA-TERMS-OF-USE.pdf"
          target="_blank"
        >
          Disclaimer
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import NavButton from "./NavButton";
import { getBaseUrlPrefix } from "@/helpers/generalHelpers";
import { mapState } from "vuex";
import { adminRoles } from "@/config";
import ProfileCard from "../profile/ProfileCard";
import version from "../../../public/status/app.json";
import Button from "@/components/form/Button";

export default {
  name: "Navigation",
  components: {
    NavButton,
    ProfileCard,
    Button,
  },
  props: {
    username: {
      type: String,
      default: "Your name",
    },
    clicked: {
      type: Boolean,
      required: true,
      default: false,
    },
    roles: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      appVersion: version.version,
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      virtualStudioManagerUrl: process.env.VUE_APP_VSM_URL,
      prefix: getBaseUrlPrefix(process.env.VUE_APP_API_BASE_URL),
      showParams: false,
      showAdmin: true,
      showPersonalData: false,
    };
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
    adminRole: function () {
      return adminRoles.some((role) =>
        this.currentUser.roles_reachable.includes(role),
      );
    },
    showChangeLog: function () {
      return !!this.currentUser.roles_reachable.includes("ROLE_SUPER_ADMIN");
    },
    showActivationButton: function () {
      return this.currentUser.demo;
    },
  },
  methods: {
    logout: function () {
      this.$store.dispatch("authStore/AUTH_LOGOUT").then(() => {
        this.$router.push("/login");
      });
    },
    showProfileCard() {
      this.$emit("clickAction");
    },
    toggleActivationModal() {
      this.$store.dispatch("userStore/TOGGLE_ACTIVATION_MODAL");
    },
  },
};
</script>
