<template>
  <div>
    <div class="flex flex-row">
      <div class="flex justify-between">
        <label
          for="input"
          class="block text-sm font-medium leading-5"
          :class="[textColor]"
        >
          {{ title }}
        </label>
        <span
          v-if="requiredInput"
          class="text-sm leading-5"
          :class="[textColor]"
        >
          {{ $t("game.required") }}
        </span>
      </div>
      <div
        v-if="hint"
        class="flex justify-center bg-white items-center h-3 w-3 rounded-full mt-1 ml-1"
        v-tooltip="{ content: hint, boundary: 'window' }"
      >
        <p class="text-sidebar font-bold text-9">?</p>
      </div>
    </div>
    <span
      :class="{ 'bg-red-500': !newValue, 'bg-green-500': newValue }"
      class="mt-1 relative inline-block flex-no-shrink h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring"
      role="checkbox"
      tabindex="0"
      @click="toggle()"
      @keydown.space.prevent="toggle()"
      :aria-checked="newValue.toString()"
    >
      <span
        aria-hidden="true"
        :class="{ 'translate-x-5': newValue, 'translate-x-0': !newValue }"
        class="inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"
      ></span>
    </span>
  </div>
</template>

<script>
export default {
  name: "ToggleButton",
  props: {
    val: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    requiredInput: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: "",
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {};
  },
  computed: {
    newValue: {
      // getter
      get: function () {
        return this.val;
      },
      // setter
      set: function (newValue) {
        this.$emit("update", newValue);
      },
    },
    style: function () {
      if (this.light) return "text-gray-100 bg-rowHover";
      else return "border-gray-300";
    },
    textColor: function () {
      if (this.light) return "text-gray-100";
      else return "text-gray-700";
    },
  },
  methods: {
    toggle() {
      this.newValue = !this.newValue;
    },
  },
};
</script>

<style></style>
