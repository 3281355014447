<template>
  <div class="flex justify-center items-center h-full">
    <div class="flex justify-center flex-col items-center">
      <p
        class="text-140 font-black leading-none bg-secondaryColor text-primaryColor px-5 py-10 rounded-lg mb-30"
      >
        {{ error }}
      </p>
      <p class="text-60 mt-12 leading-none font-normal text-secondaryColor">
        {{ $t("error.you_dont_access_to_this_page") }}
      </p>
      <p
        class="text-20 leading font-extralight none text-lightAlineaColor cursor-pointer hover:bg-primaryOpacity mt-16 py-2 px-4"
        @click="toHome"
      >
        {{ $t("error.to_home_page") }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error403",
  props: {
    error: {
      type: Number,
      default: 403,
    },
  },
  methods: {
    toHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style></style>
