import Repository from "./Repository";

const resource = "/api/v3/trainings";

export default {
  getProductFields(trainingId, category, locale) {
    return Repository.get(
      `${resource}/${trainingId}/product-fields/${category}?include=media`,
      {
        headers: {
          "Accept-Language": locale,
        },
      },
    );
  },
  getCategories(trainingId) {
    return Repository.get(`${resource}/${trainingId}/product-field-categories`);
  },

  editProductField(trainingId, productFieldId, locale, payload) {
    return Repository.patch(
      `${resource}/${trainingId}/productfields/${productFieldId}?include=media`,
      payload,
      {
        headers: {
          "Accept-Language": locale,
        },
      },
    );
  },

  getTypes(trainingId) {
    return Repository.get(`${resource}/${trainingId}/productfields/types`);
  },
};
