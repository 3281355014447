import { RepositoryFactory } from "@/repository/RepositoryFactory";
import { errorHandlingAndMessage } from "@/services/ErrorService";
import i18n from "@/i18n";
const translationRepository = RepositoryFactory.get("translation");

const state = {
  locales: [],
  showImportModal: false,
  showExportModal: false,
  showTranslationFilesModal: false,
  entityName: "",
  entityId: "",
};

const getters = {
  getLocales(state) {
    return state.locales;
  },
};

const actions = {
  GET_LOCALES: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      translationRepository
        .getLocales()
        .then((resp) => {
          commit("SET_LOCALES", resp.data);
          resolve(resp);
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            i18n.global.t("notifications.something_went_wrong_getting_locales"),
          );
          reject(err);
        });
    });
  },
  IMPORT_TRANSLATION: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      translationRepository
        .importTranslation(payload.entityName, payload.entityId, {
          uploaded_file: payload.uploaded_file,
          locale: payload.locale,
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            i18n.global.t(
              "notifications.something_went_wrong_while_importing_translations",
            ),
          );
          reject(err);
        });
    });
  },
  EXPORT_TRANSLATION: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      translationRepository
        .exportTranslation(payload.entityName, payload.entityId)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            i18n.global.t(
              "notifications.something_went_wrong_while_exporting_translations",
            ),
          );
          reject(err);
        });
    });
  },
  GET_APP_TRANSLATIONS: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      translationRepository
        .getAppTranslations(payload)
        .then((resp) => {})
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            i18n.global.t(
              "notifications.something_went_wrong_while_getting_app_translation",
            ),
          );
          reject(err);
        });
    });
  },
  SHOW_TRANSLATION_MODAL: ({ commit, dispatch }, payload) => {
    commit("SET_ENTITY", payload);
    if (payload.type === "import") {
      commit("SHOW_IMPORT_MODAL");
    }
    if (payload.type === "export") {
      commit("SHOW_EXPORT_MODAL");
    }
    if (payload.type === "files") {
      commit("SHOW_TRANSLATION_FILES_MODAL");
    }
  },
  CLOSE_TRANSLATION_MODAL: ({ commit, dispatch }, payload) => {
    if (payload === "import") {
      commit("CLOSE_IMPORT_MODAL");
    }
    if (payload === "export") {
      commit("CLOSE_EXPORT_MODAL");
    }
    if (payload === "files") {
      commit("CLOSE_TRANSLATION_FILES_MODAL");
    }
  },
};

const mutations = {
  SET_LOCALES: (state, payload) => {
    state.locales = payload;
  },
  SHOW_IMPORT_MODAL: (state) => {
    state.showImportModal = true;
  },
  SHOW_EXPORT_MODAL: (state) => {
    state.showExportModal = true;
  },
  SHOW_TRANSLATION_FILES_MODAL: (state) => {
    state.showTranslationFilesModal = true;
  },
  CLOSE_IMPORT_MODAL: (state) => {
    state.showImportModal = false;
  },
  CLOSE_EXPORT_MODAL: (state) => {
    state.showExportModal = false;
  },
  CLOSE_TRANSLATION_FILES_MODAL: (state) => {
    state.showTranslationFilesModal = false;
  },
  SET_ENTITY: (state, payload) => {
    state.entityId = payload.entityId;
    state.entityName = payload.entityName;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
