<template>
  <div
    id="main-track"
    class="flex flex-row border-t border-editorInput overflow-x-auto scrollbar-thin scrollbar-thumb-white scrollbar-thumb-rounded-full"
    :class="setHeight"
    @click="activeTrack !== 2 ? $emit('onClick') : undefined"
  >
    <div
      v-if="activeTrack === 2"
      class="sticky left-0 w-35 h-full flex flex-col bg-linearBlue cursor-pointer z-1"
      @click="$emit('onClick')"
    >
      <div class="bg-linearBlue flex justify-center items-center h-5">
        <font-awesome-icon
          icon="chevron-down"
          :class="this.rotate"
          class="text-white text-15 fa rotate"
        />
      </div>
      <div class="flex flex-1 justify-center items-center">
        <p class="vertical tracking-widest text-white py-1">
          {{ activeTrack !== 2 ? "" : $t("editor.main") }}
        </p>
      </div>
    </div>

    <div
      v-if="activeTrack !== 2"
      class="w-screen flex flex-row justify-center items-center bg-linearBlue hover:bg-blue-400 cursor-pointer z-1"
      @click="$emit('onClick')"
    >
      <div class="flex flex-row justify-center items-center mr-24">
        <div class="flex flex-1 justify-center items-center">
          <p class="tracking-widest text-white py-1">
            {{
              $t("editor.main_steps_container_number_steps", {
                number: steps.length,
              })
            }}
          </p>
        </div>
        <div class="flex justify-center items-center h-5 ml-3">
          <font-awesome-icon icon="chevron-up" class="text-white text-15" />
        </div>
      </div>
    </div>

    <div
      v-else
      class="w-100 pl-15 overflow-x-auto bg-gray w-full flex-col pr-200"
    >
      <div v-if="false">
        <Loader />
      </div>

      <div v-cloak class="h-100 w-100 py-5 pl-15 flex">
        <draggable
          :list="steps"
          :group="steps"
          class="flex flex-row"
          @end="changed($event)"
          :item-key="(element) => element.linear.id"
        >
          <template #item="{ element, index }">
            <LinearStep
              :key="element.linear.id"
              :index="index"
              :id="element.linear.id"
              :step-id="element.linear.id"
              :title="element.linear.name"
              :kind="element.linear.type"
              :sort="element.linear.sequence"
              :steps-length="element.length"
              :intended="element.linear.is_intended"
              :opportunity="element.linear.is_opportunity"
              :remark="element.linear.remark"
              :non-linear-random="element.linear.randomization"
              :repeatable="element.linear.repeatable"
              :max-score="element.linear.max_score"
              :required="element.linear.is_required"
              :error-state="element.linear.error_state"
              :enabled="element.linear.enabled"
              :on-checklist="element.linear.add_to_checklist"
              :children="element.children"
              :actions="element.linear.actions.data"
              :size="activeTrack === 2 ? 'L' : 'M'"
              :active-track="activeTrack"
              @addLinearBetween="addLinearBetween"
              @setTrack="activeTrack !== 2 ? $emit('onClick') : undefined"
            />
          </template>
        </draggable>
        <div
          v-if="activeTrack === 2 && !steps.length"
          class="flex flex-row items-center cursor-pointer h-20 w-250"
        >
          <div class="relative inline-block text-right">
            <div>
              <OptionMenu
                icon="plus"
                position="left"
                color="text-gray-50"
                :options="options"
                :text="$t('editor.add_new_linear_step')"
                @addLinearStep="addLinearStep"
                @importLinearStep="toggleModal"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="showImportModal">
        <ImportModal
          :header-text="$t('editor.import_step')"
          :sub-header-text="$t('select_a_valid_json_and_upload_it')"
          target="step"
          :sort="sequence"
          :step-id="activeStep.id"
          @close="toggleModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import LinearStep from "../components/steps/LinearStep";
import { mapState } from "vuex";
import OptionMenu from "@/components/helpers/OptionMenu";
import ImportModal from "@/views/editor/components/modals/ImportModal";
import sortStep from "@/mixins/sortStep";
import Loader from "@/views/editor/components/Loader";

export default {
  name: "MainTrack",
  components: {
    Loader,
    draggable,
    LinearStep,
    OptionMenu,
    ImportModal,
  },
  mixins: [sortStep],
  props: {
    activeTrack: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      rotate: "up",
      showImportModal: false,
      show: false,
      options: [
        { option: "addLinearStep", name: "Add new linear step" },
        { option: "importLinearStep", name: "Import linear step" },
      ],
    };
  },
  computed: {
    ...mapState("editorStore", [
      "mainSteps",
      "setupSteps",
      "activeStep",
      "activeLesson",
      "totalStepsLoaded",
    ]),
    setHeight: function () {
      if (this.activeTrack === 2) {
        return "h-full";
      } else if (this.activeTrack === 0) {
        return "h-35";
      } else {
        return "h-35";
      }
    },
    steps: function () {
      if (this.mainSteps.length) {
        return this.mainSteps;
      } else {
        return [];
      }
    },
    sequence: function () {
      if (
        this?.steps[this.steps.length - 1]?.children[
          this.steps[this.steps.length - 1]?.children.length - 1
        ]?.sequence
      ) {
        return this.steps[this.steps.length - 1].children[
          this.steps[this.steps.length - 1].children.length - 1
        ].sequence;
      } else if (!this.steps.length) {
        return this.setupSteps.length;
      } else {
        return 0;
      }
    },
  },
  watch: {
    activeTrack: {
      handler() {
        if (this.activeTrack === 2) {
          this.rotate = "down";
        } else {
          this.rotate = "up";
        }
      },
      deep: true,
    },
    steps: {
      handler(val) {
        this.stepsList = val;
      },
      deep: true,
    },
  },
  methods: {
    addLinearStep() {
      this.$store.dispatch("editorStore/SET_ACTIVE_STEP", {
        id: null,
        kind: "linear",
        type: "create",
        sort: this.sequence,
        activeType: "step",
      });
    },

    addLinearBetween(sort) {
      this.$store.dispatch("editorStore/SET_ACTIVE_STEP", {
        id: null,
        kind: "linear",
        type: "create",
        sort: sort,
        activeType: "step",
      });
    },

    insert: (list, index, item) => [
      ...list.slice(0, index),
      item,
      ...list.slice(index),
    ],

    toggleModal() {
      this.showImportModal = !this.showImportModal;
    },
  },
};
</script>

<style></style>
