export default {
  computed: {
    changeData() {
      const { email, firstname, lastname, password, repeatPassword } = this;
      return {
        email,
        firstname,
        lastname,
        password,
        repeatPassword,
      };
    },

    notSamePasswords() {
      if (this.fieldsFilled) {
        return this.password !== this.repeatPassword;
      } else {
        return false;
      }
    },
    fieldsFilled() {
      return this.password !== "" && this.repeatPassword !== "";
    },

    passwordValidation() {
      let errors = [];
      for (let condition of this.rules) {
        if (!condition.regex.test(this.password)) {
          errors.push(condition.message);
        }
      }
      if (errors.length === 0) {
        return { valid: true, errors };
      } else {
        return { valid: false, errors };
      }
    },
  },
  created() {
    this.rules = [
      {
        message: this.$t("auth.one_lowercase_letter_required"),
        regex: /[a-z]+/,
      },
      {
        message: this.$t("auth.one_uppercase_letter_required"),
        regex: /[A-Z]+/,
      },
      {
        message: this.$t("auth.eight_characters_minimum"),
        regex: /.{8,}/,
      },
      { message: this.$t("auth.one_number_required"), regex: /[0-9]+/ },
    ];
  },
};
