<template>
  <ModalContainer @cancel="close">
    <div
      class="bg-gray-50 rounded-lg overflow-hidden shadow-xl w-1/3 sm:w-3/4 transform transition-all sm:max-w-lg z-10"
      v-click-outside="close"
    >
      <div class="flex flex-col w-full h-auto bg-gray-50 p-5">
        <div class="flex flex-1 flex-row justify-between">
          <div class="mb-2">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              {{ $t("profile.set_profile_picture") }}
            </h3>
          </div>
          <div class="flex items-center justify-center">
            <font-awesome-icon
              icon="times"
              @click.stop="close"
              class="h-5 w-5 fa-sm text-gray-600 cursor-pointer group-hover:text-gray-300 group-focus:text-gray-300"
            />
          </div>
        </div>
        <div
          v-if="!isModal && !imgSrc"
          class="flex w-full h-96 mt-1 justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md"
          :class="{
            'border-blue-500 bg-blue-100': fileOverDropArea,
            'border-gray-300': !fileOverDropArea,
          }"
          @drop.prevent="dropFile"
          @dragover.prevent
          @dragenter.prevent="fileOverDropArea = true"
          @dragleave.prevent="fileOverDropArea = false"
        >
          <div
            class="flex flex-col justify-center align-middle space-y-1 text-center"
          >
            <svg
              class="mx-auto h-12 w-12 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div class="flex text-sm text-gray-600">
              <label
                for="file-upload"
                class="relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
              >
                <span>{{ $t("media.upload_a_file") }}</span>
                <input
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  accept="image/*"
                  class="sr-only"
                  @change="setImage($event.target.files[0])"
                />
              </label>
              <p class="pl-1">
                {{ $t("media.or_drag_and_drop") }}
              </p>
            </div>
          </div>
        </div>
        <div
          v-if="isModal"
          class="flex flex-col w-full h-96 justify-center items-center"
        >
          <div
            class="flex w-full h-full justify-center items-center mb-3 inline-block"
          >
            <VueCropper
              ref="cropper"
              :guides="false"
              :view-mode="1"
              drag-mode="move"
              :background="true"
              :rotatable="true"
              :src="imgSrc"
              :auto-crop-area="1"
              :crop-box-resizable="true"
              :aspect-ratio="1"
              :img-style="{ width: '500px', height: '300px' }"
            ></VueCropper>
          </div>
          <div class="flex flex-1 flex-row justify-center items-center">
            <Button
              v-if="imgSrc"
              class="mr-3"
              type="submit"
              button-type="primary"
              :title="$t('profile.crop')"
              @clickAction="
                cropImage();
                closeModal();
              "
            />
            <Button
              v-if="imgSrc"
              class="mr-3"
              type="submit"
              button-type="primary"
              :title="$t('profile.rotate')"
              @clickAction="rotate"
            />
          </div>
        </div>
        <div
          v-if="cropImg"
          class="flex flex-col justify-center items-center w-full h-96 flex-1 p-3"
        >
          <div class="flex flex-1 mb-8 mt-6">
            <img class="w-64 h-64 rounded-full" :src="cropImg" />
          </div>
          <div class="flex flex-row w-full justify-between items-center">
            <Button
              class="mr-2"
              type="submit"
              button-type="secondary"
              :title="$t('general.back')"
              @clickAction="backToCropping"
            />

            <div class="text-sm leading-5 text-gray-20">
              <input
                type="file"
                name="image"
                :id="id"
                accept="image/*"
                class="hidden"
                @change="setImage($event.target.files[0])"
              />
              <label
                :for="id"
                class="flex items-center cursor-pointer justify-center border border-transparent h-10 mr-2 p-3 py-2 rounded-md text-base leading-6 font-medium text-white font-medium bg-primaryColor hover:bg-primaryOpacityButton focus:outline-none transition ease-in-out duration-150 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50"
              >
                {{ $t("profile.change_picture") }}
              </label>
            </div>
            <Button
              type="submit"
              button-type="primary"
              :title="$t('general.save')"
              @clickAction="save"
            />
          </div>
        </div>
      </div>
    </div>
  </ModalContainer>
</template>

<script>
import ModalContainer from "@/components/helpers/ModalContainer";
import VueCropper from "vue-cropperjs";
import Button from "@/components/form/Button";
import uploadFile from "@/mixins/uploadFile";
import axios from "axios";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const uploadRepository = RepositoryFactory.get("upload");
import { errorHandlingAndMessage } from "@/services/ErrorService";
import { showNotification } from "@/services/notificationService";
import "cropperjs/dist/cropper.css";

export default {
  components: {
    VueCropper,
    ModalContainer,
    Button,
  },
  mixins: [uploadFile],
  props: {
    id: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      imgSrc: "",
      cropImg: "",
      isModal: false,
      fileOverDropArea: false,
      file: null,
    };
  },
  methods: {
    dropFile(e) {
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      this.file = droppedFiles[0];
      this.fileOverDropArea = false;
      this.setImage(droppedFiles[0]);
    },
    setImage(e) {
      const file = e;
      this.cropImg = null;
      if (!file.type.includes("image/")) {
        showNotification(
          this.$t("notifications.please_select_an_image_file"),
          "error",
        );
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        showNotification(
          this.$t("notifications.filereader_api_not_supported"),
          "error",
        );
      }
      this.openModal();
    },
    cropImage() {
      this.cropImg = this.$refs.cropper
        .getCroppedCanvas({
          width: 160,
          height: 90,
          minWidth: 256,
          minHeight: 256,
          maxWidth: 4096,
          maxHeight: 4096,
          fillColor: "#fff",
          imageSmoothingEnabled: false,
          imageSmoothingQuality: "high",
        })
        .toDataURL();
    },
    rotate() {
      this.$refs.cropper.rotate(90);
    },
    openModal() {
      this.isModal = true;
    },
    closeModal() {
      this.isModal = false;
    },
    backToCropping() {
      this.cropImg = null;
      this.isModal = true;
    },
    close() {
      this.$emit("close");
    },
    save() {
      fetch(this.cropImg)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], `${this.id}.png`, {
            lastModified: new Date().getTime(),
            type: blob.type,
          });
          this.uploadFile(file);
        });
    },
    uploadFile(file) {
      uploadRepository
        .createSignedUrl(file["name"])
        .then(async (result) => {
          await axios
            .put(result.data.data.url, file)
            .then(() => {
              const fileUrl = result.data.data.url.split("?")[0];
              this.$emit("setImage", fileUrl, this.cropImg);
              this.close();
            })
            .catch((err) => {
              this.uploadError = true;
              errorHandlingAndMessage(
                err,
                this.$t(
                  "notifications.something_went_wrong_uploading_this_file",
                ),
              );
            })
            .finally(() => {});
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t("notifications.something_went_wrong_uploading_this_file"),
          );
        });
    },
  },
};
</script>
