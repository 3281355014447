<template>
  <div class="w-full">
    <div class="flex justify-between">
      <label
        for="input"
        class="block text-sm font-medium leading-5 text-gray-200"
      >
        {{ title }}
        <span
          v-if="requiredInput"
          class="text-gray-200"
          v-tooltip="{
            content: $t('game.required'),
            boundary: 'window',
          }"
        >
          {{ "*" }}
        </span>
      </label>

      <div class="flex">
        <div
          v-if="hint"
          class="flex justify-center bg-white items-center h-3 w-3 rounded-full mt-1 ml-1"
          v-tooltip="{ content: hint, boundary: 'window' }"
        >
          <p class="text-sidebar font-bold text-9">?</p>
        </div>
      </div>
    </div>
    <div class="mt-1 relative rounded-md shadow-sm">
      <textarea
        v-on="validationListeners"
        :id="placeholder"
        :style="{
          borderColor: activeColor,
        }"
        :rows="rows"
        :value="inputValue"
        :placeholder="placeholder"
        @focusin="setFocus(true)"
        @focusout="setFocus(false)"
        class="form-textarea block w-full text-gray-200 placeholder-gray-600 bg-sidebar transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        :class="{
          'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red':
            errorMessage,
        }"
      ></textarea>
      <div
        v-if="errorMessage"
        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
      >
        <svg
          class="h-5 w-5 text-red-500"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </div>
    <div class="h-6 mt-px">
      <p class="text-sm text-red-600">
        {{ errorMessage }}
      </p>
    </div>
  </div>
</template>

<script>
import { disableShortcuts } from "@/services/disableShortcutsService";
import useValidation from "@/helpers/useValidation";

export default {
  name: "TextArea",
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Title",
    },
    placeholder: {
      type: String,
      default: "Placeholder",
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    requiredInput: {
      type: Boolean,
      default: false,
    },
    activeColor: {
      type: String,
      default: "#6b7280",
    },
    hint: {
      type: String,
      default: "",
    },
    rows: {
      type: Number,
      default: 3,
    },
    rules: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue", "updateField", "preventSubmit"],
  setup(props, { emit }) {
    const { validationListeners, errorMessage, inputValue, meta, updateField } =
      useValidation(props, emit);

    return {
      validationListeners,
      errorMessage,
      inputValue,
      updateField,
      meta,
    };
  },
  data() {
    return {
      focus: false,
    };
  },
  methods: {
    setFocus(boolean) {
      this.focus = boolean;
      this.$emit("preventSubmit", boolean);
      disableShortcuts(boolean);
    },
  },
};
</script>
