<template>
  <div
    id="highscore-row"
    class="mt-10 flex items-center shadow-darkBgCard bg-standardBgColor pr-5 flex-row flex-1 justify-between mobile:h-35"
  >
    <div class="flex-1 py-2 pl-2 mobile:hidden">
      <div class="w-10 h-55 flex bg-lightBlue">
        <p
          class="text-22 self-center text-left flex-1 pl-5 z-10 -mr-10 text-white"
        >
          {{ position }}
        </p>
        <div class="bg-lightBlue skew-20 pl-35"></div>
      </div>
    </div>

    <p
      v-if="isMobile()"
      class="text-17 text-darkAlineaColor self-center text-left flex-1 pl-5 font-bold mobile:pl-14"
    >
      {{ position }}
    </p>

    <div
      class="flex-2 flex pl-5 items-center mobile:pl-0 mobile:text-16 mobile:flex-5"
    >
      <div
        class="h-10 w-10 bg-lightBlue rounded-full mobile:h-6 mobile:w-6"
      ></div>
      <div class="pl-10 flex flex-1 flex-col justify-center">
        <div class="bg-lightBlue h-18 w-50% mobile:h-3"></div>
        <!-- <div class="bg-lightBlue h-14 w-30% mt-1 mobile:h-8 mobile:mt-2"></div> -->
      </div>
    </div>
    <div
      class="text-darkAlineaColor self-center h-18 flex-1 mobile:flex-3 mobile:h-3"
    >
      <div class="bg-lightBlue w-30% h-100"></div>
    </div>
    <div
      class="text-darkAlineaColor self-center h-18 flex-1 mobile:flex-3 mobile:h-3"
    >
      <div class="bg-lightBlue w-50% h-100"></div>
    </div>
    <div class="text-darkAlineaColor self-center h-18 flex-1 mobile:hidden">
      <div class="bg-lightBlue w-30% h-100"></div>
    </div>
    <div
      class="text-darkAlineaColor self-center h-18 flex-1 mobile:flex-3 mobile:h-3"
    >
      <div class="bg-lightBlue w-60% h-100"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HighscoresRow",
  components: {},
  props: {
    position: {
      type: String,
      default: "#",
    },
  },
  data() {
    return {};
  },
  methods: {
    isMobile() {
      return screen.width <= 820;
    },
  },
};
</script>

<style></style>
