<template>
  <PageContentContainer>
    <div class="grid grid-cols-1 gap-6">
      <ListHeader
        :buttons="buttonSettings"
        @buttonAction="showCreateModal = true"
        @searchAction="searchAction"
      />
      <div class="backgroundRow shadow overflow-hidden sm:rounded-md">
        <div class="flex flex-col">
          <div
            class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
          >
            <div
              class="align-middle inline-block min-w-full overflow-hidden sm:rounded-lg"
            >
              <div
                v-if="loading"
                class="py-10 flex items-center justify-center"
              >
                <Loader />
              </div>
              <div v-else>
                <table class="min-w-full" data-cy="usersTable">
                  <thead class="backgroundRow">
                    <tr>
                      <th
                        v-for="(header, index) in columnHeaders"
                        :key="`columnHeaders-${index}`"
                        class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                        :class="{
                          'text-gray-100': header === 'name',
                          'text-gray-300': header !== 'name',
                          'cursor-pointer': isSortable(header),
                        }"
                        @click="isSortable(header) ? sort(header) : ''"
                      >
                        <div
                          class="flex flex-row justify-between align-center"
                          :class="{
                            'hover:text-white': isSortable(header),
                          }"
                        >
                          <p>{{ $t(`users.${header}`) }}</p>

                          <font-awesome-layers class="ml-1 mr-1">
                            <font-awesome-icon
                              v-if="header === currentSort"
                              icon="sort-up"
                              class="fa-lg"
                              :class="
                                currentSortDir === 'DESC'
                                  ? 'text-white'
                                  : 'text-gray-400'
                              "
                            />
                            <font-awesome-icon
                              v-if="header === currentSort"
                              icon="sort-down"
                              class="fa-lg"
                              :class="
                                currentSortDir === 'ASC'
                                  ? 'text-white'
                                  : 'text-gray-400'
                              "
                            />
                          </font-awesome-layers>
                        </div>
                      </th>
                      <th
                        class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-400 uppercase tracking-wider"
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    <UsersRow
                      v-for="userData in users"
                      :key="userData.id"
                      :id="userData.id"
                      :user="userData"
                      :name="`${userData.lastname} ${userData.firstname}`"
                      :enabled="userData.active"
                      :email="userData.email"
                      :last-played-at="userData.last_time_played"
                      :customer="userData.customer ? userData.customer : ''"
                      :dealer="userData.dealer ? userData.dealer : ''"
                      @delete="showDeleteUserModal(userData.id)"
                      @edit="editUser(userData)"
                    />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        class="justify-self-end"
        :total-pages="totalPages"
        :total-amount="totalEntries"
        :page-amount="users.length"
        :current-page="currentPage"
        :max-result="params.maxResult"
        @next="nextPage"
        @previous="previousPage"
        @toPage="toPage"
        @changeAmountToShow="changeAmountToShow"
      />
    </div>
    <CreateUserModal
      v-if="showCreateModal"
      @getUsers="getUsers"
      @close="showCreateModal = false"
    />
    <EditModal
      v-if="showEditModal"
      :user="user"
      @close="closeEditModal"
      @getUsers="getUsers"
    />
    <DeleteUserModal
      @keydown.esc="showDeleteModal = false"
      v-if="showDeleteModal"
      @delete="deleteUser()"
      @cancel="showDeleteModal = false"
    />
  </PageContentContainer>
</template>

<script>
import UsersRow from "./components/UsersRow";
import Pagination from "@/components/helpers/Pagination";
import PageContentContainer from "@/components/helpers/PageContentContainer";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import Loader from "@/components/helpers/Loader";
import CreateUserModal from "@/components/helpers/modals/CreateUserModal";
import EditModal from "./EditModal";
import DeleteUserModal from "./DeleteUserModal";
import ListHeader from "@/components/helpers/ListHeader";
import { showNotification } from "@/services/notificationService.js";
import paginationHelper from "@/mixins/paginationHelper";

const usersRepository = RepositoryFactory.get("user");

export default {
  name: "Users",
  components: {
    PageContentContainer,
    UsersRow,
    CreateUserModal,
    EditModal,
    DeleteUserModal,
    Loader,
    Pagination,
    ListHeader,
  },
  mixins: [paginationHelper],
  data: () => {
    return {
      users: [],
      showCreateModal: false,
      showEditModal: false,
      showDeleteModal: false,
      user: {},
      loading: true,
      columnHeaders: ["name", "active", "email", "last_time_played", "company"],
      sortableColumnHeaders: ["name", "active", "email", "last_time_played"],
      params: {
        page: "1",
        sort: "ASC",
        sortfield: "name",
        maxResult: localStorage.getItem("maxresult-users") || "7",
        query: "",
      },
      buttonSettings: [
        {
          title: "button.add_user",
          action: "buttonAction",
        },
      ],
    };
  },
  created() {
    this.getUsers();
  },
  methods: {
    getEntries() {
      this.getUsers();
    },
    editUser(user) {
      this.user = user;
      this.showEditModal = true;
    },
    getUsers() {
      usersRepository
        .getUsers(this.params)
        .then((result) => {
          this.users = result.data.data;
          const { total, total_pages } = result.data.meta.pagination;
          this.totalEntries = total;
          this.totalPages = total_pages;
          this.loading = false;
        })
        .catch(() => {});
    },
    setMaxResults(val) {
      localStorage.setItem("maxresult-users", val);
    },
    deleteUser() {
      usersRepository
        .deleteUser(this.userDeleteId)
        .then(() => {
          this.getUsers();
          this.showDeleteModal = false;
          showNotification(
            this.$t("notifications.user_deleted"),
            this.$t("notifications.user_is_successfully_deleted"),
            "success",
          );
        })
        .catch(() => {
          this.showDeleteModal = false;
          showNotification(
            this.$t("notifications.delete_deleted"),
            this.$t("notifications.something_went_wrong_deleting_the_user"),
            "error",
          );
        });
    },
    isSortable(header) {
      return this.sortableColumnHeaders.includes(header);
    },
    showDeleteUserModal(user) {
      this.showDeleteModal = true;
      this.userDeleteId = user;
    },
    closeEditModal() {
      this.showEditModal = false;
    },
  },
};
</script>

<style>
.justify-self-end {
  justify-self: flex-end;
}
</style>
