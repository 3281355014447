<template>
  <div class="flex flex-1 flex-col p-2">
    <div class="flex justify-between mb-3">
      <h3 class="block text-base font-medium text-gray-50">
        {{
          `${$t("editor.non_linear_steps")} (${nonLinearStepResults.length})`
        }}
      </h3>
    </div>
    <div class="flex flex-col justify-between">
      <div
        v-for="(nonLinearStep, index) in nonLinearStepResults"
        :key="nonLinearStep.id"
        class="block bg-nonLinearBlue shadow text-white p-2 mb-2 hover:bg-activeAction"
        @mouseover="showJumpMessage = index"
        @mouseleave="showJumpMessage = null"
      >
        <div
          class="flex flex-row w-full justify-between items-center cursor-pointer overflow-hidden"
          @click="pickResult(nonLinearStep.id)"
        >
          <div class="w-2/3">
            {{ nonLinearStep.name }}
          </div>
          <div
            class="flex justify-end w-1/3"
            :class="showJumpMessage === index ? 'visible' : 'invisible'"
          >
            {{ $t("editor.jump_to") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NonLinearStepResults",
  props: {
    nonLinearStepResults: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showJumpMessage: false,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    pickResult(result) {
      const pickedResult = {
        searchTarget: "nonLinear",
        searchTargetId: result,
      };
      this.$emit("pickResult", pickedResult);
    },
  },
};
</script>

<style></style>
