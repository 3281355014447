import Repository from "./Repository";

const resource = "/api/v3/gamecharacters";

export default {
  getGameCharacterSettings() {
    return Repository.get(`${resource}`);
  },

  getGameCharacter(id) {
    return Repository.get(`${resource}/${id}`);
  },

  updateGameCharacter(payload) {
    return Repository.patch(`${resource}/${payload.id}`, {
      gamecharacter: {
        name: payload.name,
        voices: payload.voices,
      },
    });
  },

  createGameCharacter(payload) {
    return Repository.post(`${resource}`, {
      gamecharacter: {
        name: payload.name,
        voices: payload.voices,
      },
    });
  },

  deleteGameCharacter(payload) {
    return Repository.delete(`${resource}/${payload}`);
  },
};
