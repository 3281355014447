<template>
  <ModalContainer @cancel="close">
    <div
      class="flex flex-col justify-between w-1/2 h-2/3 min-w-800 bg-gray-100 p-4 rounded-md pb-5"
      v-click-outside="close"
    >
      <div class="flex justify-end">
        <button
          @click="close"
          class="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
        >
          <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div class="ml-4">
        <strong>{{ `${$t("editor.score_overview")} - ` }}</strong>
        <strong> {{ activeLesson.name }}</strong>
      </div>
      <div class="flex flex-col h-4/5 mt-4">
        <div
          class="-my-2 overflow-x-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
        >
          <div class="align-middle inline-block w-full">
            <div class="overflow-hidden">
              <table class="table inline-block w-full divide-y divide-gray-200">
                <thead class="bg-gray-50 w-full">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      #
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {{ $t("editor.active") }}
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {{ $t("editor.type") }}
                    </th>
                    <th
                      scope="col"
                      class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {{ $t("editor.step") }}
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {{ $t("editor.behaviour") }}
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {{ $t("editor.max_score") }}
                    </th>
                  </tr>
                </thead>
                <template v-if="stepArray.length">
                  <tbody
                    class="bg-white divide-y divide-gray-200 overflow-y-auto w-full"
                  >
                    <tr v-for="steps in stepArray" :key="steps.id">
                      <td
                        class="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                      >
                        {{ steps.sequence + 1 }}
                      </td>
                      <td
                        class="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                      >
                        <span
                          v-if="steps.enabled"
                          class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-300 text-green-600"
                        >
                          {{ $t("editor.enabled") }}
                        </span>
                        <span
                          v-else
                          class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-300 text-red-600"
                        >
                          {{ $t("editor.disabled") }}
                        </span>
                      </td>
                      <td
                        class="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                      >
                        <span
                          v-if="steps.type === 'linear'"
                          class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full text-white"
                          :class="
                            steps.enabled ? 'bg-linearBlue' : 'bg-gray-600'
                          "
                        >
                          {{ $t("editor.linear") }}
                        </span>
                        <span
                          v-else
                          class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-nonLinearBlue text-white"
                          :class="
                            steps.enabled ? 'bg-nonLinearBlue' : 'bg-gray-600'
                          "
                        >
                          {{ $t("editor.non_linear") }}
                        </span>
                      </td>
                      <td
                        class="px-4 py-4 text-sm break-words"
                        :class="
                          steps.enabled ? 'text-gray-700' : 'text-gray-400'
                        "
                      >
                        {{ steps.name }}
                      </td>
                      <td class="px-4 py-4 whitespace-nowrap text-sm">
                        <div class="ml-1 px-2 rounded-full bg-green-500">
                          <span
                            v-if="steps.is_intended"
                            class="inline-flex text-xs leading-5 font-semibold text-green-100"
                          >
                            {{ $t("editor.correct_behaviour") }}
                          </span>
                        </div>
                      </td>
                      <td
                        class="px4 py-4 whitespace-nowrap text-sm text-center"
                        :class="
                          steps.enabled
                            ? 'text-gray-700'
                            : 'text-gray-400 line-through'
                        "
                      >
                        {{ steps.max_score ? steps.max_score : 0 }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end mr-24 mt-3">
        <div class="w-1/8 px-4 py-4">
          <div>
            <div class="flex">
              <strong>{{ $t("editor.total_steps") }}</strong>
            </div>
          </div>
        </div>
        <div class="w-1/8 px-4 py-4">
          <div>
            <div class="flex">
              <span>{{ totalSteps }}</span>
            </div>
          </div>
        </div>
        <div class="w-1/8 px-4 py-4">
          <div>
            <div>
              <strong>{{ $t("editor.total_score") }}</strong>
            </div>
          </div>
        </div>
        <div class="w-1/8 px-4 py-4">
          <div>
            <div>
              <span>{{ totalScore }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ModalContainer>
</template>

<script>
import { mapState } from "vuex";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const trainingRepository = RepositoryFactory.get("training");
import ModalContainer from "@/components/helpers/ModalContainer";

export default {
  name: "AddChapter",
  components: { ModalContainer },
  data() {
    return {
      stepArray: [],
      totalScore: 0,
      totalSteps: 0,
    };
  },
  computed: {
    ...mapState("editorStore", ["setupSteps", "mainSteps", "activeLesson"]),
  },
  mounted() {
    const allSteps = [{ children: this.setupSteps }, ...this.mainSteps];
    allSteps.forEach((step) => {
      if (step.children.length) {
        step.children.map((child) => {
          this.stepArray.push(child);
        });
      }
      if (step.linear) {
        this.stepArray.push(step.linear);
      }
    });

    this.stepArray.sort((a, b) => {
      return a.sequence - b.sequence;
    });

    this.totalSteps = this.stepArray.length
      ? this.stepArray.filter(({ enabled }) => enabled === true).length
      : 0;

    this.totalScore = this.stepArray.length
      ? this.stepArray
          .filter(({ enabled }) => enabled === true)
          .reduce((acc, item) => {
            return acc + item.max_score;
          }, 0)
      : 0;
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
