<template>
  <ModalContainer @cancel="cancel">
    <div class="bg-white shadow sm:rounded-lg" v-click-outside="cancel">
      <div class="px-4 py-5 sm:p-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          {{ $t("layout.reset_password_title") }}
        </h3>
        <div class="mt-2 max-w-xl text-sm leading-5 text-gray-500">
          <p>
            {{
              error
                ? error
                : sended
                ? $t("layout.reset_password_send")
                : $t("layout.reset_password_text")
            }}
          </p>
        </div>
        <div class="mt-5">
          <Button
            type="primary"
            :title="$t('button.send')"
            @clickAction="send"
          />
        </div>
      </div>
    </div>
  </ModalContainer>
</template>

<script>
import ModalContainer from "../ModalContainer";
import Button from "../../helpers/Button";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const authRepository = RepositoryFactory.get("auth");
import { errorHandlingAndMessage } from "@/services/ErrorService";
import { showNotification } from "@/services/notificationService";

export default {
  name: "ResetPasswordModal",
  components: {
    ModalContainer,
    Button,
  },
  props: {
    email: {
      type: String,
      default: "",
    },
  },
  emits: ["cancel"],
  data() {
    return {
      activationCode: "",
      activationError: true,
      loading: true,
      sended: false,
      error: "",
    };
  },
  methods: {
    send() {
      authRepository
        .resetPassword(this.email)
        .then(() => {
          this.sended = true;
          showNotification(
            this.$t("notifications.password_reset"),
            this.$t("notifications.email_sent"),
            "success",
          );
          this.$emit("cancel");
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t(
              "notifications.something_went_wrong_resetting_the_password",
            ),
          );
          this.error = this.$t("layout.something_went_wrong");
        });
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>
