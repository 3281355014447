<template>
  <ModalContainer>
    <div
      class="flex flex-col w-250 bg-white rounded-lg overflow-hidden shadow-xl transform transition-all"
    >
      <div class="flex bg-white shadow sm:rounded-lg justify-center">
        <div class="px-4 py-5 sm:p-6">
          <h3
            class="flex text-lg leading-6 font-medium text-gray-900 justify-center"
          >
            {{ headerText }}
          </h3>
          <div
            class="flex flex-col justify-center mt-2 max-w-xl text-sm leading-5 text-gray-500"
          >
            <p class="text-center">
              {{ message }}
            </p>
            <p v-if="completed" class="text-center mt-3">
              {{ $t("download.this_form_will_close_in_5_seconds") }}
            </p>
          </div>
          <div v-if="loading" class="flex justify-center mt-5 content-center">
            <Loader />
          </div>
        </div>
      </div>
      <div class="flex bg-gray-50 py-6 sm:px-6 justify-center">
        <div v-if="!loading && !completed">
          <input
            type="file"
            name="file"
            id="file"
            class="hidden"
            @change="initializeImport($event.target.files)"
          />
          <label
            for="file"
            class="py-3 px-3 rounded-md text-sm leading-4 w-24 mr-6 flex justify-center items-center font-medium bg-secondaryColor text-lightAlineaColor cursor-pointer"
          >
            {{ $t("editor.upload_file") }}
          </label>
        </div>
        <Button
          type="secondary"
          :title="completed ? $t('general.close') : $t('general.cancel')"
          @clickAction="$emit('close')"
        />
      </div>
    </div>
  </ModalContainer>
</template>

<script>
import ModalContainer from "../../../../components/helpers/ModalContainer";
import Loader from "@/views/editor/components/Loader";
import Button from "../../../../components/form/Button";
import { errorHandlingAndMessage } from "@/services/ErrorService";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import { showNotification } from "@/services/notificationService";
import { mapState } from "vuex";
const lessonRepository = RepositoryFactory.get("lesson");
const trainingRepository = RepositoryFactory.get("training");

export default {
  name: "ImportModal",
  components: { ModalContainer, Loader, Button },
  props: {
    headerText: {
      type: String,
      default: "",
    },
    subHeaderText: {
      type: String,
      default: "",
    },
    target: {
      type: String,
      default: "",
    },
    trainingId: {
      type: String,
      default: "",
    },
    versionId: {
      type: String,
      default: "",
    },
    subchapterId: {
      type: String,
      default: "",
    },
    sort: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      completed: false,
    };
  },
  computed: {
    ...mapState("editorStore", ["activeLesson"]),
    message: function () {
      if (!this.loading && !this.completed) {
        return this.subHeaderText;
      }
      if (this.loading && !this.completed) {
        return "";
      } else {
        return this.$t("editor.import_completed");
      }
    },
  },
  methods: {
    initializeImport(file) {
      this.loading = true;
      if (this.target === "step") {
        this.importStep(file);
      }
      if (this.target === "lesson") {
        this.importLesson(file);
      }
      if (this.target === "game") {
        this.importGame(file);
      }
    },
    importLesson(file) {
      trainingRepository
        .importLesson(
          this.trainingId,
          this.versionId,
          this.subchapterId,
          file[0],
        )
        .then(() => {
          this.$store.dispatch("trainingStore/GET_CHAPTERS");
          this.completed = true;
          this.closeModal();
          showNotification(
            this.$t("notifications.import_lesson"),
            this.$t("notifications.lesson_has_been_successfully_imported"),
            "success",
          );
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t(
              "notifications.something_went_wrong_while_exporting_this_json",
            ),
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    importStep(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const json = e.target.result;

        const payload = {
          sequence: this.sort,
          file: json,
        };
        lessonRepository
          .importStep(this.activeLesson.id, payload)
          .then(() => {
            this.$store.dispatch("editorStore/GET_CHAPTER_TREE");
            this.completed = true;
            this.closeModal();
            showNotification(
              this.$t("notifications.import_step"),
              this.$t("notifications.step_has_been_successfully_imported"),
              "success",
            );
          })
          .catch((err) => {
            errorHandlingAndMessage(
              err,
              this.$t(
                "notifications.something_went_wrong_while_importing_this_step",
              ),
            );
          })
          .finally(() => {
            this.loading = false;
          });
      };
      reader.readAsText(file[0]);
    },
    importGame(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const json = e.target.result;

        const payload = {
          sequence: this.sort + 1,
          file: json,
        };
        lessonRepository
          .importStep(this.activeLesson.id, payload)
          .then(() => {
            this.$store.dispatch("editorStore/GET_CHAPTER_TREE");
            this.completed = true;
            this.closeModal();
            showNotification(
              this.$t("notifications.import_step"),
              this.$t("notifications.step_has_been_successfully_imported"),
              "success",
            );
          })
          .catch((err) => {
            errorHandlingAndMessage(
              err,
              this.$t(
                "notifications.something_went_wrong_while_importing_this_step",
              ),
            );
          })
          .finally(() => {
            this.loading = false;
          });
      };
      reader.readAsText(file[0]);
    },
    closeModal() {
      setTimeout(() => this.$emit("close"), 5000);
    },
  },
};
</script>

<style></style>
