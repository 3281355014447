<template>
  <div class="flex flex-1 flex-col justify-end w-full">
    <div
      v-if="loading"
      class="flex flex-col flex-1 height justify-center items-center"
    >
      <Loader />
    </div>
    <div v-else class="flex flex-col flex-1 height">
      <SetupTrack
        ref="setupTrack"
        :class="{ 'flex-auto': activeTrack === 0 }"
        :active-track="activeTrack"
        @onClick="setActiveTrack(1)"
      />

      <!-- <ListenerTrack
      ref="listenerTrack"
      :class="{ 'flex-auto': activeTrack === 0 }"
      :active-track="activeTrack"
      @onClick="setActiveTrack(2)"
    /> -->
      <MainTrack
        ref="mainTrack"
        :height="700"
        :class="{ 'flex-auto': activeTrack === 0 }"
        :active-track="activeTrack"
        @onClick="setActiveTrack(2)"
      />
    </div>
    <div id="timeTrack" class="timeline-pattern h-4"></div>
    <SearchModal
      v-if="showSearchModal"
      :active-track="activeTrack"
      @setTrack="setActiveTrack"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";
import SetupTrack from "./tracks/SetupTrack";
import Loader from "@/views/editor/components/Loader";
import MainTrack from "./tracks/MainTrack";
import SearchModal from "@/views/editor/components/modals/searchModal/SearchModal";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import { getOS } from "@/helpers/osCheck";
import keyboardShortcuts from "@/mixins/keyboardShortcuts";
import { showNotification } from "@/services/notificationService";
import { nextTick } from "vue";
const trainingRepository = RepositoryFactory.get("training");
const lessonRepository = RepositoryFactory.get("lesson");

export default {
  name: "Timeline",
  components: { SetupTrack, MainTrack, Loader, SearchModal },
  mixins: [keyboardShortcuts],
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      activeTrack: 2,
      setupTrack: false,
      listenerTrack: false,
      mainTrack: false,
      loading: false,
      operatingSystem: "windows",
    };
  },
  computed: {
    ...mapState("trainingStore", [
      "training_version",
      "chapters",
      "showLevelModal",
      "versionId",
      "trainingId",
      "level",
    ]),
    ...mapState("editorStore", [
      "activeAction",
      "activeStep",
      "activeType",
      "activeLesson",
      "mainSteps",
    ]),
    ...mapState("translationStore", ["showImportModal", "showExportModal"]),
    ...mapState("searchStore", ["showSearchModal"]),
    ...mapState("keyboardShortcutStore", [
      "collapseAll",
      "disableShortcuts",
      "clipboard",
    ]),
  },
  created() {
    window.addEventListener("keydown", this.captureShortcut);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.captureShortcut);
    // Empty active item to prevent reopening in another lesson
    this.$store.dispatch("editorStore/SET_ACTIVE_ACTION", {
      id: "",
      type: "",
      activeType: "",
      stepId: "",
    });

    this.$store.dispatch("searchStore/CLEAR_SEARCH");
  },
  mounted() {
    this.loading = true;
    this.operatingSystem = getOS();

    lessonRepository
      .getLesson(this.id)
      .then((result) => {
        const lesson = result.data.data;
        this.$store.dispatch("editorStore/SET_ACTIVE_LESSON", {
          lesson,
        });
        this.$store.dispatch(
          "trainingStore/SET_CURRENT_VERSION",
          lesson.unity_package.data.id,
        );
        this.loading = false;
      })
      .catch(() => {
        showNotification(
          this.$t(
            "notifications.something_went_wrong_while_fetching_this_level",
          ),
          "error",
        );
      });
  },
  methods: {
    log: function (evt) {},
    setActiveTrack(trackNum) {
      if (this.activeTrack === trackNum) {
        this.activeTrack = 0;
      } else {
        this.activeTrack = trackNum;
      }
      if (this.collapseAll !== 1) {
        this.$store.dispatch("keyboardShortcutStore/TOGGLE_COLLAPSE_ALL", 1);
      }
    },
    captureShortcut(e) {
      if (!this.disableShortcuts) {
        // COPY ID
        if (
          e.keyCode === 73 &&
          e.shiftKey &&
          e.ctrlKey &&
          this.activeType &&
          (this.activeStep || this.activeAction)
        ) {
          e.preventDefault();
          this.copyId();
        }

        // SHOW FULL TITLES
        if (e.shiftKey && e.keyCode === 78) {
          e.preventDefault();
          this.showFullTitles();
        }

        // EXPAND STEPS
        if ((e.shiftKey && e.key === "=") || (e.shiftKey && e.key === "+")) {
          e.preventDefault();
          this.expandSteps();
        }

        // COLLAPSE STEPS
        if ((e.shiftKey && e.key === "_") || (e.shiftKey && e.key === "-")) {
          e.preventDefault();
          this.collapseSteps();
        }

        // COPY STEP/ACTION
        if (
          this.activeType &&
          e.keyCode === 67 &&
          ((this.operatingSystem === "macos" && e.metaKey) ||
            (this.operatingSystem === "windows" && e.ctrlKey))
        ) {
          e.preventDefault();
          this.copyActive();
        }

        // PASTE STEP/ACTION
        if (
          e.keyCode === 86 &&
          ((this.operatingSystem === "macos" && e.metaKey) ||
            (this.operatingSystem === "windows" && e.ctrlKey))
        ) {
          e.preventDefault();
          this.pasteClipboard();
        }

        // CUT STEP/ACTION
        if (
          this.activeType &&
          e.keyCode === 88 &&
          ((this.operatingSystem === "macos" && e.metaKey) ||
            (this.operatingSystem === "windows" && e.ctrlKey))
        ) {
          e.preventDefault();
          this.cutActive();
        }
      }
    },
  },
};
</script>

<style>
.height {
  height: calc(100% - 4rem);
}

.vertical {
  writing-mode: vertical-rl;
}

.track {
  min-height: 115px;
}

.timeline-pattern {
  min-height: 40px;
  display: flex;
  background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.17) 6px,
      transparent 6px
    ),
    linear-gradient(
      to right,
      transparent 12px,
      rgba(255, 255, 255, 0.17) 12px,
      rgba(255, 255, 255, 0.17) 14px,
      transparent 14px,
      transparent 20px,
      rgba(255, 255, 255, 0.17) 20px,
      rgba(255, 255, 255, 0.17) 22px,
      transparent 22px,
      transparent 28px,
      rgba(255, 255, 255, 0.17) 28px,
      rgba(255, 255, 255, 0.17) 30px,
      transparent 30px,
      transparent 36px,
      rgba(255, 255, 255, 0.17) 36px,
      rgba(255, 255, 255, 0.17) 38px,
      transparent 38px
    );
  background-repeat: repeat-x;
  background-size:
    44px 20px,
    44px 10px;
  background-position: 10px 0px;
  border-top: 2px solid rgba(255, 255, 255, 0.17);
}
</style>
