<template>
  <div
    class="fixed inset-0 flex items-end justify-center px-4 py-4 pointer-events-none sm:p-4 sm:items-start sm:justify-end z-50"
  >
    <transition name="right">
      <div
        v-if="showNotification"
        class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto mr-4"
        data-cy="notification"
      >
        <div
          class="rounded-lg ring-1 ring-black ring-opacity-5 overflow-hidden"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <font-awesome-icon
                  :icon="icon"
                  class="text-16 mt-1"
                  :class="[color]"
                />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm leading-5 font-medium text-gray-900">
                  {{ title }}
                </p>
                <div v-if="typeof content === 'object'">
                  <p
                    v-for="(message, index) in content"
                    class="mt-1 text-sm leading-5 text-gray-500"
                    :key="`${message}-${index}`"
                  >
                    {{ message }}
                  </p>
                </div>

                <p v-else class="mt-1 text-sm leading-5 text-gray-500">
                  {{ content }}
                </p>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <button
                  @click="close"
                  class="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                >
                  <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Notification",
  computed: {
    ...mapState("notificationStore", [
      "showNotification",
      "content",
      "title",
      "type",
    ]),
    icon: function () {
      switch (this.type) {
        case "warning":
          return "exclamation-triangle";
        case "error":
          return "times";
        case "success":
          return "check-circle";
        default:
          return "";
      }
    },
    color: function () {
      switch (this.type) {
        case "warning":
          return "text-orange-400";
        case "error":
          return "text-red-400";
        case "success":
          return "text-green-400";
        default:
          return "";
      }
    },
  },
  methods: {
    close() {
      this.$store.dispatch("notificationStore/CLOSE_NOTIFICATION");
    },
  },
};
</script>

<style></style>
