export const chartStylingOptions = {
  legend: {
    itemStyle: {
      color: "#ffffff",
    },
  },
  plotOptions: {
    line: {
      color: "#ffffff00",
    },
    column: {
      borderColor: "#ffffff00",
      borderRadius: 0,
    },
  },
  credits: {
    enabled: false,
  },
};

export const allRoles = [
  "ROLE_STUDENT",
  "ROLE_CUSTOMER_ADMIN",
  "ROLE_DEALER_ADMIN",
  "ROLE_EDITOR",
  "ROLE_USER_ADMIN",
  "ROLE_TEACHER",
  "ROLE_ADMIN",
  "ROLE_SUPER_ADMIN",
  "ROLE_USER",
];

export const adminRoles = [
  "ROLE_SUPER_ADMIN",
  "ROLE_ADMIN",
  "ROLE_EDITOR",
  "ROLE_USER_ADMIN",
  "ROLE_TEACHER",
  "ROLE_CUSTOMER_ADMIN",
  "ROLE_DEALER_ADMIN",
];
export const superAdminRole = "ROLE_SUPER_ADMIN";
export const sentryDSN =
  "https://2ed95ae352364b029e166c004bb0cc3f@sentry.io/1551492";
