import Repository from "./Repository";

const resource = "/api/v3/trainings";

export default {
  getTrainings() {
    return Repository.get(
      `${resource}?include=latest_version.applications.platform,oauth_client,versions`,
    );
  },

  showVersionContent(trainingId, versionId) {
    return Repository.get(
      `${resource}/${trainingId}/version/${versionId}/content?include=latest_version.applications.platform`,
    );
  },

  showVersions(trainingId) {
    return Repository.get(`${resource}/${trainingId}/versions`);
  },

  syncStatus(trainingId, versionId, runNext) {
    let urlParam = "";
    if (runNext) {
      urlParam = "?run_next=true";
    }
    return Repository.get(
      `${resource}/${trainingId}/versions/${versionId}/syncstatus${urlParam}`,
    );
  },

  updateVersion(trainingId, versionId, plaformSlug, url) {
    return Repository.patch(
      `${resource}/${trainingId}/versions/${versionId}/${plaformSlug}`,
      url,
    );
  },

  deleteVersion(trainingId, versionId) {
    return Repository.delete(`${resource}/${trainingId}/versions/${versionId}`);
  },

  deleteBuild(trainingId, versionId, slug) {
    return Repository.delete(
      `${resource}/${trainingId}/versions/${versionId}/${slug}`,
    );
  },

  getPlatforms() {
    return Repository.get(`/api/v3/platforms`);
  },

  newChapter(trainingId, versionId, chapter) {
    return Repository.post(
      `${resource}/${trainingId}/version/${versionId}/chapters`,
      { chapter },
    );
  },

  editChapter(trainingId, versionId, id, chapter) {
    return Repository.patch(
      `${resource}/${trainingId}/version/${versionId}/chapters/${id}`,
      { chapter },
    );
  },

  deleteChapter(trainingId, versionId, id) {
    return Repository.delete(
      `${resource}/${trainingId}/version/${versionId}/chapters/${id}`,
    );
  },

  createTraining(training) {
    return Repository.post(`${resource}`, { training: training });
  },

  editTraining(training, trainingId) {
    return Repository.patch(`${resource}/${trainingId}`, {
      training: training,
    });
  },

  deleteTraining(trainingId) {
    return Repository.delete(`${resource}/${trainingId}`);
  },

  editLevel(trainingId, versionId, id, lesson) {
    return Repository.patch(
      `${resource}/${trainingId}/version/${versionId}/lessons/${id}`,
      { lesson },
    );
  },

  getLessonById(trainingId, versionId, lessonId) {
    return Repository.get(
      `${resource}/${trainingId}/version/${versionId}/lessons/${lessonId}`,
    );
  },

  createLesson(trainingId, versionId, lesson) {
    return Repository.post(
      `${resource}/${trainingId}/version/${versionId}/lessons`,
      { lesson },
    );
  },

  updateLesson(trainingId, versionId, lessonId, lesson) {
    return Repository.patch(
      `${resource}/${trainingId}/version/${versionId}/lessons/${lessonId}`,
      { lesson: lesson },
    );
  },

  deleteLesson(trainingId, versionId, lessonId) {
    return Repository.delete(
      `${resource}/${trainingId}/version/${versionId}/lessons/${lessonId}`,
    );
  },

  getTrophies(trainingId) {
    return Repository.get(`${resource}/${trainingId}/trophies`);
  },

  createTrophies(trainingId, trophy) {
    return Repository.post(`${resource}/${trainingId}/trophies`, { trophy });
  },

  updateTrophies(trainingId, trophyId, trophy) {
    return Repository.patch(`${resource}/${trainingId}/trophies/${trophyId}`, {
      trophy,
    });
  },

  deleteTrophies(trainingId, trophyId) {
    return Repository.delete(`${resource}/${trainingId}/trophies/${trophyId}`);
  },

  publishTraining(trainingId, versionId) {
    return Repository.patch(
      `${resource}/${trainingId}/versions/${versionId}/publish`,
    );
  },

  importLesson(trainingId, versionId, subchapterId, payload) {
    return Repository.post(
      `${resource}/${trainingId}/version/${versionId}/chapters/${subchapterId}/import-lesson`,
      payload,
    );
  },
};
