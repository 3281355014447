import { defineRule } from "vee-validate";
import i18n from "@/i18n";

defineRule("min_one_upper_case", (value) => {
  if (value.match(/[A-Z]/g) === null) {
    return i18n.global.t("validation.messages.min_one_upper_case");
  }
  return true;
});

defineRule("min_one_number", (value) => {
  if (value.match(/[0-9]/g) === null) {
    return i18n.global.t("validation.messages.min_one_number");
  }
  return true;
});

defineRule("min_0_or_null", (value) => {
  if (isNaN(value)) {
    return true;
  } else {
    if (value >= 0) {
      return true;
    }
    return i18n.global.t("editor.value_must_be_either_empty_or_0_or_more");
  }
});

defineRule("date_start_end", (value, [target], ctx) => {
  if (value && ctx.form[target]) {
    value.setHours(0, 0, 0, 0);
    target.setHours(0, 0, 0, 0);
    if (target.getTime() >= value.getTime()) {
      return i18n.global.t("reporting.the_end_date_is_before_start_date");
    }
    return true;
  }
});

defineRule("date_end_start", (value, [target], ctx) => {
  if (value && ctx.form[target]) {
    value.setHours(0, 0, 0, 0);
    target.setHours(0, 0, 0, 0);
    if (target.getTime() <= value.getTime()) {
      return i18n.global.t("reporting.the_start_date_is_before_end_date");
    }
    return true;
  }
});

defineRule("minArrayLength", (value) => {
  if (value && value.length > 0) {
    return true;
  }
  return "This field is required";
});
