<template>
  <PageContentContainer>
    <div class="grid grid-cols-1 gap-6">
      <div class="backgroundRow overflow-hidden shadow sm:rounded-md">
        <div class="px-4 py-5 sm:p-6">
          <Form
            @keypress.enter="createCustomer"
            @submit="createCustomer"
            v-slot="{ errors }"
          >
            <InputField
              class="mt-inputPadding w-full"
              :title="$t('customers.name')"
              rules="required|min:4|max:100"
              :required-input="true"
              type="text"
              placeholder=""
              :light="true"
              v-model="customer.name"
              id="customerName"
            />

            <InputField
              class="mt-inputPadding w-full"
              :title="$t('customers.client_id')"
              rules="required"
              :required-input="true"
              type="text"
              placeholder=""
              :light="true"
              v-model="customer.client_id"
              id="customerClientId"
            />

            <DatePicker
              :title="$t('customers.start_date')"
              rules="required"
              :locale="$i18n.locale"
              :required-input="true"
              @update="
                (val) => {
                  customer.start_date = val;
                }
              "
              cy="customerStartDate"
            />

            <DatePicker
              :title="$t('customers.stop_date')"
              :locale="$i18n.locale"
              @update="
                (val) => {
                  customer.stop_date = val;
                }
              "
              cy="customerStopDate"
            />

            <SelectorField
              :title="$t('customers.dealer')"
              :light="true"
              v-model="customer.dealer_id"
            >
              <option
                v-for="dealer in dealers"
                :key="dealer.id"
                :value="dealer.id"
              >
                {{ dealer.name }}
              </option>
            </SelectorField>

            <!-- <ToggleButton
                class="mt-6"
                :title="$t('customers.enabled')"
                :val="customer.enabled"
                :light="true"
                @update="toggle"
              /> -->

            <div class="mt-8 pt-5">
              <div class="flex justify-end">
                <Button
                  type="submit"
                  class="mr-3"
                  :invalid="Object.keys(errors).length !== 0"
                  button-type="lightPrimary"
                  :title="$t('editor.save')"
                  data-cy="createCustomer"
                />
                <Button
                  button-type="lightSecondary"
                  :title="$t('customers.cancel')"
                  @clickAction="
                    $router.push({
                      name: 'customers',
                    })
                  "
                  cy="cancelCustomers"
                />
              </div>
            </div>
          </Form>
        </div>
      </div>
      <!-- <div class="backgroundRow shadow overflow-hidden sm:rounded-md">
        <div class="px-4 py-5 sm:px-6">
          <div
            class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap"
          >
            <div class="ml-4 mt-2 flex flex-row">
              <h3 class="text-lg leading-6 font-medium text-gray-100">
                {{ $t("customers.customers_users") }}
              </h3>
              <span
                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-blue-200 text-blue-900 ml-2"
              >
                2
              </span>
            </div>
            <div class="ml-4 mt-2 flex-shrink-0">
              <span class="inline-flex rounded-md shadow-sm">
                <button
                  type="button"
                  @click="addDomain = true"
                  class="inline-flex items-center px-4 py-2 border border-gray-400 text-sm leading-5 bg-transparent font-medium rounded-md text-gray-100 hover:bg-gray-400 hover:text-gray-100 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                >
                  {{ $t("customers.add_user") }}
                </button>
              </span>
            </div>
          </div>
        </div>
        <div class="flex flex-col">
          <div
            class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
          >
            <div
              class="align-middle inline-block min-w-full overflow-hidden sm:rounded-lg"
            >
              <table class="min-w-full">
                <thead>
                  <tr>
                    <th
                      class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-300 uppercase tracking-wider"
                    >
                      {{ $t("customers.firstname") }}
                    </th>
                    <th
                      class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-300 uppercase tracking-wider"
                    >
                      {{ $t("customers.lastname") }}
                    </th>
                    <th
                      class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-300 uppercase tracking-wider"
                    >
                      {{ $t("customers.email") }}
                    </th>
                    <th class="px-6 py-3 "></th>
                  </tr>
                </thead>
                <tbody>
                  <TableRow />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </PageContentContainer>
</template>

<script>
import Button from "@/components/form/Button";
import InputField from "@/components/form/InputField";
import DatePicker from "@/components/form/DatePicker";
import SelectorField from "@/components/form/SelectorField";
import ToggleButton from "@/components/form/ToggleButton";
import PageContentContainer from "@/components/helpers/PageContentContainer";
import TableRow from "./components/TableRowUsers";
import { showNotification } from "@/services/notificationService.js";
import { errorHandlingAndMessage } from "@/services/ErrorService";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const customerRepository = RepositoryFactory.get("customer");
const dealerRepository = RepositoryFactory.get("dealer");

export default {
  name: "CustomerEdit",
  components: {
    PageContentContainer,
    Button,
    InputField,
    SelectorField,
    DatePicker,
  },
  data: () => {
    return {
      mode: "single",
      dealers: [],
      customer: {
        name: "",
        client_id: "",
        start_date: null,
        stop_date: null,
        dealer_id: "",
        enabled: false,
      },
    };
  },
  created() {
    this.getDealers();
  },
  methods: {
    createCustomer() {
      customerRepository
        .createCustomer(this.customer)
        .then(() => {
          showNotification(
            this.$t("notifications.customer_created"),
            this.$t("notifications.customer_is_successfully_created"),
            "success",
          );
          this.$router.push({
            name: "customers",
          });
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t("notifications.something_went_wrong_creating_customers"),
          );
        });
    },
    toggle(val) {
      this.customer.enabled = val;
    },
    getDealers() {
      dealerRepository
        .getDealers()
        .then((result) => {
          this.dealers = result.data.data;
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t("notifications.something_went_wrong_fetching_dealers"),
          );
        });
    },
  },
};
</script>

<style></style>
