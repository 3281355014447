<template>
  <ModalContainer @cancel="close">
    <div class="bg-white overflow-y-visible shadow rounded-lg w-1/2">
      <div class="border-b border-gray-200 px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          {{ $t("users.edit_user") }}
        </h3>
      </div>
      <Form
        :initial-values="initialValues"
        @keypress.enter="editUser()"
        @submit="editUser()"
        v-slot="{ errors }"
      >
        <div v-if="userData" class="px-4 py-5 sm:p-6">
          <InputField
            :title="$t('users.firstname')"
            placeholder="John"
            v-model="userData.firstname"
            rules="required|min:2|max:255"
            cy="firstname"
          />
          <InputField
            :title="$t('users.lastname')"
            placeholder="John"
            v-model="userData.lastname"
            rules="required|min:2|max:255"
            cy="lastname"
          />
          <InputField
            :title="$t('users.email')"
            placeholder="John"
            v-model="userData.email"
            rules="required|email"
            cy="email"
          />
          <InputField
            :title="$t('users.password')"
            type="password"
            v-model="userData.password"
            rules="min:8|min_one_upper_case|min_one_number"
            cy="password"
          />
          <div v-if="customers && customers.length">
            <label
              for="selector"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              {{ `${$t("customers.customer")}` }}
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="max-w-full rounded-md shadow-sm">
                <v-select
                  class="users-select"
                  v-model="selectedCustomer"
                  :options="customers"
                  label="name"
                  :disabled="!!selectedDealer"
                  :reduce="(customer) => customer.id"
                  append-to-body
                  @update:modelValue="checkValue('customer')"
                ></v-select>
              </div>
              <Field
                as="div"
                name="selectedCustomer"
                class="h-6"
                v-model="selectedCustomer"
                :rules="selectedDealer ? '' : 'required'"
                v-slot="{ errors: selectedCustomerError }"
              >
                <div v-if="selectedCustomerError.length" class="h-6 mt-px">
                  <p class="text-sm text-red-600">
                    {{
                      selectedDealer
                        ? ""
                        : $t(
                            "users.a_user_has_to_be_assigned_to_either_a_customer_or_a_dealer",
                          )
                    }}
                  </p>
                </div>
              </Field>
            </div>
          </div>
          <div v-if="dealers && dealers.length">
            <label
              for="selector"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              {{ `${$t("customers.dealer")}` }}
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="max-w-full rounded-md shadow-sm">
                <v-select
                  class="users-select"
                  v-model="selectedDealer"
                  :options="dealers"
                  label="name"
                  :disabled="!!selectedCustomer"
                  :reduce="(dealer) => dealer.id"
                  append-to-body
                  @update:modelValue="checkValue('dealer')"
                ></v-select>
              </div>

              <Field
                as="div"
                name="selectedDealer"
                class="h-6"
                v-model="selectedDealer"
                :rules="selectedCustomer ? '' : 'required'"
                v-slot="{ errors: selectedDealerError }"
              >
                <div v-if="selectedDealerError.length" class="h-6 mt-px">
                  <p class="text-sm text-red-600">
                    {{
                      selectedCustomer
                        ? ""
                        : $t(
                            "users.a_user_has_to_be_assigned_to_either_a_customer_or_a_dealer",
                          )
                    }}
                  </p>
                </div>
              </Field>
            </div>
          </div>
          <div>
            <label
              for="selector"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              {{ $t("users.roles") }}
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="max-w-full rounded-md shadow-sm">
                <v-select
                  v-model="userData.roles"
                  :options="roles"
                  multiple
                  :reduce="(role) => role.id"
                >
                </v-select>
              </div>

              <Field
                as="div"
                name="userRoles"
                class="h-6"
                v-model="userData.roles"
                rules="minArrayLength"
                v-slot="{ errors: userRolesError }"
              >
                <div v-if="userRolesError.length" class="h-6 mt-px">
                  <p class="text-sm text-red-600">
                    {{ userRolesError[0] }}
                  </p>
                </div>
              </Field>
            </div>
          </div>

          <Checkbox :title="$t('users.active')" v-model="userData.active" />
        </div>
        <div class="border-t border-gray-200 px-4 py-4 sm:px-6">
          <div class="flex flex-row justify-end">
            <Button
              class="mr-3"
              button-type="primary"
              :invalid="Object.keys(errors).length !== 0"
              :title="$t('users.save')"
              @clickAction="editUser()"
            />
            <Button
              type="button"
              button-type="secondary"
              :title="$t('users.cancel')"
              @clickAction="close"
            />
          </div>
        </div>
      </Form>
    </div>
  </ModalContainer>
</template>

<script>
import { showNotification } from "@/services/notificationService";
import InputField from "@/components/form/InputField";
import Checkbox from "@/components/form/Checkbox";
import Button from "@/components/form/Button";
import ModalContainer from "@/components/helpers/ModalContainer";
import { allRoles } from "@/config";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const usersRepository = RepositoryFactory.get("user");
const dealerRepository = RepositoryFactory.get("dealer");
const customerRepository = RepositoryFactory.get("customer");
import { errorHandlingAndMessage } from "@/services/ErrorService";
import { mapState } from "vuex";
import useValidation from "@/helpers/useValidation";
import { Field, Form } from "vee-validate";

export default {
  name: "UserModal",
  components: { InputField, Button, ModalContainer, Checkbox, Field, Form },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["close", "getUsers"],
  setup(props, { emit }) {
    const initialValues = {
      "user-roles": props.user,
      subscribed: false,
    };

    return { initialValues };
  },
  data: () => {
    return {
      roles: [],
      dealers: null,
      customers: null,
      selectedDealer: null,
      selectedCustomer: null,
      userData: {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        dealer_id: null,
        customer_id: null,
        active: null,
        roles: [],
      },
    };
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
    selected: function () {
      return !this.selectedCustomer && !this.selectedDealer;
    },
  },
  mounted() {
    if (this.currentUser.roles_reachable.includes("ROLE_DEALER_ADMIN")) {
      this.getDealers();
    }
    if (this.currentUser.roles_reachable.includes("ROLE_CUSTOMER_ADMIN")) {
      this.getCustomers();
    }
    this.userData = { ...this.user };
    this.selectedDealer = this.user.dealer ? this.user.dealer.data.id : null;
    this.selectedCustomer = this.user.customer
      ? this.user.customer.data.id
      : null;

    this.roles = allRoles
      .filter((role) => {
        return this.currentUser.roles_reachable.includes(role);
      })
      .map((role) => {
        return {
          id: role,
          label: this.$t(`roles.${role}`),
        };
      });
  },

  methods: {
    close() {
      this.$emit("close");
    },
    editUser() {
      this.userData.dealer_id = this.selectedDealer;
      this.userData.customer_id = this.selectedCustomer;
      usersRepository
        .updateUser(this.userData)
        .then((result) => {
          showNotification(
            this.$t("notifications.user_updated"),
            this.$t("notifications.user_is_successfully_updated"),
            "success",
          );
          this.$emit("getUsers");
          this.$emit("close");
        })
        .catch((err) => {
          if (err.response.status === 422) {
            function capitalizeFirstLetter(string) {
              return string.charAt(0).toUpperCase() + string.slice(1);
            }

            const errorMessages = err.response.data.data.violations.data.map(
              (violation) => {
                const property = violation.property_path.replace(
                  /[\[\]']+/g,
                  "",
                );
                return `${capitalizeFirstLetter(
                  property,
                )}: ${capitalizeFirstLetter(violation.message)}`;
              },
            );

            errorHandlingAndMessage(err, errorMessages);
          } else {
            errorHandlingAndMessage(
              err,
              this.$t("notifications.something_went_wrong_editing_the_user"),
            );
          }
        });
    },
    getDealers() {
      dealerRepository
        .getDealers()
        .then((result) => {
          this.dealers = result.data.data;
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t("notifications.something_went_wrong_receiving_dealers"),
          );
        });
    },
    getCustomers() {
      customerRepository
        .getCustomers()
        .then((result) => {
          this.customers = result.data.data;
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t("notifications.something_went_wrong_receiving_dealers"),
          );
        });
    },
    checkValue(target) {
      if (target === "customer") {
        this.selectedDealer = null;
      }
      if (target === "dealer") {
        this.selectedCustomer = null;
      }
    },
  },
};
</script>

<style>
.users-select {
  color: black;
}
.users-select .vs__dropdown-toggle,
.users-select .style-chooser__title,
.users-select .vs-state-active-color {
  border-color: #d2d6dc;
  color: #233876;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-left: 0.5rem;
  margin-top: 0.3rem;
  height: 2rem;
}
.users-select .vs-dropdown-bg,
.users-select .vs__dropdown-menu {
  color: black;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-radius: 0.5rem;
}

.vs--disabled {
  background: #e7e9ee;
  border-radius: 0.5rem;
}

.users-select .vs__dropdown-option:hover {
  background: #3b82f6;
  color: white;
}
</style>
