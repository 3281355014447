<template>
  <div
    class="bg-blue-800 px-4 py-3 flex items-center justify-between sm:px-6 rounded-b-md"
  >
    <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
      <div>
        <p class="text-sm leading-5 text-white">
          {{
            $t("translation.showing_start_to_end_of_total_results", {
              start: currentPage * limit - limit + 1,
              end:
                currentPage * limit + 1 > totalResults
                  ? totalResults
                  : currentPage * limit + 1,
              total: totalResults,
            })
          }}
        </p>
      </div>
      <div class="hidden sm:flex-1 sm:flex items-center justify-end">
        <div>
          <nav
            class="relative z-0 inline-flex bg-gray-100 h-10 shadow-md rounded-full"
          >
            <button
              v-if="currentPage !== 1"
              type="button"
              class="relative inline-flex items-center px-4 rounded-l-md text-sm leading-5 font-medium hover:text-gray-400 active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
              aria-label="Previous"
              @click="$emit('previous')"
            >
              <font-awesome-icon
                icon="chevron-left"
                class="fa-sm text-gray-500"
              />
            </button>
            <button
              v-for="index in totalPages"
              :key="`totalPages-${index}`"
              type="button"
              :class="{
                ' font-black text-secondaryColor': index === currentPage,
                'text-gray-500': index !== currentPage,
                ' underline': index === currentPage,
              }"
              class="-ml-px relative inline-flex items-center px-4 text-sm leading-5 font-medium hover:text-gray-500 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
              @click="$emit('toPage', index)"
            >
              {{ index }}
            </button>
            <button
              v-if="currentPage !== totalPages"
              type="button"
              class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md text-sm leading-5 font-medium hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
              aria-label="Next"
              @click="$emit('next')"
            >
              <font-awesome-icon
                icon="chevron-right"
                class="fa-sm text-gray-500"
              />
            </button>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    limit: {
      type: Number,
      default: 10,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      loading: false,
      totalResults: 0,
      totalPages: 1,
      currentIndex: 0,
    };
  },
  watch: {
    data: {
      handler() {
        this.totalResults = this.data.length;
        this.totalPages = Math.ceil(this.data.length / this.limit);
      },
      deep: true,
    },
  },
  mounted() {
    this.totalResults = this.data.length;
    this.totalPages = Math.ceil(this.data.length / this.limit);
  },
};
</script>

<style></style>
