<template>
  <div
    class="flex flex-1 bg-modalBackground absolute top-0 left-0 h-full justify-center items-center w-full z-50"
  >
    <div
      class="flex flex-col bg-gray-100 shadow rounded-lg w-80% h-80% min-h-600 min-w-600 overflow-x-auto"
    >
      <div class="border-b border-gray-200 flex justify-between">
        <nav class="flex -mb-px">
          <a
            @click="activeTab = 1"
            class="cursor-pointer ml-8 group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
            :class="{
              'border-blue-500 text-blue-600 focus:outline-none focus:text-blue-800 focus:border-blue-700':
                activeTab === 1,
            }"
          >
            <font-awesome-icon
              icon="upload"
              class="-ml-0.5 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600"
              :class="{
                'text-blue-500 group-focus:text-blue-600': activeTab === 1,
              }"
            />

            <span>{{ title ? title : $t("media.upload_media") }}</span>
          </a>
          <a
            @click="activeTab = 2"
            class="cursor-pointer ml-8 group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
            :class="{
              'border-blue-500 text-blue-600 focus:outline-none focus:text-blue-800 focus:border-blue-700':
                activeTab === 2,
            }"
          >
            <font-awesome-icon
              icon="list"
              class="-ml-0.5 mr-2 h-5 w-5 fa-sm text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600"
              :class="{
                'text-blue-500 group-focus:text-blue-600': activeTab === 2,
              }"
            />
            <span>{{ $t("media.select_media") }}</span>
          </a>
        </nav>
        <div class="flex items-center justify-center">
          <font-awesome-icon
            icon="times"
            @click="close"
            class="mr-8 h-5 w-5 fa-sm text-gray-600 cursor-pointer group-hover:text-gray-800 group-focus:text-gray-700"
          />
        </div>
      </div>

      <UploadMedia
        v-if="activeTab === 1"
        :media-modal-type="mediaModalType"
        :uploading="uploading"
        :title="title"
        :progress="uploadPercentage"
        :total="uploadTotalSize"
        :done="uploadCompletedSize"
        @uploadFile="uploadFile"
      />
      <SelectMedia
        v-if="activeTab === 2"
        :media-modal-type="mediaModalType"
        @selectFile="selectFile"
      />
    </div>
  </div>
</template>

<script>
import UploadMedia from "./tabs/UploadMedia";
import SelectMedia from "./tabs/SelectMedia";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import axios from "axios";
const uploadRepository = RepositoryFactory.get("upload");

export default {
  name: "MediaModal",
  components: {
    UploadMedia,
    SelectMedia,
  },
  props: {
    mediaModalType: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {
      activeTab: 1,
      uploading: false,
      uploadPercentage: 0,
      uploadTotalSize: 0,
      uploadCompletedSize: 0,
    };
  },
  methods: {
    close() {
      this.$emit("toggleMediaModal");
    },
    uploadFile(file) {
      if (file?.length) {
        this.uploading = true;
        this.$emit("initializeUpload");
        uploadRepository
          .createSignedUrl(file[0]["name"])
          .then(async (result) => {
            const config = {
              onUploadProgress: (progressEvent) => {
                this.uploadPercentage = Math.round(
                  (progressEvent.loaded / progressEvent.total) * 100,
                );
                this.uploadTotalSize = progressEvent.total;
                this.uploadCompletedSize = progressEvent.loaded;
              },
            };
            await axios
              .put(result.data.data.url, file[0], config)
              .then(() => {
                this.$emit(
                  "finishUpload",
                  file,
                  result.data.data.url.split("?")[0],
                  this.required,
                );
              })
              .catch((err) => {
                this.$emit("handleUploadError");
              })
              .finally(() => {
                this.$emit("finalizeUpload");
              });
          })
          .catch((err) => {
            this.$emit("handleUploadError");
          })
          .finally(() => (this.uploading = false));
      }
    },
    selectFile(file) {
      this.$emit("selectFile", file);
    },
    setButtonStatus() {
      if (this.fileLoading) {
        return this.$t("button.uploading");
      } else {
        return this.$t("button.upload");
      }
    },
  },
};
</script>

<style></style>
