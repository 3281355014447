<template>
  <ModalContainer @cancel="close" v-click-outside="close">
    <div
      class="flex flex-col justify-between w-auto h-auto max-w-7xl bg-gray-100 rounded-md"
    >
      <div class="flex justify-end">
        <button
          @click="close"
          class="absolute text-white focus:outline-none focus:text-gray-500 transition ease-in-out duration-150 mr-2 mt-2 bg-gray-400 cursor-pointer hover:bg-gray-300"
        >
          <svg class="h-8 w-8" viewBox="0 0 20 20" fill="currentColor">
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
        <img :src="image" alt="image" />
      </div>
    </div>
  </ModalContainer>
</template>

<script>
import ModalContainer from "@/components/helpers/ModalContainer";

export default {
  name: "ImageModel",
  components: { ModalContainer },
  props: {
    image: {
      type: String,
      default: "",
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
