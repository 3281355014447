<template>
  <VDropdown :triggers="['hover']">
    <font-awesome-layers class="ml-1 mr-1">
      <font-awesome-icon icon="circle" class="text-white" />
      <font-awesome-icon
        icon="exclamation-circle"
        class="text-red-600 hover:text-red-800 outline-none"
        :class="iconSize"
      />
    </font-awesome-layers>
    <template #popper>
      <div class="flex flex-col max-w-300">
        <p
          class="popover-header text-md font-medium text-gray-800 mb-3"
          :class="{ 'mb-3': message }"
        >
          {{ title }}
        </p>
        <p v-if="message" class="popover-body text-sm text-gray-700">
          {{ message }}
        </p>
      </div>
    </template>
  </VDropdown>
</template>

<script>
export default {
  name: "ErrorPopup",
  props: {
    title: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "m",
    },
  },
  data() {
    return {
      uid: null,
    };
  },
  computed: {
    iconSize: function () {
      if (this.size === "s") {
        return "text-15";
      } else {
        return "text-20";
      }
    },
  },
  created() {
    this.uid = this.id ? this.id : this._uid;
  },
};
</script>

<style></style>
