<template>
  <ModalContainer @cancel="close">
    <div class="bg-white shadow sm:rounded-lg" v-click-outside="close">
      <div class="px-4 py-5 sm:p-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          {{ $t("game.game_started_in_a_new_window") }}
        </h3>
        <div class="mt-2 max-w-xl text-sm leading-5 text-gray-500">
          <div v-if="content.type === 'downloadable'" class="w-full">
            <p class="pb-2">
              {{ $t("game.the_game_will_automatically_open") }}
            </p>
            <p class="pb-2">
              {{
                $t(
                  "game.if_nothing_happens_please_make_sure_the_game_has_been_downloaded_on_your_computer",
                )
              }}
            </p>
          </div>
        </div>

        <div class="flex mt-5 w-full">
          <div class="flex justify-between w-full">
            <Button
              v-if="content.download_url"
              button-type="primary"
              :title="$t('general.download_the_game').toUpperCase()"
              @clickAction="startDownload"
            />
            <Button
              class="ml-3"
              button-type="secondary"
              :title="$t('button.close')"
              @clickAction="close"
            />
          </div>
        </div>
      </div>
    </div>
  </ModalContainer>
</template>

<script>
import ModalContainer from "../ModalContainer";
import Button from "../../form/Button";

export default {
  components: {
    ModalContainer,
    Button,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showModal: this.show,
      activationCode: "",
      activationError: false,
      loading: false,
    };
  },
  methods: {
    close() {
      this.$store.dispatch("modalStore/HIDE_GAME_INFO_MODAL");
    },
    startDownload() {
      window.open(this.content.download_url, "_blank");
    },
  },
};
</script>

<style></style>
